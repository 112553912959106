import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import AffectationTableRow from '../../components/affectation/AffectationTableRow';
import { AffectationModel, ProjetModel, UserModel } from '../../data/models';
import { useFetch } from '../../hooks/useFetch';
import env from '../../utils/env';
import EmptyState from '../../components/EmptyState';
import { useAuthContext } from '../../contexts/AuthContextProvider';

const AffectationsPage = () => {

  const [affectations, setAffectations] = useState<AffectationModel[]>([]);
  const [error, setError] = useState<string>('');
  const [projetId, setProjetId] = useState<string>('');
  const [userId, setUserId] = useState<string>('');
  const [typeTravaux, setTypeTravaux] = useState<string>('');

  const { hasAuthority } = useAuthContext();

  const { data: projets, error: loadProjetsError } = useFetch<ProjetModel[]>(
    `${env.API_URL}/api/projets`
  );
  const { data: users, error: loadUsersError } = useFetch<UserModel[]>(
    `${env.API_URL}/api/users`
  );
  const { sendRequest: loadAffects, data: loadAffectsResp, error: loadAffectsError } = useFetch<AffectationModel[]>(
    `${env.API_URL}/api/affectations`
  );

  const {
    data: loadAffectationsByProjetResp,
    sendRequest: loadAffectationsByProjet,
    error: loadAffectationsByProjetError
  } = useFetch<AffectationModel[]>(
    `${env.API_URL}/api/affectations/projet/${projetId}`,
    { method: 'GET' }
  );

  const {
    data: loadAffectationsByUserResp,
    sendRequest: loadAffectationsByUser,
    error: loadAffectationsByUserError
  } = useFetch<AffectationModel[]>(
    `${env.API_URL}/api/affectations/user/${userId}`,
    { method: 'GET' }
  );

  const {
    data: loadAffectationsByTravauxResp,
    sendRequest: loadAffectationsByTravaux,
    error: loadAffectationsByTravauxError
  } = useFetch<AffectationModel[]>(
    `${env.API_URL}/api/affectations/travaux/${typeTravaux}`,
    { method: 'GET' }
  );

  useEffect(() => {
    if (loadAffectsResp) { setAffectations(loadAffectsResp); }
  }, [loadAffectsResp]);

  useEffect(() => {
    if (loadAffectationsByTravauxResp) {
      setAffectations(loadAffectationsByTravauxResp);
    }
    if (loadAffectationsByTravauxError) {
      setError(loadAffectationsByTravauxError);
    }
  }, [loadAffectationsByTravauxResp, loadAffectationsByTravauxError]);

  useEffect(() => {
    if (loadAffectationsByProjetResp) {
      setAffectations(loadAffectationsByProjetResp);
    }
    if (loadAffectationsByProjetError) {
      setError(loadAffectationsByProjetError);
    }
  }, [loadAffectationsByProjetResp, loadAffectationsByProjetError]);

  useEffect(() => {
    if (loadAffectationsByUserResp) {
      setAffectations(loadAffectationsByUserResp);
    }
    if (loadAffectationsByUserError) {
      setError(loadAffectationsByUserError);
    }
  }, [loadAffectationsByUserResp, loadAffectationsByUserError]);

  useEffect(() => {
    if (projetId) {
      loadAffectationsByProjet();
      setUserId('');
    }
    else if (!userId) {
      loadAffects();
    }
  }, [projetId]);

  useEffect(() => {
    if (userId) {
      loadAffectationsByUser();
      setProjetId('');
    } else if (!projetId) {
      loadAffects();
    }
  }, [userId]);


  const selectProjet0 = (e: React.MouseEvent) => {
    e.preventDefault();
  }

  const searchByProjet = (e: React.ChangeEvent<HTMLSelectElement>) => {
    e.preventDefault();
    const { name, value, type } = e.target;

    if (value
      && value.trim().length > 0
      && value !== projetId) {
      setProjetId(value);
    }
    if (!value || !value.trim()) {
      setProjetId('');
    }
  }

  const searchByUser = (e: React.ChangeEvent<HTMLSelectElement>) => {
    e.preventDefault();
    const { name, value: _userId, type } = e.target;

    if (_userId
      && _userId.trim().length > 0
      && _userId !== userId) {
      setUserId(_userId);
    }
    if (!_userId || !_userId.trim()) {
      setUserId('');
    }
  }

  const selectTypeTravaux = (e: React.ChangeEvent<HTMLSelectElement>) => {
    e.preventDefault();
    const { name, value: _typeTravaux, type } = e.target;

    if (_typeTravaux
      && _typeTravaux.trim().length > 0
      && _typeTravaux !== typeTravaux) {
      loadAffectationsByTravaux();
    }
    if (!_typeTravaux || !_typeTravaux.trim()) {
      setAffectations([]);
    }
  }

  return (

    <div className="w-full lg:ps-64">
      <div className="p-4 sm:p-6 space-y-4 sm:space-y-6">

        <div className="flex flex-col">
          <div className="-m-1.5 overflow-x-auto">
            <div className="p-1.5 min-w-full inline-block align-middle">
              <div className="bg-white border border-gray-200 rounded-xl shadow-sm overflow-hidden dark:bg-neutral-800 dark:border-neutral-700">

                <div className="px-6 py-4 grid gap-3 md:flex md:justify-between md:items-center border-b border-gray-200 dark:border-neutral-700">
                  <div>
                  </div>

                  <div>
                    <div className="inline-flex gap-x-2">
                      {hasAuthority('CREATE_AFFECTATION') && (
                        <Link to={`/affectations/add`}
                          className="py-2 px-3 inline-flex items-center gap-x-2 text-sm font-semibold rounded-lg border border-transparent bg-red-600 text-white hover:bg-red-700 disabled:opacity-50 disabled:pointer-events-none">
                          <svg className="flex-shrink-0 size-4" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" stroke-linecap="round" stroke-linejoin="round"><path d="M5 12h14" /><path d="M12 5v14" /></svg>
                          Affecter un projet
                        </Link>
                      )}
                    </div>
                  </div>
                </div>

                <div className="p-7 sm:p-7 mb-8 sm:mb-8">
                  <div className="space-y-4 sm:space-y-6">
                    <div className="space-y-2">
                      <label htmlFor="af-search-by-projet" className="inline-block text-sm font-medium text-gray-800 mt-2.5 dark:text-neutral-200">
                        Filtrer par projet
                      </label>
                      <select name="by-projet" id="af-search-by-projet"
                        className="py-2 px-3 pe-9 block w-full border-gray-200 shadow-sm text-sm rounded-lg focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none dark:bg-neutral-900 dark:border-neutral-700 dark:text-neutral-400 dark:placeholder-neutral-500 dark:focus:ring-neutral-600"
                        value={projetId}
                        onChange={searchByProjet}>
                        <option value=""></option>
                        {projets?.map((p) => (
                          <option value={p.sid} selected={projetId === p.sid}>
                            {p.greza + ' - ' + p.etablissement?.nom}
                          </option>
                        ))}
                      </select>
                    </div>
                    <div className="space-y-2">
                      <label htmlFor="af-search-by-user" className="inline-block text-sm font-medium text-gray-800 mt-2.5 dark:text-neutral-200">
                        Filtrer par intervenant
                      </label>
                      <select name="by-projet" id="af-search-by-user"
                        className="py-2 px-3 pe-9 block w-full border-gray-200 shadow-sm text-sm rounded-lg focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none dark:bg-neutral-900 dark:border-neutral-700 dark:text-neutral-400 dark:placeholder-neutral-500 dark:focus:ring-neutral-600"
                        value={userId}
                        onChange={searchByUser}>
                        <option value=""></option>
                        {users?.map((u) => (
                          <option value={u.sid} selected={userId === u.sid}>
                            {u.firstName + ' ' + u.lastName}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                </div>

                {!affectations || affectations.length == 0
                  ? (
                    <EmptyState
                      title="Aucune affectation n'a été trouvée"
                      description="Vous pouvez commencer par en créer" />)
                  : (
                    <table className="min-w-full divide-y divide-gray-200 dark:divide-neutral-700">
                      <thead className="bg-gray-50 dark:bg-neutral-800">
                        <tr>
                          <th scope="col" className="ps-6 py-3 text-start">
                            <label htmlFor="hs-at-with-checkboxes-main" className="flex">
                              <input type="checkbox"
                                className="shrink-0 border-gray-300 rounded text-red-600 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none dark:bg-neutral-800 dark:border-neutral-600 dark:checked:bg-red-500 dark:checked:border-blue-500 dark:focus:ring-offset-gray-800"
                                id="hs-at-with-checkboxes-main" />
                              <span className="sr-only">Checkbox</span>
                            </label>
                          </th>

                          <th scope="col" className="px-6 py-3 text-start">
                            <div className="flex items-center gap-x-2">
                              <span className="text-xs font-semibold uppercase tracking-wide text-gray-800 dark:text-neutral-200">
                                Gresa
                              </span>
                            </div>
                          </th>

                          <th scope="col" className="px-6 py-3 text-start">
                            <div className="flex items-center gap-x-2">
                              <span className="text-xs font-semibold uppercase tracking-wide text-gray-800 dark:text-neutral-200">
                                Établissement
                              </span>
                            </div>
                          </th>

                          <th scope="col" className="px-6 py-3 text-start">
                            <div className="flex items-center gap-x-2">
                              <span className="text-xs font-semibold uppercase tracking-wide text-gray-800 dark:text-neutral-200">
                                Intervenant
                              </span>
                            </div>
                          </th>

                          <th scope="col" className="px-6 py-3 text-start">
                            <div className="flex items-center gap-x-2">
                              <span className="text-xs font-semibold uppercase tracking-wide text-gray-800 dark:text-neutral-200">
                                Poste
                              </span>
                            </div>
                          </th>

                          <th scope="col" className="px-6 py-3 text-end"></th>
                        </tr>
                      </thead>

                      <tbody className="divide-y divide-gray-200 dark:divide-neutral-700">
                        {affectations.map((affectation) => (
                          <AffectationTableRow {...affectation} />
                        ))}
                      </tbody>

                    </table>

                  )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AffectationsPage;