import { Stats } from '../../data/models';
import StatCard from './StatCard';

type ThisProps = {
    stats: Stats;
};

const ProjetGlobalStats = (props: ThisProps) => {

    return (
        <>
            {props.stats?.repartition && props.stats.repartition.length > 0 && (
                <div className={"grid sm:grid-cols-2 lg:grid-cols-"+(props.stats.repartition.length+1)+" gap-4 sm:gap-6"}>
                    <StatCard
                        title="Total projets"
                        tooltip="Nombre total de projets toutes catégories de travaux confondues"
                        value={props.stats.total} />
                    {props.stats.repartition.map((partition) => (
                        <StatCard
                            title={partition.sujet['libelle']}
                            value={partition.valeur}
                            percentage={partition.pourcentage} />
                    ))}
                </div>
            )}
        </>
    );
};

export default ProjetGlobalStats;
