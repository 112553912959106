import { Link } from 'react-router-dom';
import { AffectationModel } from '../../data/models';
import { useAuthContext } from '../../contexts/AuthContextProvider';

const AffectationTableRow = (affectation: AffectationModel) => {

    const { hasAuthority } = useAuthContext();

    return (
        <tr>
            <td className="size-px whitespace-nowrap">
                <div className="ps-6 py-3">
                    <label htmlFor="hs-at-with-checkboxes-1" className="flex">
                        <input type="checkbox"
                            className="shrink-0 border-gray-300 rounded text-red-600 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none dark:bg-neutral-800 dark:border-neutral-600 dark:checked:bg-red-500 dark:checked:border-blue-500 dark:focus:ring-offset-gray-800"
                            id="hs-at-with-checkboxes-1" />
                        <span className="sr-only">Checkbox</span>
                    </label>
                </div>
            </td>

            <td className="size-px whitespace-nowrap">
                <div className="px-6 py-3">
                    <span className="text-sm font-semibold text-gray-800 dark:text-neutral-200">
                        {affectation.projet.greza}
                    </span>
                </div>
            </td>

            <td className="size-px whitespace-nowrap">
                <div className="px-6 py-3">
                    <span className="block text-sm font-semibold text-gray-800 dark:text-neutral-200">
                        {affectation.projet.etablissement?.nom}
                    </span>
                    <span className="block text-sm text-gray-500 dark:text-neutral-500">
                        {affectation.projet.etablissement?.commune?.nom}
                    </span>
                </div>
            </td>

            <td className="size-px whitespace-nowrap">
                <div className="px-6 py-3">
                    <span className="block text-sm font-semibold text-gray-800 dark:text-neutral-200">
                        {affectation.user.firstName + ' ' + affectation.user.lastName}
                    </span>
                    <span className="block text-sm text-gray-500 dark:text-neutral-500">
                        {affectation.user.email}
                    </span>
                </div>
            </td>

            <td className="size-px whitespace-nowrap">
                <div className="px-6 py-3">
                    <span className="text-sm text-gray-500 dark:text-neutral-500">
                        {affectation.competence?.libelle}
                    </span>
                </div>
            </td>

            <td className="size-px whitespace-nowrap">
                <div className="px-6 py-1.5">
                    {hasAuthority('EDIT_AFFECTATION') && (
                        <Link to={`/affectations/${affectation.sid}`}
                            className="inline-flex items-center gap-x-1 text-sm text-red-600 decoration-2 hover:underline font-medium dark:text-red-500">
                            Editer
                        </Link>
                    )}
                </div>
            </td>
        </tr>
    );
};

export default AffectationTableRow;
