import React, { Dispatch, SetStateAction } from 'react';

type LogInFormProps = {
    setUsername: Dispatch<SetStateAction<string>>;
    setPassword: Dispatch<SetStateAction<string>>;
    handleLogInClick: (e: React.MouseEvent<HTMLElement>) => void;
};

const LogInForm = ({
    setUsername,
    setPassword,
    handleLogInClick
}: LogInFormProps) => {
    return (
        <form>
            <div className="grid gap-y-4">
                <div>
                    <label htmlFor="username" className="block text-sm mb-2 dark:text-white">Email address</label>
                    <div className="relative">
                        <input type="text" id="username" name="username" placeholder='Email'
                            className="py-3 px-4 block w-full border-gray-200 rounded-lg text-sm focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none dark:bg-neutral-900 dark:border-neutral-700 dark:text-neutral-400 dark:placeholder-neutral-500 dark:focus:ring-neutral-600 border border-gray-200"
                            onChange={(e) => setUsername(e.target.value)} />
                    </div>
                    <p className="hidden text-xs text-red-600 mt-2" id="email-error">Please include a valid email address so we
                        can get back to you</p>
                </div>
                <div>
                    <div className="flex justify-between items-center">
                        <label htmlFor="password" className="block text-sm mb-2 dark:text-white">Password</label>
                        {/* <a className="text-sm text-red-600 decoration-2 hover:underline font-medium"
                            href="../examples/html/recover-account.html">Forgot password?</a> */}
                    </div>
                    <div className="relative">
                        <input type="password" id="password" name="password" placeholder='Mot de passe'
                            className="py-3 px-4 block w-full border-gray-200 rounded-lg text-sm focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none dark:bg-neutral-900 dark:border-neutral-700 dark:text-neutral-400 dark:placeholder-neutral-500 dark:focus:ring-neutral-600 border border-gray-200"
                            onChange={(e) => setPassword(e.target.value)} />
                    </div>
                    <p className="hidden text-xs text-red-600 mt-2" id="password-error">8+ characters required</p>
                </div>
                {/* <div className="flex items-center">
                    <div className="flex">
                        <input id="remember-me" name="remember-me" type="checkbox"
                            className="shrink-0 mt-0.5 border-gray-200 rounded text-red-600 focus:ring-blue-500 dark:bg-neutral-800 dark:border-neutral-700 dark:checked:bg-blue-500 dark:checked:border-blue-500 dark:focus:ring-offset-gray-800" />
                    </div>
                    <div className="ms-3">
                        <label htmlFor="remember-me" className="text-sm dark:text-white">Rester connecté</label>
                    </div>
                </div> */}
                <button className="w-full py-3 px-4 inline-flex justify-center items-center gap-x-2 text-sm font-semibold rounded-lg border border-transparent bg-red-600 text-white hover:bg-red-700 disabled:opacity-50 disabled:pointer-events-none"
                        onClick={handleLogInClick}>
                    Se connecter
                </button>
                
            </div>
        </form>
    );
};

export default LogInForm;
