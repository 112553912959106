import {
    UserModel,
    ProjetModel,
    AffectationModel,
    CompetenceModel
} from '../../data/models';

type AffectationFormProps = {
    handleFieldChange: (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => void;
    handleSubmit: (e: React.MouseEvent) => void;
    handleClearForm?: (e: React.MouseEvent) => void | null;
    projets: ProjetModel[] | null;
    users: UserModel[] | null;
    competences: CompetenceModel[] | null;
    affectation: AffectationModel;
};

const AffectationForm = (props: AffectationFormProps) => {

    const currentAffectation = props.affectation;

    return (
        <form>
            <div className="grid sm:grid-cols-12 gap-2 sm:gap-6">

                <div className="sm:col-span-3">
                    <label htmlFor="af-affect-projet" className="inline-block text-sm text-gray-800 mt-2.5 dark:text-neutral-200">
                        Projet
                    </label>
                </div>
                <div className="sm:col-span-9">
                    <select name="projet" id="af-affect-projet"
                        className="py-2 px-3 pe-9 block w-full border-gray-200 shadow-sm text-sm rounded-lg focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none dark:bg-neutral-900 dark:border-neutral-700 dark:text-neutral-400 dark:placeholder-neutral-500 dark:focus:ring-neutral-600"
                        value={currentAffectation.projet.sid}
                        onChange={props.handleFieldChange}>
                        <option value="">Choisir le projet...</option>
                        {props.projets?.map((p) => (
                            <option
                                value={p.sid}
                                selected={currentAffectation.projet ? currentAffectation.projet.sid === p.sid : false}>
                                {p.greza + ' - ' + p.etablissement?.nom}
                            </option>
                        ))}
                    </select>
                </div>

                <div className="sm:col-span-3">
                    <label htmlFor="af-affect-user" className="inline-block text-sm text-gray-800 mt-2.5 dark:text-neutral-200">
                        Intervenant
                    </label>
                </div>
                <div className="sm:col-span-9">
                    <select name="user" id="af-affect-user"
                        className="py-2 px-3 pe-9 block w-full border-gray-200 shadow-sm text-sm rounded-lg focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none dark:bg-neutral-900 dark:border-neutral-700 dark:text-neutral-400 dark:placeholder-neutral-500 dark:focus:ring-neutral-600"
                        value={currentAffectation.user.sid}
                        onChange={props.handleFieldChange}>
                        <option value="">Choisir l'intervenant...</option>
                        {props.users?.map((u) => (
                            <option
                                value={u.sid}
                                selected={currentAffectation.user ? currentAffectation.user.sid === u.sid : false}>
                                {u.firstName + ' ' + u.lastName}
                            </option>
                        ))}
                    </select>
                </div>

                <div className="sm:col-span-3">
                    <label htmlFor="af-affect-competence" className="inline-block text-sm text-gray-800 mt-2.5 dark:text-neutral-200">
                        Poste
                    </label>
                </div>

                <div className="sm:col-span-9">
                    <div className="grid sm:grid-cols-2 gap-2">
                        {props.competences?.map(({ nom, libelle }) => (
                            <label htmlFor={`affect-competence-${nom}`}
                                className="flex p-3 w-full bg-white border border-gray-200 rounded-lg text-sm focus:border-blue-500 focus:ring-blue-500 dark:bg-neutral-900 dark:border-neutral-700 dark:text-neutral-400">
                                <input id={`affect-competence-${nom}`}
                                    type="radio"
                                    name={`affect-competence-${nom}`}
                                    className="shrink-0 mt-0.5 border-gray-200 rounded-full text-red-600 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none dark:bg-neutral-800 dark:border-neutral-700 dark:checked:bg-red-500 dark:checked:border-blue-500 dark:focus:ring-offset-gray-800"
                                    onChange={props.handleFieldChange}
                                    checked={nom === currentAffectation.competence?.nom} />
                                <span className="text-sm text-gray-500 ms-3 dark:text-neutral-400">
                                    {libelle}
                                </span>
                            </label>
                        ))}
                    </div>
                </div>
            </div>
        </form>
    );
};

export default AffectationForm;
