
const EmptyState = (props: {title: string, description: string}) => {
    return (
        <div className="max-w-2xl px-4 sm:px-6 py-12 md:py-20 lg:px-8 mx-auto">
            <div className="mb-5 sm:mb-10 text-center">
                <h2 className="text-2xl font-bold md:text-3xl dark:text-white">
                    {props.title} 
                </h2>
                <p className="mt-3 text-gray-500 dark:text-neutral-400">
                    {props.description} 
                </p>
            </div>
        </div>
    );
};

export default EmptyState;
