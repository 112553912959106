import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useFetch } from '../../../hooks/useFetch';
import env from '../../../utils/env';
import Alert from "../../../components/Alert";
import { useAuthContext } from '../../../contexts/AuthContextProvider';
import { FaitMarquantProjetModel, ProjetModel } from '../../../data/models';
import FaitMarquantForm from '../../../components/projet/fait/FaitMarquantForm';

const AddFaitMarquantPage = () => {

  const [fait, setFait] = useState<FaitMarquantProjetModel>({} as FaitMarquantProjetModel);
  const [file, setFile] = useState<File | null>(null);

  const [error, setError] = useState('');
  const [validationErrors, setValidationErrors] = useState<string[]>([]);
  const [successful, setSuccessful] = useState<boolean>(false);

  const { authState } = useAuthContext();
  const authorizationHeader = authState.token;

  const { sid: projetId } = useParams() || "";
  if (!projetId || !projetId.trim()) {
    setError("L'identifiant projet fourni est erroné!");
  }

  const { data: projet, error: loadProjetError } = useFetch<ProjetModel>(
    `${env.API_URL}/api/projets/${projetId}`
  );

  useEffect(() => {
    if (projet) {
      setFait(f => ({
        ...f,
        projet: {
          ...f.projet,
          sid: projet.sid
        }
      }))
    }
  }, [projet]);

  useEffect(() => {
    if (loadProjetError) {
      setError(loadProjetError);
    }
  }, [loadProjetError]);

  const formData = new FormData();

  const {
    error: postFaitMarquantError,
    data: postFaitMarquantResp,
    sendRequest: postFaitMarquant
  } = useFetch<FormData>(
    `${env.API_URL}/api/projets/${projetId}/faits`,
    {
      method: 'POST',
      payload: formData
    }
  );

  useEffect(() => {
    if (postFaitMarquantResp) {
      setSuccessful(true);
      setError('');
    }
  }, [postFaitMarquantResp]);

  useEffect(() => {
    if (postFaitMarquantError) {
      setError(postFaitMarquantError);
      setSuccessful(false);
    }
  }, [postFaitMarquantError]);

  const handleOnSubmit = (e: React.MouseEvent) => {
    e.preventDefault();

    setError('');
    setValidationErrors([]);

    let errorMsg = 'Merci de bien vouloir saisir les champs suivants :\n';
    let errors: string[] = [];

    if (!fait.date) {
      errors.push("La date du fait est obligatoire!");
    }
    if (!file) {
      errors.push("Vous devez joindre au fait marquant un justificatif");
    }

    if (errors.length == 0) {
      handleUpload();
    } else {
      setError(errorMsg);
      setValidationErrors(errors);
    }
  };

  const handleUpload = async () => {

    const faitJson = JSON.stringify(fait);
    const faitBlob = new Blob([faitJson], {
      type: 'application/json'
    })
    const formData = new FormData();
    if (file) {
      formData.append("document", file, file.name);
    }
    formData.append("fait", faitBlob);

    try {
      const result = await fetch(
        `${env.API_URL}/api/projets/${projetId}/faits`,
        {
          method: "POST",
          body: formData,
          headers: { 'authorization': `Bearer ${authorizationHeader}` }
        });

      const resp = await result.json();

      if ('sid' in resp) {
        setFait(resp);
        setSuccessful(true);
        setError('');
      }
      else {
        setSuccessful(false);
        setError(resp.message);
      }
    } catch (e) {
      console.error(e);
      setError("Une erreur est survenue lors de l'envoi du formulaire");
    }
  };

  const handleFieldChange = (e: React.ChangeEvent<HTMLInputElement
    | HTMLTextAreaElement | HTMLSelectElement>) => {
    const { name, value, type } = e.target;

    if (type === 'text' || type === 'textarea') {
      setFait(f => ({
        ...f,
        [name]: value || undefined
      }));
    }
  };

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      setFile(e.target.files[0]);
    }
  };

  const setDate = (d: Date) => {
    setFait(f => ({
      ...f,
      date: d
    }))
  }

  return (
    <div className="w-full lg:ps-64">
      <div className="p-4 sm:p-6 space-y-4 sm:space-y-6">

        <div className="flex flex-col">
          <div className="overflow-x-auto">
            <div className="p-1.5 min-w-full inline-block align-middle">
              <div className="bg-white border border-gray-200 rounded-xl shadow-sm overflow-hidden dark:bg-neutral-800 dark:border-neutral-700">

                <div className="px-6 py-4 grid gap-3 md:flex md:justify-between md:items-center border-b border-gray-200 dark:border-neutral-700">
                  <div>
                    <h2 className="text-xl font-semibold text-gray-800 dark:text-neutral-200">
                      Saisie d'un fait marquant du projet {projet?.greza + ' - ' + projet?.etablissement?.nom}
                    </h2>
                    <p className="text-sm text-gray-600 dark:text-neutral-400">
                      Alimenter le formulaire de création de fait marquant.
                    </p>
                  </div>

                  <div>
                    <div className="inline-flex gap-x-2">
                    </div>
                  </div>
                </div>

                <div className="px-14 py-16">
                  {(validationErrors.length > 0 || error) && (
                    <Alert
                      type="error"
                      title={error}
                      details={validationErrors}
                    />
                  )}
                  {successful && (
                    <Alert
                      type="success"
                      title="Le fait marquant du projet en cours a bien été créé"
                      showLinks={true}
                      linkText='Aller à la liste des faits'
                      toLink={`/projets/${projetId}/faits`}
                    />
                  )}
                  <FaitMarquantForm
                    handleFieldChange={handleFieldChange}
                    handleFileChange={handleFileChange}
                    handleSubmit={handleOnSubmit}
                    fait={fait} file={file} setDate={setDate} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddFaitMarquantPage;
