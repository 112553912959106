
import { Link } from 'react-router-dom';
import Menu from './Menu';
import logo from '../../cgi-management.jpg';

const Sidebar = () => {
    return (
        <div id="application-sidebar" className="hs-overlay [--auto-close:lg]
            hs-overlay-open:translate-x-0
            -translate-x-full transition-all duration-300 transform
            w-[260px]
            hidden
            fixed inset-y-0 start-0 z-[60]
            bg-white border-e border-gray-200
            lg:block lg:translate-x-0 lg:end-auto lg:bottom-0
            dark:bg-neutral-800 dark:border-neutral-700">
            <div className="px-8 pt-4">
                <Link className="flex-none rounded-xl text-xl inline-block font-semibold focus:outline-none focus:opacity-80" 
                    to="/">
                        <img src={logo} alt="logo" />
                </Link>
            </div>

            <Menu />
        </div>
    );

};

export default Sidebar;
