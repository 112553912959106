import { StatutUserModel, UserModel, UserProfile } from '../../data/models';

type UserFormProps = {
    handleFieldChange: (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
    handleSubmit: (e: React.MouseEvent) => void;
    handleClearForm?: (e: React.MouseEvent) => void | null;
    userProfiles: UserProfile[] | null;
    statuts: StatutUserModel[] | null;
    canUpdatePassword: boolean;
    disableEmail: boolean;
    user: UserModel;
};

const UserForm = (props: UserFormProps) => {

    const currentUser = props.user;

    const hasUserProfile = (p: string) => {
        return (currentUser?.profiles ? currentUser?.profiles : []).indexOf(p.toUpperCase()) >= 0;
    }

    return (
        <form>
            <div className="grid sm:grid-cols-12 gap-2 sm:gap-6">
                <div className="sm:col-span-3">
                    <label htmlFor="af-account-full-name" className="inline-block text-sm text-gray-800 mt-2.5 dark:text-neutral-200">
                        Nom complet
                    </label>
                </div>

                <div className="sm:col-span-9">
                    <div className="sm:flex">
                        <input id="af-account-full-name"
                            className="py-2 px-3 pe-11 block w-full border-gray-200 shadow-sm -mt-px -ms-px first:rounded-t-lg last:rounded-b-lg sm:first:rounded-s-lg sm:mt-0 sm:first:ms-0 sm:first:rounded-se-none sm:last:rounded-es-none sm:last:rounded-e-lg text-sm relative focus:z-10 focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none dark:bg-neutral-800 dark:border-neutral-700 dark:text-neutral-400 dark:placeholder-neutral-500 dark:focus:ring-neutral-600"
                            type="text"
                            name="firstName"
                            placeholder="Prénom..."
                            value={currentUser.firstName}
                            onChange={props.handleFieldChange}
                        />
                        <input
                            className="py-2 px-3 pe-11 block w-full border-gray-200 shadow-sm -mt-px -ms-px first:rounded-t-lg last:rounded-b-lg sm:first:rounded-s-lg sm:mt-0 sm:first:ms-0 sm:first:rounded-se-none sm:last:rounded-es-none sm:last:rounded-e-lg text-sm relative focus:z-10 focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none dark:bg-neutral-800 dark:border-neutral-700 dark:text-neutral-400 dark:placeholder-neutral-500 dark:focus:ring-neutral-600"
                            type="text"
                            name="lastName"
                            placeholder="Nom..."
                            value={currentUser.lastName}
                            onChange={props.handleFieldChange}
                        />
                    </div>
                </div>

                <div className="sm:col-span-3">
                    <label htmlFor="af-account-email" className="inline-block text-sm text-gray-800 mt-2.5 dark:text-neutral-200">
                        Email
                    </label>
                </div>
                <div className="sm:col-span-9">
                    <input id="af-account-email"
                        type="email" name="email"
                        className="py-2 px-3 pe-11 block w-full border-gray-200 shadow-sm text-sm rounded-lg focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none dark:bg-neutral-800 dark:border-neutral-700 dark:text-neutral-400 dark:placeholder-neutral-500 dark:focus:ring-neutral-600"
                        placeholder="Adresse email..."
                        value={currentUser.email}
                        onChange={props.handleFieldChange}
                        disabled={props.disableEmail}
                    />
                </div>

                {props.canUpdatePassword && (
                    <>
                        <div className="sm:col-span-3">
                            <label htmlFor="af-account-password" className="inline-block text-sm text-gray-800 mt-2.5 dark:text-neutral-200">
                                Mot de passe
                            </label>
                        </div>
                        <div className="sm:col-span-9">
                            <div className="sm:flex">
                                <input id="af-account-password"
                                    className="py-2 px-3 pe-11 block w-full border-gray-200 shadow-sm -mt-px -ms-px first:rounded-t-lg last:rounded-b-lg sm:first:rounded-s-lg sm:mt-0 sm:first:ms-0 sm:first:rounded-se-none sm:last:rounded-es-none sm:last:rounded-e-lg text-sm relative focus:z-10 focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none dark:bg-neutral-800 dark:border-neutral-700 dark:text-neutral-400 dark:placeholder-neutral-500 dark:focus:ring-neutral-600"
                                    type="text"
                                    name="password"
                                    placeholder="Mot de passe..."
                                    value={currentUser.password}
                                    onChange={props.handleFieldChange}
                                />
                                <input
                                    className="py-2 px-3 pe-11 block w-full border-gray-200 shadow-sm -mt-px -ms-px first:rounded-t-lg last:rounded-b-lg sm:first:rounded-s-lg sm:mt-0 sm:first:ms-0 sm:first:rounded-se-none sm:last:rounded-es-none sm:last:rounded-e-lg text-sm relative focus:z-10 focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none dark:bg-neutral-800 dark:border-neutral-700 dark:text-neutral-400 dark:placeholder-neutral-500 dark:focus:ring-neutral-600"
                                    type="text"
                                    name="confirmPassword"
                                    placeholder="Confirmer le mot de passe..."
                                    value={currentUser.confirmPassword}
                                    onChange={props.handleFieldChange}
                                />
                            </div>
                        </div>
                    </>
                )}

                <div className="sm:col-span-3">
                    <label htmlFor="af-account-profils" className="inline-block text-sm text-gray-800 mt-2.5 dark:text-neutral-200">
                        Profils
                    </label>
                </div>
                <div className="sm:col-span-9">
                    <div className="grid sm:grid-cols-2 gap-2">
                        {props.userProfiles?.map(({ name, label }) => (
                            <label htmlFor={`hs-checkbox-in-form-${name}`} className="flex p-3 w-full bg-white border border-gray-200 rounded-lg text-sm focus:border-blue-500 focus:ring-blue-500 dark:bg-neutral-900 dark:border-neutral-700 dark:text-neutral-400">
                                <input type="checkbox" name={`profile-${name}`}
                                    className="shrink-0 mt-0.5 border-gray-200 rounded text-red-600 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none dark:bg-neutral-800 dark:border-neutral-700 dark:checked:bg-blue-500 dark:checked:border-blue-500 dark:focus:ring-offset-gray-800"
                                    id={`hs-checkbox-in-form-${name}`}
                                    onChange={props.handleFieldChange}
                                    checked={hasUserProfile(name)} />
                                <span className="text-sm text-gray-500 ms-3 dark:text-neutral-400">{label}</span>
                            </label>
                        ))}
                    </div>
                </div>

                <div className="sm:col-span-3">
                    <label htmlFor="af-account-statut" className="inline-block text-sm text-gray-800 mt-2.5 dark:text-neutral-200">
                        Statut
                    </label>
                </div>

                <div className="sm:col-span-9">
                    <div className="grid sm:grid-cols-2 gap-2">
                        {props.statuts?.map(({ nom, libelle }) => (
                            <label htmlFor={`statut-user-${nom}`}
                                className="flex p-3 w-full bg-white border border-gray-200 rounded-lg text-sm focus:border-blue-500 focus:ring-blue-500 dark:bg-neutral-900 dark:border-neutral-700 dark:text-neutral-400">
                                <input id={`statut-user-${nom}`}
                                    type="radio"
                                    name={`statut-user-${nom}`}
                                    className="shrink-0 mt-0.5 border-gray-200 rounded-full text-red-600 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none dark:bg-neutral-800 dark:border-neutral-700 dark:checked:bg-red-500 dark:checked:border-blue-500 dark:focus:ring-offset-gray-800"
                                    onChange={props.handleFieldChange}
                                    checked={currentUser.statut === nom} />
                                <span className="text-sm text-gray-500 ms-3 dark:text-neutral-400">
                                    {libelle}
                                </span>
                            </label>
                        ))}
                    </div>
                </div>

                <div className="sm:col-span-3">
                    <label htmlFor="af-account-notes" className="inline-block text-sm text-gray-800 mt-2.5 dark:text-neutral-200">
                        Notes
                    </label>
                </div>

                <div className="sm:col-span-9">
                    <textarea id="af-account-notes" name="notes"
                        className="py-2 px-3 block w-full border-gray-200 rounded-lg text-sm focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none dark:bg-neutral-800 dark:border-neutral-700 dark:text-neutral-400 dark:placeholder-neutral-500 dark:focus:ring-neutral-600"
                        rows={10} placeholder="Saisir vos notes ici..."
                        value={currentUser.notes}
                        onChange={props.handleFieldChange} />
                </div>
            </div>
        </form>
    );
};

export default UserForm;
