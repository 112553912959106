import {
    EtablissementModel,
    ProvinceModel,
    CommuneModel,
    CategorieEtablissementModel
} from '../../data/models';

type EtabliFormProps = {
    handleFieldChange: (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => void;
    handleSubmit: (e: React.MouseEvent) => void;
    handleClearForm?: (e: React.MouseEvent) => void | null;
    provinces: ProvinceModel[] | null;
    communes: CommuneModel[] | null;
    categories: CategorieEtablissementModel[] | null;
    etabli: EtablissementModel;
};

const EtabliForm = (props: EtabliFormProps) => {

    const currentEtabli = props.etabli;

    /*     const selectTypeTravaux = (travaux: string) => {
            return (currentProjet?.typesTravaux ? currentProjet?.profiles : []).indexOf(p.toUpperCase()) >= 0;
        } */

    return (
        <form>
            <div className="grid sm:grid-cols-12 gap-2 sm:gap-6">
                <div className="sm:col-span-3">
                    <label htmlFor="af-etab-nom" className="inline-block text-sm text-gray-800 mt-2.5 dark:text-neutral-200">
                        Nom
                    </label>
                </div>

                <div className="sm:col-span-9">
                    <input id="af-etab-nom"
                        className="py-2 px-3 pe-11 block w-full border-gray-200 shadow-sm -mt-px -ms-px first:rounded-t-lg last:rounded-b-lg sm:first:rounded-s-lg sm:mt-0 sm:first:ms-0 sm:first:rounded-se-none sm:last:rounded-es-none sm:last:rounded-e-lg text-sm relative focus:z-10 focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none dark:bg-neutral-800 dark:border-neutral-700 dark:text-neutral-400 dark:placeholder-neutral-500 dark:focus:ring-neutral-600"
                        type="text"
                        name="nom"
                        placeholder="Nom de l'établissement..."
                        value={currentEtabli.nom}
                        onChange={props.handleFieldChange}
                    />
                </div>

                <div className="sm:col-span-3">
                    <label htmlFor="af-etab-classe" className="inline-block text-sm text-gray-800 mt-2.5 dark:text-neutral-200">
                        Classe
                    </label>
                </div>

                <div className="sm:col-span-9">
                    <input id="af-etab-classe"
                        type="text" name="classe"
                        className="py-2 px-3 pe-11 block w-full border-gray-200 shadow-sm text-sm rounded-lg focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none dark:bg-neutral-800 dark:border-neutral-700 dark:text-neutral-400 dark:placeholder-neutral-500 dark:focus:ring-neutral-600"
                        placeholder="La classe..."
                        value={currentEtabli.classe}
                        onChange={props.handleFieldChange}
                    />
                </div>

                <div className="sm:col-span-3">
                    <label htmlFor="af-etab-province" className="inline-block text-sm text-gray-800 mt-2.5 dark:text-neutral-200">
                        Province
                    </label>
                </div>

                <div className="sm:col-span-9">
                    <select name="province"
                        className="py-2 px-3 pe-9 block w-full border-gray-200 shadow-sm text-sm rounded-lg focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none dark:bg-neutral-900 dark:border-neutral-700 dark:text-neutral-400 dark:placeholder-neutral-500 dark:focus:ring-neutral-600"
                        value={currentEtabli.province?.code}
                        onChange={props.handleFieldChange}>
                        <option value="">Choisir la province...</option>
                        {props.provinces?.map(({ code, nom }) => (
                            <option value={code} selected={currentEtabli.province ? currentEtabli.province.code === code : false}>{nom}</option>
                        ))}
                    </select>
                </div>

                <div className="sm:col-span-3">
                    <label htmlFor="af-etab-commune" className="inline-block text-sm text-gray-800 mt-2.5 dark:text-neutral-200">
                        Commune
                    </label>
                </div>

                <div className="sm:col-span-9">
                    <select name="commune"
                        className="py-2 px-3 pe-9 block w-full border-gray-200 shadow-sm text-sm rounded-lg focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none dark:bg-neutral-900 dark:border-neutral-700 dark:text-neutral-400 dark:placeholder-neutral-500 dark:focus:ring-neutral-600"
                        value={currentEtabli.commune?.code}
                        onChange={props.handleFieldChange}>
                        <option value="">Choisir la commune...</option>
                        {props.communes?.map(({ code, nom }) => (
                            <option value={code} selected={currentEtabli.commune ? currentEtabli.commune.code === code : false}>{nom}</option>
                        ))}
                    </select>
                </div>

                <div className="sm:col-span-3">
                    <label htmlFor="af-etab-localisation" className="inline-block text-sm text-gray-800 mt-2.5 dark:text-neutral-200">
                        Localisation géographique
                    </label>
                </div>
                <div className="sm:col-span-9">
                    <div className="sm:flex">
                        <input id="af-etab-localisation"
                            className="py-2 px-3 pe-11 block w-full border-gray-200 shadow-sm -mt-px -ms-px first:rounded-t-lg last:rounded-b-lg sm:first:rounded-s-lg sm:mt-0 sm:first:ms-0 sm:first:rounded-se-none sm:last:rounded-es-none sm:last:rounded-e-lg text-sm relative focus:z-10 focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none dark:bg-neutral-800 dark:border-neutral-700 dark:text-neutral-400 dark:placeholder-neutral-500 dark:focus:ring-neutral-600"
                            type="text"
                            name="latitude"
                            placeholder="Latitude..."
                            value={currentEtabli.latitude}
                            onChange={props.handleFieldChange}
                        />
                        <input
                            className="py-2 px-3 pe-11 block w-full border-gray-200 shadow-sm -mt-px -ms-px first:rounded-t-lg last:rounded-b-lg sm:first:rounded-s-lg sm:mt-0 sm:first:ms-0 sm:first:rounded-se-none sm:last:rounded-es-none sm:last:rounded-e-lg text-sm relative focus:z-10 focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none dark:bg-neutral-800 dark:border-neutral-700 dark:text-neutral-400 dark:placeholder-neutral-500 dark:focus:ring-neutral-600"
                            type="text"
                            name="longitude"
                            placeholder="Longitude..."
                            value={currentEtabli.longitude}
                            onChange={props.handleFieldChange}
                        />
                    </div>
                </div>

                <div className="sm:col-span-3">
                    <label htmlFor="" className="inline-block text-sm text-gray-800 mt-2.5 dark:text-neutral-200">
                        Catégorie
                    </label>
                </div>
                <div className="sm:col-span-9">
                    <div className="grid sm:grid-cols-2 gap-2">
                        {props.categories?.map(({ nom, libelle }) => (
                            <label htmlFor={`categorie-etabli-${nom}`}
                                className="flex p-3 w-full bg-white border border-gray-200 rounded-lg text-sm focus:border-blue-500 focus:ring-blue-500 dark:bg-neutral-900 dark:border-neutral-700 dark:text-neutral-400">
                                <input id={`categorie-etabli-${nom}`}
                                    type="radio"
                                    name={`categorie-etabli-${nom}`}
                                    className="shrink-0 mt-0.5 border-gray-200 rounded-full text-red-600 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none dark:bg-neutral-800 dark:border-neutral-700 dark:checked:bg-red-500 dark:checked:border-blue-500 dark:focus:ring-offset-gray-800"
                                    onChange={props.handleFieldChange}
                                    checked={currentEtabli.categorie?.nom === nom} />
                                <span className="text-sm text-gray-500 ms-3 dark:text-neutral-400">
                                    {libelle}
                                </span>
                            </label>
                        ))}
                    </div>
                </div>

            </div>
        </form>
    );
};

export default EtabliForm;
