import React, { } from "react";
import {
    ProjetModel,
    TypeTravauxModel,
    EtablissementModel,
    StatutProjetModel,
    InterventionModel,
    TrancheProgrammeModel
} from '../../data/models';
import DatePicker from "react-datepicker";
import { yesterday } from "../../utils/dateHelpers";

type ProjetFormProps = {
    setDebutProjet: (d: Date) => void;
    setFinProjet: (d: Date) => void;
    handleFieldChange: (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => void;
    handleSubmit: (e: React.MouseEvent) => void;
    handleClearForm?: (e: React.MouseEvent) => void | null;
    typesTravaux: TypeTravauxModel[] | null;
    etablissements?: EtablissementModel[] | null;
    statuts?: StatutProjetModel[] | null;
    phases?: InterventionModel[] | null;
    tranches?: TrancheProgrammeModel[] | null;
    projet: ProjetModel;
    editMode?: string;
};

const ProjetForm = (props: ProjetFormProps) => {

    const currentProjet = props.projet;

    const isStatutActive = (s: string): boolean => {
        if (currentProjet.statut && currentProjet.statut === s)
            return true;
        else return false;
    }

    return (
        <form>
            {(!props.editMode || props.editMode === "infos") && (
                <>
                    <div className="py-6 first:pt-0 last:pb-0 border-t first:border-transparent border-gray-200 dark:border-neutral-700 dark:first:border-transparent">
                        <div className="mt-2 space-y-8">
                            <div className="grid sm:flex gap-3">
                                <div className="space-y-1 w-full">
                                    <label htmlFor="greza" className="inline-block text-sm font-medium dark:text-white">
                                        N° Gresa
                                    </label>
                                    <input type="text" name="greza"
                                        className="py-2 px-3 pe-11 block w-full border-gray-200 shadow-sm text-sm rounded-lg focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none dark:bg-neutral-900 dark:border-neutral-700 dark:text-neutral-400 dark:placeholder-neutral-500 dark:focus:ring-neutral-600"
                                        placeholder="N° Gresa"
                                        value={currentProjet.greza} disabled
                                    />
                                </div>
                                <div className="space-y-1 w-full">
                                    <label htmlFor="numero" className="inline-block text-sm font-medium dark:text-white">
                                        Bon de commande
                                    </label>
                                    <input type="text" name="numero" id="af-projet-numero"
                                        className="py-2 px-3 pe-11 block w-full border-gray-200 shadow-sm text-sm rounded-lg focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none dark:bg-neutral-900 dark:border-neutral-700 dark:text-neutral-400 dark:placeholder-neutral-500 dark:focus:ring-neutral-600"
                                        placeholder="Numéro bon de commande"
                                        value={currentProjet.numero}
                                        onChange={props.handleFieldChange}
                                    />
                                </div>
                            </div>
                            <div className="grid sm:flex gap-3">
                                <div className="space-y-1 w-full">
                                    <label htmlFor="montantEstime" className="inline-block text-sm font-medium dark:text-white">
                                        Montant estimé
                                    </label>
                                    <input type="text" name="montantEstime"
                                        className="py-2 px-3 pe-11 block w-full border-gray-200 shadow-sm text-sm rounded-lg focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none dark:bg-neutral-900 dark:border-neutral-700 dark:text-neutral-400 dark:placeholder-neutral-500 dark:focus:ring-neutral-600"
                                        placeholder="Montant estimé"
                                        value={currentProjet.montantEstime}
                                        onChange={props.handleFieldChange}
                                    />
                                </div>
                                <div className="space-y-1 w-full">
                                    <label htmlFor="montant" className="inline-block text-sm font-medium dark:text-white">
                                        Montant
                                    </label>
                                    <input type="text" name="montant"
                                        className="py-2 px-3 pe-11 block w-full border-gray-200 shadow-sm text-sm rounded-lg focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none dark:bg-neutral-900 dark:border-neutral-700 dark:text-neutral-400 dark:placeholder-neutral-500 dark:focus:ring-neutral-600"
                                        placeholder="Montant"
                                        value={currentProjet.montant}
                                        onChange={props.handleFieldChange}
                                    />
                                </div>
                            </div>

                        </div>
                    </div>

                    <div className="py-6 first:pt-0 last:pb-0 border-t first:border-transparent border-gray-200 dark:border-neutral-700 dark:first:border-transparent">
                        <label htmlFor="af-projet-type-travaux" className="inline-block text-sm font-medium dark:text-white">
                            Type travaux
                        </label>
                        <div className="mt-2 space-y-3">
                            <select name="typeTravaux" id="af-projet-type-travaux"
                                className="py-2 px-3 pe-9 block w-full border-gray-200 shadow-sm text-sm rounded-lg focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none dark:bg-neutral-900 dark:border-neutral-700 dark:text-neutral-400 dark:placeholder-neutral-500 dark:focus:ring-neutral-600"
                                value={currentProjet.typeTravaux}
                                onChange={props.handleFieldChange}>
                                <option value="">Catégorie des travaux...</option>
                                {props.typesTravaux?.map(({ nom, libelle }) => (
                                    <option value={nom} selected={currentProjet.typeTravaux ? currentProjet.typeTravaux === nom : false}>{libelle}</option>
                                ))}
                            </select>
                        </div>
                    </div>

                    <div className="py-6 first:pt-0 last:pb-0 border-t first:border-transparent border-gray-200 dark:border-neutral-700 dark:first:border-transparent">
                        <label htmlFor="af-projet-etablissement" className="inline-block text-sm font-medium dark:text-white">
                            Etablissement
                        </label>
                        <div className="mt-2 space-y-3">
                            <select name="etablissement" id="af-projet-etablissement"
                                className="py-2 px-3 pe-9 block w-full border-gray-200 shadow-sm text-sm rounded-lg focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none dark:bg-neutral-900 dark:border-neutral-700 dark:text-neutral-400 dark:placeholder-neutral-500 dark:focus:ring-neutral-600"
                                value={currentProjet.etablissement?.sid}
                                onChange={props.handleFieldChange}>
                                <option value="">Choisir l'établissement...</option>
                                {props.etablissements?.map(({ sid, nom }) => (
                                    <option value={sid} selected={currentProjet.etablissement?.sid ? currentProjet.etablissement?.sid === sid : false}>{nom}</option>
                                ))}
                            </select>
                        </div>
                    </div>
                    <div className="py-6 first:pt-0 last:pb-0 border-t first:border-transparent border-gray-200 dark:border-neutral-700 dark:first:border-transparent">
                        <label htmlFor="af-projet-tranche" className="inline-block text-sm font-medium dark:text-white">
                            Tranche
                        </label>
                        <div className="mt-2 space-y-3">
                            <select name="tranche" id="af-projet-tranche"
                                className="py-2 px-3 pe-9 block w-full border-gray-200 shadow-sm text-sm rounded-lg focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none dark:bg-neutral-900 dark:border-neutral-700 dark:text-neutral-400 dark:placeholder-neutral-500 dark:focus:ring-neutral-600"
                                value={currentProjet.tranche?.nom}
                                onChange={props.handleFieldChange}>
                                <option value="">Tranche du programme...</option>
                                {props.tranches?.map(({ nom, libelle }) => (
                                    <option value={nom} selected={currentProjet.tranche?.nom ? currentProjet.tranche?.nom === nom : false}>{libelle}</option>
                                ))}
                            </select>
                        </div>
                    </div>
                    <div className="py-6 first:pt-0 last:pb-0 border-t first:border-transparent border-gray-200 dark:border-neutral-700 dark:first:border-transparent">
                        <label htmlFor="af-projet-debut-travaux" className="inline-block text-sm font-medium dark:text-white">
                            Début projet
                        </label>
                        <div className="mt-2 space-y-3">
                            <DatePicker id="af-projet-debut-travaux"
                                className="py-2 px-3 pe-11 block w-full border-gray-200 shadow-sm text-sm rounded-lg focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none dark:bg-neutral-900 dark:border-neutral-700 dark:text-neutral-400 dark:placeholder-neutral-500 dark:focus:ring-neutral-600"
                                selected={currentProjet.debut}
                                onChange={(date: Date) => props.setDebutProjet(date)}
                                dateFormat="dd/MM/yyyy"
                            />
                        </div>
                    </div>
                    <div className="py-6 first:pt-0 last:pb-0 border-t first:border-transparent border-gray-200 dark:border-neutral-700 dark:first:border-transparent">
                        <label htmlFor="af-projet-delai-contractuel" className="inline-block text-sm font-medium dark:text-white">
                            Délai contractuel (en jours)
                        </label>
                        <div className="mt-2 space-y-3">
                            <input type="text" id="af-projet-delai-contractuel"
                                name="delaiContractuel"
                                className="py-2 px-3 pe-11 block w-full border-gray-200 shadow-sm text-sm rounded-lg focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none dark:bg-neutral-900 dark:border-neutral-700 dark:text-neutral-400 dark:placeholder-neutral-500 dark:focus:ring-neutral-600"
                                placeholder="Délai en jours (exemple 12, 23, 36)"
                                value={currentProjet.delaiContractuel}
                                onChange={props.handleFieldChange}
                            />
                        </div>
                    </div>
                </>
            )}
            {(!props.editMode || props.editMode === "etat") && (
                <>
                    <div className="py-6 first:pt-0 last:pb-0 border-t first:border-transparent border-gray-200 dark:border-neutral-700 dark:first:border-transparent">
                        <label htmlFor="af-projet-avancement" className="inline-block text-sm font-medium dark:text-white">
                            Taux d'avancement
                        </label>
                        <div className="mt-2 space-y-3">
                            <input type="text" id="af-projet-avancement"
                                name="avancement"
                                className="py-2 px-3 pe-11 block w-full border-gray-200 shadow-sm text-sm rounded-lg focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none dark:bg-neutral-900 dark:border-neutral-700 dark:text-neutral-400 dark:placeholder-neutral-500 dark:focus:ring-neutral-600"
                                placeholder="Taux d'avancement du projet en poucentage (exemple 15, 53, 76)"
                                value={currentProjet.avancement}
                                onChange={props.handleFieldChange}
                            />
                        </div>
                    </div>

                    <div className="py-6 first:pt-0 last:pb-0 border-t first:border-transparent border-gray-200 dark:border-neutral-700 dark:first:border-transparent">
                        <label
                            className="inline-block text-sm font-medium dark:text-white">
                            Statut
                        </label>
                        <div className="mt-2 space-y-3">
                            {props.statuts?.map(({ nom, libelle }) => (
                                <label htmlFor={`statut-projet-${nom}`}
                                    className="flex p-3 w-full bg-white border border-gray-200 rounded-lg text-sm focus:border-blue-500 focus:ring-blue-500 dark:bg-neutral-900 dark:border-neutral-700 dark:text-neutral-400">
                                    <input id={`statut-projet-${nom}`}
                                        type="radio"
                                        name={`statut-projet-${nom}`}
                                        className="shrink-0 mt-0.5 border-gray-200 rounded-full text-red-600 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none dark:bg-neutral-800 dark:border-neutral-700 dark:checked:bg-red-500 dark:checked:border-blue-500 dark:focus:ring-offset-gray-800"
                                        onChange={props.handleFieldChange}
                                        checked={isStatutActive(nom)} />
                                    <span className="text-sm text-gray-500 ms-3 dark:text-neutral-400">
                                        {libelle}
                                    </span>
                                </label>
                            ))}
                        </div>
                    </div>

                    <div className="py-6 first:pt-0 last:pb-0 border-t first:border-transparent border-gray-200 dark:border-neutral-700 dark:first:border-transparent">
                        <label
                            className="inline-block text-sm font-medium dark:text-white">
                            Phase
                        </label>
                        <div className="mt-2 space-y-3">
                            <div className="grid grid-cols-2 gap-2">
                                {props.phases?.map(({ sid, nom }) => (
                                    <label htmlFor={`phase-projet-${sid}`}
                                        className="flex p-3 w-full bg-white border border-gray-200 rounded-lg text-sm focus:border-blue-500 focus:ring-blue-500 dark:bg-neutral-900 dark:border-neutral-700 dark:text-neutral-400">
                                        <input id={`phase-projet-${sid}`}
                                            type="radio"
                                            name={`phase-projet-${sid}`}
                                            className="shrink-0 mt-0.5 border-gray-200 rounded-full text-red-600 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none dark:bg-neutral-800 dark:border-neutral-700 dark:checked:bg-red-500 dark:checked:border-blue-500 dark:focus:ring-offset-gray-800"
                                            onChange={props.handleFieldChange}
                                            checked={currentProjet.typeIntervention?.sid === sid} />
                                        <span className="text-sm text-gray-500 ms-3 dark:text-neutral-400">
                                            {nom}
                                        </span>
                                    </label>
                                ))}
                            </div>
                        </div>
                    </div>
                </>
            )}
        </form>
    );
};

export default ProjetForm;
