import { formatDate } from '../../../../utils/dateHelpers';
import { AvancementEtapeProjetModel } from '../../../../data/models';
import env from '../../../../utils/env';

type ThisProps = {
    avancement: AvancementEtapeProjetModel;
    handleDownloadPieceJointeClick: (pieceJointeId: any, e: React.MouseEvent<HTMLElement>) => void;
};
const AvancementEtapeTableRow = (props: ThisProps) => {

    return (
        <tr>
            <td className="">
                <div className="px-6 py-3">
                    <span className="text-sm text-gray-500 dark:text-neutral-500">
                        {formatDate(props.avancement.creation, 'DD/MM/YYYY')}
                    </span>
                </div>
            </td>
            <td className="">
                <div className="px-3 py-3">
                    <span className="text-sm text-gray-500 dark:text-neutral-500">
                        {props.avancement.user?.firstName + ' ' + props.avancement.user?.lastName}
                    </span>
                </div>
            </td>
            <td className="">
                <div className="px-3 py-3">
                    <span className="text-sm text-gray-500 dark:text-neutral-500">
                        {props.avancement.pourcentage + '%'}
                    </span>
                </div>
            </td>
            <td className="">
                <div className="px-3 py-3">
                    {props.avancement.latitude && props.avancement.longitude ?
                        (
                            <a href={`https://www.google.com/maps/search/?api=1&query=${props.avancement.latitude},${props.avancement.longitude}`}
                                target="_blank"
                                className="inline-flex items-center gap-x-1 text-sm text-red-600 decoration-2 hover:underline font-medium dark:text-red-500">
                                {props.avancement.latitude + ',' + props.avancement.longitude}
                            </a>
                        ) : (
                            <span className="text-sm text-gray-500 dark:text-neutral-500">
                                ?,?
                            </span>
                        )}
                </div>
            </td>
            <td className="">
                <div className="px-3 py-3">
                    <span className="text-sm text-gray-500 dark:text-neutral-500">
                        {props.avancement.blocage?'Oui':'Non'}
                    </span>
                </div>
            </td>
            <td className="">
                <div className="px-3 py-3">
                    <span className="text-sm text-gray-500 dark:text-neutral-500">
                        {props.avancement.detailBlocage}
                    </span>
                </div>
            </td>
            <td className="">
                <div className="px-3 py-3">
                    <span className="text-sm text-gray-500 dark:text-neutral-500">
                        {props.avancement.commentaire}
                    </span>
                </div>
            </td>
            <td className="">
                {props.avancement.pieceJointe && (
                    <div className="px-6 py-1.5">
                        <a onClick={(e: React.MouseEvent<HTMLElement>) => props.handleDownloadPieceJointeClick(props.avancement.pieceJointe?.sid, e)}
                            href={`${env.API_URL}/api/documents/${props.avancement.pieceJointe.sid}`}
                            target="_blank"
                            className="inline-flex items-center gap-x-1 text-sm text-red-600 decoration-2 hover:underline font-medium dark:text-red-500">
                            Pièce jointe
                        </a>
                    </div>
                )}
            </td>
        </tr>
    );
};

export default AvancementEtapeTableRow;
