import { useEffect } from 'react';
import { Outlet, useNavigate, useLocation } from 'react-router-dom';
import { useAuthContext } from './contexts/AuthContextProvider';
import CustomToastContainer from './components/ToastContainer';
import './App.css';
import Topbar from './components/topbar/Topbar';
import Sidebar from './components/sidebar/Sidebar';
import { registerLocale, setDefaultLocale } from "react-datepicker";
import { fr } from 'date-fns/locale/fr';

import "preline/preline";
import { IStaticMethods } from "preline/preline";
declare global {
  interface Window {
    HSStaticMethods: IStaticMethods;
  }
}

registerLocale('fr', fr)

const App = () => {
  const location = useLocation();
  const { authState, checkTokenExpiration } = useAuthContext();
  const navigate = useNavigate();

  const { token, isLogged } = authState;

  useEffect(() => {
    checkTokenExpiration();
  }, []);


  useEffect(() => {
    window.HSStaticMethods.autoInit();
  }, [location.pathname]);

  setDefaultLocale('fr');
  
  return (
    <>
      <Topbar />
      <Sidebar />
      <Outlet />
      <CustomToastContainer />
    </>
  );
};

export default App;
