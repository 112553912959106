export const formatPrice = (price: number) => {
  const formatter = new Intl.NumberFormat("fr-FR", {
    style: 'currency',
    currency: "MAD",
    minimumFractionDigits: 2,
    useGrouping: true
  });
  return formatter.format(price).replace("MAD","").replaceAll(".", ",");
};

export const isFixed = (o: any) => {
  const s = String(o)
  return !isNaN(+s) && isFinite(+s) && (typeof o === 'number' || !/e/i.test(s))
};

export const isInt = (n: number) => {
  return n % 1 === 0;
};
