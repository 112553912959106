import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useFetch } from '../../../../hooks/useFetch';
import env from '../../../../utils/env';
import Alert from "../../../../components/Alert";
import { AvancementDecompteModel, DecompteProjetModel } from '../../../../data/models';
import { useAuthContext } from '../../../../contexts/AuthContextProvider';
import AvancementDecompteForm from '../../../../components/projet/decompte/avancement/AvancementDecompteForm';
import { isFixed, isInt } from '../../../../utils/helpers';

const AddAvancementDecomptePage = () => {

  const [avancement, setAvancement] = useState<AvancementDecompteModel>({} as AvancementDecompteModel);
  const [file, setFile] = useState<File | null>(null);

  const [error, setError] = useState('');
  const [validationErrors, setValidationErrors] = useState<string[]>([]);
  const [successful, setSuccessful] = useState<boolean>(false);

  const { authState } = useAuthContext();
  const authorizationHeader = authState.token;

  const { sid: projetId } = useParams() || "";
  if (!projetId || !projetId.trim()) {
    setError("L'identifiant projet fourni est erroné!");
  }

  const { did: decompteId } = useParams() || "";
  if (!decompteId || !decompteId.trim()) {
    setError("L'identifiant décompte fourni est erroné!");
  }

  const { data: loadDecompteResp, error: loadDecompteError } = useFetch<DecompteProjetModel>(
    `${env.API_URL}/api/projets/${projetId}/decomptes/${decompteId}`
  );

  useEffect(() => {
    if (loadDecompteResp) {
      setAvancement(a => ({
        ...a,
        decompte: {
          ...a.decompte,
          sid: loadDecompteResp.sid
        }
      }))
    }
    if (loadDecompteError) {
      setError(loadDecompteError);
    }
  }, [loadDecompteResp]);

  const formData = new FormData();

  const {
    error: postAvancementError,
    data: postAvancementResp,
    sendRequest: postAvancement
  } = useFetch<FormData>(
    `${env.API_URL}/api/projets/${projetId}/decomptes/${decompteId}/avancements`,
    {
      method: 'POST',
      payload: formData
    }
  );

  useEffect(() => {
    if (postAvancementResp) {
      setSuccessful(true);
      setError('');
    }
  }, [postAvancementResp]);

  useEffect(() => {
    if (postAvancementError) {
      setError(postAvancementError);
      setSuccessful(false);
    }
  }, [postAvancementError]);

  const handleOnSubmit = (e: React.MouseEvent) => {
    e.preventDefault();

    setError('');
    setValidationErrors([]);
    
    let errorMsg = 'Merci de bien vouloir vérifier les champs suivants :\n';
    let errors: string[] = [];

    if (!avancement.pourcentage
      || !isFixed(avancement.pourcentage)) {
      errors.push("Le pourcentage est obligatoire et doit être un nombre");
    } else {
      var percent: number = Number(avancement.pourcentage);
      if (!isInt(percent) || percent < 0 || percent > 100) {
        errors.push("Le pourcentage doit être un nombre positif qui n'est pas une fraction (entier), situé entre 0 et 100 inclus");
      }
    }

    if (errors.length == 0) {
      handleUpload();
    } else {
      setError(errorMsg);
      setValidationErrors(errors);
    }
  };

  const handleUpload = async () => {

    const avancementJson = JSON.stringify(avancement);
    const avancementBlob = new Blob([avancementJson], {
      type: 'application/json'
    })
    const formData = new FormData();

    if (file) {
      formData.append("document", file, file.name);
    }
    formData.append("avancement", avancementBlob);

    try {
      const result = await fetch(
        `${env.API_URL}/api/projets/${projetId}/decomptes/${decompteId}/avancements`,
        {
          method: "POST",
          body: formData,
          headers: { 'authorization': `Bearer ${authorizationHeader}` }
        });

      const resp = await result.json();

      if ('sid' in resp) {
        setAvancement(resp);
        setSuccessful(true);
        setError('');
      }
      else {
        setSuccessful(false);
        setError(resp.message);
      }
    } catch (e) {
      console.error(e);
      setError("Une erreur est survenue lors de l'envoi du formulaire");
    }

  };

  const handleFieldChange = (e: React.ChangeEvent<HTMLInputElement
    | HTMLTextAreaElement | HTMLSelectElement>) => {
    const { name, value, type } = e.target;
  
    if (type === 'checkbox' && name.startsWith('blocage')) {
      var isBlocage = avancement.blocage;
      if ('checked' in e.target && !e.target.checked) {
        isBlocage = false;
      }
      if ('checked' in e.target && e.target.checked) {
        isBlocage = true;
      }
      setAvancement(a => ({
        ...a,
        blocage: isBlocage
      }));
    }
    if (type === 'text' || type === 'textarea') {
      setAvancement(a => ({
        ...a,
        [name]: value || undefined
      }));
    }
  };

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      setFile(e.target.files[0]);
    }
  };

  return (
    <div className="w-full lg:ps-64">
      <div className="p-4 sm:p-6 space-y-4 sm:space-y-6">

        <div className="flex flex-col">
          <div className="overflow-x-auto">
            <div className="p-1.5 min-w-full inline-block align-middle">
              <div className="bg-white border border-gray-200 rounded-xl shadow-sm overflow-hidden dark:bg-neutral-800 dark:border-neutral-700">

                <div className="px-6 py-4 grid gap-3 md:flex md:justify-between md:items-center border-b border-gray-200 dark:border-neutral-700">
                  <div>
                    <h2 className="text-xl font-semibold text-gray-800 dark:text-neutral-200">
                      Saisie d'avancement du décompte projet N° {loadDecompteResp?.numero}
                    </h2>
                    <p className="text-sm text-gray-600 dark:text-neutral-400">
                      
                    </p>
                  </div>

                  <div>
                    <div className="inline-flex gap-x-2">
                    </div>
                  </div>
                </div>

                <div className="px-14 py-16">
                  {(validationErrors.length > 0 || error) && (
                    <Alert
                      type="error"
                      title={error}
                      details={validationErrors}
                    />
                  )}
                  {successful && (
                    <Alert
                      type="success"
                      title="L'avancement de décompte a bien été enregistré"
                      showLinks={true}
                      linkText='Aller à la liste des avancements'
                      toLink={`/projets/${projetId}/decomptes/${decompteId}/avancements`}
                    />
                  )}
                  <AvancementDecompteForm
                    handleFieldChange={handleFieldChange}
                    handleFileChange={handleFileChange}
                    handleSubmit={handleOnSubmit}
                    avancement={avancement} file={file} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddAvancementDecomptePage;
