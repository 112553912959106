import { useState, useEffect } from 'react';
import {
  ProjetModel, EtablissementModel,
  EtapeProjetModel,
  AvancementEtapeProjetModel
} from '../../data/models';
import FicheProjet from './FicheProjet';
import env from '../../utils/env';
import { useFetch } from '../../hooks/useFetch';
import EmptyState from '../../components/EmptyState';
import { formatDate } from '../../utils/dateHelpers';
import { useAuthContext } from '../../contexts/AuthContextProvider';
import { Link } from 'react-router-dom';


const ViewAvancementsProjetPage = () => {

  const [etapes, setEtapes] = useState<EtapeProjetModel[]>([]);
  const [avancements, setAvancements] = useState<AvancementEtapeProjetModel[]>([]);
  const [error, setError] = useState('');
  const [selectedEtape, setSelectedEtape] = useState(0);

  const { hasAuthority } = useAuthContext();

  const [projet, setProjet] = useState<ProjetModel>({
    etablissement: {} as EtablissementModel
  } as ProjetModel);

  const {
    data: loadEtapesResp,
    sendRequest: loadEtapes,
    error: loadEtapesError
  } = useFetch<EtapeProjetModel[]>(
    `${env.API_URL}/api/projets/${projet.sid}/etapes`,
    { method: 'GET' }
  );

  const {
    data: loadAvancementsResp,
    sendRequest: loadAvancements,
    error: loadAvancementsError
  } = useFetch<AvancementEtapeProjetModel[]>(
    `${env.API_URL}/api/projets/${projet.sid}/etapes/${etapes[selectedEtape]?.sid}/avancements`,
    { method: 'GET' }
  );

  useEffect(() => {
    if (loadEtapesResp) {
      setEtapes(loadEtapesResp);
      setSelectedEtape(0);
    }
  }, [loadEtapesResp]);

  useEffect(() => {
    if (loadAvancementsResp) { setAvancements(loadAvancementsResp); }
  }, [loadAvancementsResp]);

  useEffect(() => {
    loadEtapes();
  }, [projet]);

  useEffect(() => {
    loadAvancements();
  }, [selectedEtape]);

  const selectEtape = (index: number) => {
    setAvancements([]);
    setSelectedEtape(index);
  }

  return (
    <FicheProjet tab="AVANCEMENTS" updateProjet={setProjet}>

      <div className="px-4 py-10">
        <div className="mb-5 pb-5 flex justify-between items-center border-b border-gray-200 dark:border-neutral-700">
          <div>
            <h2 className="text-2xl font-semibold text-gray-800 dark:text-neutral-200">
              Étapes
            </h2>
          </div>

          <div className="inline-flex gap-x-2">
            {hasAuthority('VIEW_ETAPES_PROJET') && (
              <Link to={`/projets/${projet.sid}/etapes`}
                className="py-2 px-3 inline-flex items-center gap-x-2 text-sm font-semibold rounded-lg border border-transparent bg-red-600 text-white hover:bg-red-700 disabled:opacity-50 disabled:pointer-events-none">
                Mettre à jour
              </Link>
            )}
          </div>
        </div>

        {/* <!-- Liste des étapes --> */}
        <div className="bg-gray-50 dark:bg-neutral-900 max-w-5xl px-4 py-6 mx-auto">
          {!etapes || etapes.length == 0
            ? (
              <EmptyState
                title="Aucune étape projet n'a été trouvée"
                description="Vous pouvez commencer par en créer..." />)
            : (

              <div className="grid sm:grid-cols-2 lg:grid-cols-3 gap-3 sm:gap-6">

                {etapes.map((etape, index) => (
                  <button type="button" onClick={(e) => selectEtape(index)}
                    className={(index == selectedEtape ? 'active ' : '') + "hs-tab-active:bg-white hs-tab-active:shadow-md hs-tab-active:hover:border-transparent text-start hover:bg-gray-200 p-4 md:p-5 rounded-xl dark:hs-tab-active:bg-neutral-700 dark:hover:bg-neutral-700"}
                    id={"tabs-with-card-item-" + index}
                    data-hs-tab="#tabs-with-card-1" aria-controls="tabs-with-card-1" role="tab">
                    <span className="block text-base font-semibold hs-tab-active:text-red-600 text-gray-800 dark:hs-tab-active:text-red-500 dark:text-neutral-200">{etape.typeIntervention?.nom}</span>
                  </button>
                ))}

              </div>
            )}
        </div>
        <div className="mt-10">
          <div id="tabs-with-card-1" role="tabpanel" aria-labelledby="tabs-with-card-item-1">
            {etapes && etapes.length > 0 && (
              <div>
                <div className="relative grid grid-cols-2 gap-3">
                  <div>
                    <div className="grid space-y-3">
                      <dl className="grid sm:flex gap-x-3 text-sm">
                        <dt className="min-w-36 max-w-[200px] text-gray-500 dark:text-neutral-500">
                          Date début:
                        </dt>
                        <dd className="font-medium text-gray-800 dark:text-neutral-200">
                          {formatDate(etapes[selectedEtape].debut, 'DD/MM/YYYY')}
                        </dd>
                      </dl>

                      <dl className="grid sm:flex gap-x-3 text-sm">
                        <dt className="min-w-36 max-w-[200px] text-gray-500 dark:text-neutral-500">
                          Date fin:
                        </dt>
                        <dd className="font-medium text-gray-800 dark:text-neutral-200">
                          {formatDate(etapes[selectedEtape].fin, 'DD/MM/YYYY')}
                        </dd>
                      </dl>

                      <dl className="grid sm:flex gap-x-3 text-sm">
                        <dt className="min-w-36 max-w-[200px] text-gray-500 dark:text-neutral-500">
                          Délai écoulé:
                        </dt>
                        <dd className="font-medium text-gray-800 dark:text-neutral-200">
                          {etapes[selectedEtape].delaiEcoule + ' jours'}
                        </dd>
                      </dl>
                      <dl className="grid sm:flex gap-x-3 text-sm">
                        <dt className="min-w-36 max-w-[200px] text-gray-500 dark:text-neutral-500">
                          Taux complétion:
                        </dt>
                        <dd className="font-medium text-gray-800 dark:text-neutral-200">
                          {etapes[selectedEtape].tauxCompletion + '%'}
                        </dd>
                      </dl>
                    </div>
                  </div>

                  <div>
                    <div className="grid space-y-3">
                      <dl className="grid sm:flex gap-x-3 text-sm">
                        <dt className="min-w-36 text-gray-500 dark:text-neutral-500">
                          Description:
                        </dt>
                        <dd className="not-italic font-normal text-gray-800 dark:text-neutral-200">
                          {etapes[selectedEtape].description}
                        </dd>
                      </dl>

                    </div>
                  </div>
                </div>

                {!avancements || avancements.length == 0
                  ? (
                    <EmptyState
                      title="Aucun avancement n'a été trouvé"
                      description="Vous pouvez commencer par en créer..." />)
                  : (
                    <div className="block mt-10 mb-10 overflow-x-auto">
                      <table className="min-w-full divide-y divide-gray-200 dark:divide-neutral-700">
                        <thead className="bg-gray-50 dark:bg-neutral-800">
                          <tr>
                            <th scope="col" className="px-3 py-3 text-start">
                              <div className="flex items-center gap-x-2">
                                <span className="text-xs font-semibold uppercase tracking-wide text-gray-800 dark:text-neutral-200">
                                  Création
                                </span>
                              </div>
                            </th>

                            <th scope="col" className="px-3 py-3 text-start">
                              <div className="flex items-center gap-x-2">
                                <span className="text-xs font-semibold uppercase tracking-wide text-gray-800 dark:text-neutral-200">
                                  Responsable
                                </span>
                              </div>
                            </th>

                            <th scope="col" className="px-3 py-3 text-start">
                              <div className="flex items-center gap-x-2">
                                <span className="text-xs font-semibold uppercase tracking-wide text-gray-800 dark:text-neutral-200">
                                  %
                                </span>
                              </div>
                            </th>

                            <th scope="col" className="px-3 py-3 text-start">
                              <div className="flex items-center gap-x-2">
                                <span className="text-xs font-semibold uppercase tracking-wide text-gray-800 dark:text-neutral-200">
                                  Commentaire
                                </span>
                              </div>
                            </th>
                            <th scope="col" className="px-3 py-3 text-start">
                              <div className="flex items-center gap-x-2">
                                <span className="text-xs font-semibold uppercase tracking-wide text-gray-800 dark:text-neutral-200">
                                  Blocage
                                </span>
                              </div>
                            </th>
                            <th scope="col" className="px-3 py-3 text-start">
                              <div className="flex items-center gap-x-2">
                                <span className="text-xs font-semibold uppercase tracking-wide text-gray-800 dark:text-neutral-200">
                                  Détails
                                </span>
                              </div>
                            </th>
                            <th scope="col" className="px-6 py-3 text-end"></th>
                          </tr>
                        </thead>

                        <tbody className="divide-y divide-gray-200 dark:divide-neutral-700">

                          {avancements.map((avancement, index) => (
                            <tr>
                              <td className="whitespace-nowrap">
                                <div className="px-3 py-3">
                                  <span className="text-sm text-gray-500 dark:text-neutral-500">
                                    {formatDate(avancement.creation, 'DD/MM/YYYY')}
                                  </span>
                                </div>
                              </td>
                              <td className="whitespace-nowrap">
                                <div className="px-3 py-3">
                                  <span className="text-sm text-gray-500 dark:text-neutral-500">
                                    {avancement.user?.firstName + ' ' + avancement.user?.lastName}
                                  </span>
                                </div>
                              </td>
                              <td className="whitespace-nowrap">
                                <div className="px-3 py-3">
                                  <span className="text-sm text-gray-500 dark:text-neutral-500">
                                    {avancement.pourcentage + '%'}
                                  </span>
                                </div>
                              </td>
                              <td className="size-px whitespace-nowrap">
                                <div className="px-3 py-3">
                                  <span className="text-sm text-gray-500 dark:text-neutral-500">
                                    {avancement.commentaire}
                                  </span>
                                </div>
                              </td>
                              <td className="whitespace-nowrap">
                                <div className="px-3 py-3">
                                  <span className="text-sm text-gray-500 dark:text-neutral-500">
                                    {avancement.blocage}
                                  </span>
                                </div>
                              </td>
                              <td className="whitespace-nowrap">
                                <div className="px-3 py-3">
                                  <span className="text-sm text-gray-500 dark:text-neutral-500">
                                    {avancement.detailBlocage}
                                  </span>
                                </div>
                              </td>
                              <td className="size-px whitespace-nowrap">
                                <div className="px-6 py-3">
                                  {avancement.pieceJointe && (
                                    <a href={`${env.API_URL}/api/documents/${avancement.pieceJointe.sid}`}
                                      target="_blank"
                                      className="inline-flex items-center gap-x-1 text-sm text-red-600 decoration-2 hover:underline font-medium dark:text-red-500">
                                      Pièce jointe
                                    </a>
                                  )}
                                </div>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  )}
              </div>
            )}
          </div>
        </div>
      </div>
    </FicheProjet >
  );
};

export default ViewAvancementsProjetPage;
