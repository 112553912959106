import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import UserForm from '../../components/user/UserForm';
import { StatutUserModel, UserModel, UserProfile } from '../../data/models';
import { useFetch } from '../../hooks/useFetch';
import env from '../../utils/env';
import Alert from "../../components/Alert";
import { useAuthContext } from '../../contexts/AuthContextProvider';

const EditUserPage = () => {

  const [_user, setUser] = useState<UserModel>({} as UserModel);
  const [error, setError] = useState('');
  const [canUpdatePassword, setCanUpdatePassword] = useState<boolean>(false);
  const [validationErrors, setValidationErrors] = useState<string[]>([]);
  const [successful, setSuccessful] = useState<boolean>(false);
  const [selectedProfiles, setSelectedProfiles] = useState<string[]>([]);

  const { authState, hasAuthority } = useAuthContext();
  const {profiles} = authState;

  useEffect(() => {
    if (profiles) {
      setCanUpdatePassword(canUpdatePassword || profiles.some(p => p.name === 'ADMIN'));
    }
  }, [profiles]);

  const { email: userEmail } = useParams() || "";
  if (!userEmail || userEmail.trim().length == 0) {
    setError("L'adresse email fournie est invalide!");
  }

  const { data: loadUserResp, error: loadUserError } = useFetch<UserModel>(
    `${env.API_URL}/api/users/${userEmail}`
  );
  const { data: userProfiles, error: loadUserProfilesError } = useFetch<UserProfile[]>(
    `${env.API_URL}/api/refs/profiles`
  );
  const { data: statuts, error: loadUserStatutsError } = useFetch<StatutUserModel[]>(
    `${env.API_URL}/api/refs/user/statuts`
  );

  useEffect(() => {
    if (loadUserProfilesError) { setError(loadUserProfilesError); }
    if (loadUserStatutsError) { setError(loadUserStatutsError); }
  }, [loadUserProfilesError, loadUserStatutsError]);

  useEffect(() => {
    if (loadUserResp) {
      setUser(loadUserResp);
      setSelectedProfiles(_user.profiles);
      setCanUpdatePassword(canUpdatePassword || (loadUserResp.email.toLowerCase() === authState.username?.toLowerCase()));
    } else if (loadUserError) {
      setError(loadUserError);
    }
  }, [loadUserResp]);

  useEffect(() => {
    if (_user.profiles && _user.profiles.length > 0) {
      setSelectedProfiles(_user.profiles);
    }
  }, [_user]);

  const putUserConfig = {
    method: 'PUT',
    payload: _user
  } as const;

  const { error: putUserError, data: putUserResp, sendRequest: putUser } = useFetch<UserModel>(
    `${env.API_URL}/api/users/${userEmail}`,
    putUserConfig
  );

  // Check if user has been updated
  useEffect(() => {
    if (putUserResp) {
      setSuccessful(true);
      setError('');
    }
  }, [putUserResp]);
  useEffect(() => {
    if (putUserError) {
      setError(putUserError);
      setSuccessful(false);
    }
  }, [putUserError]);

  const handleOnSubmit = (e: React.MouseEvent) => {
    e.preventDefault();
    setError('');
    setValidationErrors([]);
    setSuccessful(false);
    let errorMsg = 'Merci de bien vouloir saisir les champs suivants :\n';
    let errors = [];

    let value = `${_user.email}`.trim();
    if (!value || value.length <= 0) {
      errors.push("L'adresse Email est obligatoire");
    }

    if (canUpdatePassword && _user.password && _user.password !== _user.confirmPassword) {
      errors.push("Les mots de passe que vous avez entrés ne sont pas identiques");
    }

    value = `${_user.firstName} ${_user.lastName}`.trim();
    if (!value || value.length <= 0) {
      errors.push("Au moins le prénom ou le nom doit être saisi");
    }

    if (errors.length == 0) {
      putUser();
    } else {
      setError(errorMsg);
      setValidationErrors(errors);
    }
  };

  const handleFieldChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value, type } = e.target;
    let oldUser = { ..._user };
    if (type === 'checkbox' && name.startsWith('profile-')) {
      var profile = name.replace(/profile-/gi, "");
      var index = selectedProfiles.indexOf(profile);
      if ('checked' in e.target && !e.target.checked && index >= 0) {
        selectedProfiles.splice(index, 1);
      }
      if ('checked' in e.target && e.target.checked && index < 0) {
        selectedProfiles.push(profile);
      }
      oldUser.profiles = selectedProfiles;
      setUser(prevUser => ({
        ...oldUser
      }));
    }
    else if (name.startsWith('statut-user-')) {
      let statutVal = name.replace(/statut-user-/gi, "");
      setUser(u => ({
        ...u,
        statut: statutVal
      }))
    }

    if (type === 'text' || type === 'email' || type === 'textarea') {
      setUser(prevUser => ({
        ...prevUser,
        [name]: value
      }));
    }

  };

  return (
    <div className="w-full lg:ps-64">
      <div className="p-4 sm:p-6 space-y-4 sm:space-y-6">

        <div className="flex flex-col">
          <div className="-m-1.5 overflow-x-auto">
            <div className="p-1.5 min-w-full inline-block align-middle">
              <div className="bg-white border border-gray-200 rounded-xl shadow-sm overflow-hidden dark:bg-neutral-800 dark:border-neutral-700">

                <div className="px-6 py-4 grid gap-3 md:flex md:justify-between md:items-center border-b border-gray-200 dark:border-neutral-700">
                  <div>
                    <h2 className="text-xl font-semibold text-gray-800 dark:text-neutral-200">
                      Modification du profil utilisateur {_user.firstName + ' ' + _user.lastName}
                    </h2>
                    <p className="text-sm text-gray-600 dark:text-neutral-400">
                      Editer le compte de l'utilisateur.
                    </p>
                  </div>
                  <div>
                    <div className="inline-flex gap-x-2">
                    </div>
                  </div>
                </div>

                <div className="bg-white p-4 sm:p-7 dark:bg-neutral-800">

                  {(validationErrors.length > 0 || error) && (
                    <Alert
                      type="error"
                      title={error}
                      details={validationErrors}
                    />
                  )}
                  {successful && (
                    <Alert
                      type="success"
                      title="L'utilisateur a bien été mis à jour"
                      showLinks={true}
                      linkText='Aller à la page des utilisateurs'
                      toLink='/users'
                    />
                  )}
                  <UserForm
                    userProfiles={userProfiles}
                    statuts={statuts}
                    handleFieldChange={handleFieldChange}
                    handleSubmit={handleOnSubmit}
                    user={_user}
                    canUpdatePassword={canUpdatePassword}
                    disableEmail={true}
                  />
                </div>
                <div className="px-6 py-4 grid gap-3 md:flex md:justify-between md:items-center border-t border-gray-200 dark:border-neutral-700">
                  <div></div>
                  <div>
                    <div className="inline-flex gap-x-2">
                      <button type="button"
                        className="py-2 px-3 inline-flex items-center gap-x-2 text-sm font-semibold rounded-lg border border-transparent bg-red-600 text-white hover:bg-red-700 disabled:opacity-50 disabled:pointer-events-none"
                        onClick={handleOnSubmit}>
                        Enregistrer
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditUserPage;
