import { useEffect, useState } from 'react';
import { Stats, TypeTravauxModel } from '../../data/models';
import EmptyState from '../EmptyState';
import ReactApexChart from 'react-apexcharts';
import { ApexOptions } from 'apexcharts';

type ThisProps = {
    stats: Stats;
    typesTravaux: TypeTravauxModel[] | null;
    chartSeries: number[];
    chartLabels: string[];
    currentTypeTravaux: string;
    handleTypeTravauxChange: (e: React.ChangeEvent<HTMLSelectElement>) => void;
};

const ProjetParPhaseStats = (props: ThisProps) => {

    const chartOptions: ApexOptions = {
        chart: {
            width: '100%',
            type: 'pie',
            animations: {
                enabled: false,
            }
        },
        labels: props.chartLabels,
        legend: {
            show: true,
            showForSingleSeries: true,
            position: 'bottom',
            fontSize: '11px',
        },
        colors: ['#b71c1c', '#d32f2f', '#f44336', '#e57373', '#ffcdd2', '#ffebee', '#f44336',
            '#3e2723', '#6d4c41', '#8d6e63', '#bcaaa4', '#efebe9',
            '#bf360c', '#e64a19', '#ff7043', '#ffab91',
            '#e65100', '#fb8c00', '#ffa726', '#ffcc80',
            '#ff8f00', '#ffc107', '#ffe082',
            '#f57f17', '#fbc02d', '#fdd835', '#ffee58', '#fff59d'],
        dataLabels: {
            enabled: false
        },
        markers: {
            size: 0
        }
    };

    return (
        <div className="grid lg:grid-cols-6 gap-4 sm:gap-6">
            <div className="flex flex-col col-span-3 min-h-[410px]">
                <div className="-m-1.5 overflow-x-auto">
                    <div className="p-1.5 min-w-full inline-block align-middle">
                        <div className="bg-white border border-gray-200 rounded-xl shadow-sm overflow-hidden dark:bg-neutral-800 dark:border-neutral-700">

                            <div className="px-6 py-4 grid gap-3 md:flex md:justify-between md:items-center border-b border-gray-200 dark:border-neutral-700">
                                <div>
                                    <h2 className="text-xl font-semibold text-gray-800 dark:text-neutral-200">
                                        Projets par phase
                                    </h2>
                                    <p className="text-sm text-gray-600 dark:text-neutral-400">
                                        Répartition des projets par type d'intervention.
                                    </p>
                                </div>

                                <div>
                                    <div className="inline-flex gap-x-2">
                                        <select name="typeTravaux" id="type-travaux"
                                            className="py-2 px-3 pe-9 block w-full border-gray-200 shadow-sm text-sm rounded-lg focus:border-red-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none dark:bg-neutral-900 dark:border-neutral-700 dark:text-neutral-400 dark:placeholder-neutral-500 dark:focus:ring-neutral-600"
                                            value={props.currentTypeTravaux}
                                            onChange={props.handleTypeTravauxChange}>
                                            <option value="">Catégorie des travaux...</option>
                                            {props.typesTravaux?.map(({ nom, libelle }) => (
                                                <option value={nom} selected={props.currentTypeTravaux ? props.currentTypeTravaux === nom : false}>{libelle}</option>
                                            ))}
                                        </select>
                                    </div>
                                </div>
                            </div>

                            {!props.stats?.repartition || props.stats.repartition.length === 0
                                ? (
                                    <EmptyState
                                        title="Aucune statistique à afficher"
                                        description="Vérifiez que la base de données a bien été alimentée" />)
                                : (
                                    <table className="min-w-full divide-y divide-gray-200 dark:divide-neutral-700">
                                        <thead className="bg-gray-50 dark:bg-neutral-800">
                                            <tr>
                                                <th scope="col" className="px-6 pe-6 py-3 text-start">
                                                    <div className="flex items-center gap-x-2">
                                                        <span className="text-xs font-semibold uppercase tracking-wide text-gray-800 dark:text-neutral-200">
                                                            Phase
                                                        </span>
                                                    </div>
                                                </th>
                                                <th scope="col" className="px-6 py-3 text-start">
                                                    <div className="flex items-center gap-x-2">
                                                        <span className="text-xs font-semibold uppercase tracking-wide text-gray-800 dark:text-neutral-200">
                                                            Total projets
                                                        </span>
                                                    </div>
                                                </th>
                                                <th scope="col" className="px-6 py-3 text-end"></th>
                                            </tr>
                                        </thead>

                                        <tbody className="divide-y divide-gray-200 dark:divide-neutral-700">
                                            {props.stats.repartition.map((partition, index) => (
                                                <tr>
                                                    <td className="">
                                                        <div className="px-6 py-3">
                                                            <span className="block text-sm font-semibold text-gray-800 dark:text-neutral-200">
                                                                {partition.sujet}
                                                            </span>
                                                        </div>
                                                    </td>
                                                    <td className="">
                                                        <div className="px-6 py-3">
                                                            <span className="text-sm text-gray-500 dark:text-neutral-500">
                                                                {partition.valeur}
                                                            </span>
                                                        </div>
                                                    </td>
                                                    <td className="">
                                                        <div className="px-6 py-3">
                                                            <span className="text-sm font-semibold text-gray-500 dark:text-neutral-500">
                                                                {partition.pourcentage + "%"}
                                                            </span>
                                                        </div>
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>

                                    </table>
                                )}
                        </div>
                    </div>
                </div>
            </div>
            <div className="p-2 col-span-3 bg-white border shadow-sm rounded-xl dark:bg-neutral-800 dark:border-neutral-700">
                {(props.chartSeries && props.chartSeries.length > 0) && (
                    <ReactApexChart
                        options={chartOptions}
                        series={props.chartSeries}
                        type="pie" />
                )}
            </div >
        </div >

    );
};

export default ProjetParPhaseStats;
