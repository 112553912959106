import { useState, useEffect } from 'react';
import { ProjetModel, EtablissementModel, AffectationModel } from '../../data/models';
import FicheProjet from './FicheProjet';
import env from '../../utils/env';
import { useFetch } from '../../hooks/useFetch';
import EmptyState from '../../components/EmptyState';
import { useAuthContext } from '../../contexts/AuthContextProvider';
import { Link } from 'react-router-dom';
import { formatPrice } from '../../utils/helpers';
import { formatDate } from '../../utils/dateHelpers';

const ViewDetailsProjetPage = () => {

  const [affectations, setAffectations] = useState<AffectationModel[]>([]);
  const [error, setError] = useState('');

  const { hasAuthority } = useAuthContext();

  const [projet, setProjet] = useState<ProjetModel>({
    etablissement: {} as EtablissementModel
  } as ProjetModel);

  const {
    data: loadAffectsResp,
    sendRequest: loadAffects,
    error: loadAffectsError
  } = useFetch<AffectationModel[]>(
    `${env.API_URL}/api/affectations/projet/${projet.sid}`,
    { method: 'GET' }
  );

  useEffect(() => {
    if (loadAffectsResp) { setAffectations(loadAffectsResp); }
  }, [loadAffectsResp]);

  useEffect(() => {
    loadAffects();
  }, [projet]);


  return (
    <FicheProjet tab="DETAILS" updateProjet={setProjet}>
      <div className="max-w-[85rem] px-4 py-10 sm:px-6 lg:px-8 lg:py-14 mx-auto">

        <div className="grid sm:grid-cols-3 border-y border-gray-200 dark:border-neutral-800">

          <div className="py-2 md:py-3 px-4 md:px-5 relative before:absolute before:top-0 before:start-0 before:w-full before:h-px sm:before:w-px sm:before:h-full before:bg-gray-200 before:first:bg-transparent dark:before:bg-neutral-800">
            <div>
              <div className="mt-3">
                <h4 className="text-2xl text-gray-800 dark:text-neutral-200">N° Marché</h4>
                <p className="mt-2 text-2xl font-bold text-red-600">{projet?.numero}</p>
                <p className="mt-1 text-sm text-gray-500 dark:text-neutral-500"></p>
              </div>
            </div>
          </div>
          <div className="py-2 md:py-3 px-4 md:px-5 relative before:absolute before:top-0 before:start-0 before:w-full before:h-px sm:before:w-px sm:before:h-full before:bg-gray-200 before:first:bg-transparent dark:before:bg-neutral-800">
            <div>
              <div className="mt-3">
                <h4 className="text-2xl text-gray-800 dark:text-neutral-200">Montant</h4>
                <p className="mt-2 text-2xl font-bold text-red-600">{formatPrice(projet ? projet.montant : 0.0)}</p>
                <p className="mt-1 text-gray-500 dark:text-neutral-500"></p>
              </div>
            </div>
          </div>
          <div className="py-2 md:py-3 px-4 md:px-5 relative before:absolute before:top-0 before:start-0 before:w-full before:h-px sm:before:w-px sm:before:h-full before:bg-gray-200 before:first:bg-transparent dark:before:bg-neutral-800">
            <div>
              <div className="mt-3">
                <h4 className="text-2xl text-gray-800 dark:text-neutral-200">Estimation initiale</h4>
                <p className="mt-2 text-2xl font-bold text-red-600">{formatPrice(projet ? projet.montantEstime : 0.0)}</p>
                <p className="mt-1 text-gray-500 dark:text-neutral-500"></p>
              </div>
            </div>
          </div>
        </div>
        <div className="grid sm:grid-cols-3 border-y border-gray-200 dark:border-neutral-800">
          <div className="py-2 md:py-3 px-4 md:px-5 relative before:absolute before:top-0 before:start-0 before:w-full before:h-px sm:before:w-px sm:before:h-full before:bg-gray-200 before:first:bg-transparent dark:before:bg-neutral-800">
            <div>
              <div className="mt-3">
                <h4 className="text-2xl text-gray-800 dark:text-neutral-200">Délai contractuel</h4>
                <p className="mt-2 text-2xl font-bold text-red-600">{projet?.delaiContractuel ? projet?.delaiContractuel + ' jours' : '? jours'}</p>
                <p className="mt-1 text-sm text-gray-500 dark:text-neutral-500"></p>
              </div>
            </div>
          </div>
          <div className="py-2 md:py-3 px-4 md:px-5 relative before:absolute before:top-0 before:start-0 before:w-full before:h-px sm:before:w-px sm:before:h-full before:bg-gray-200 before:first:bg-transparent dark:before:bg-neutral-800">
            <div>
              <div className="mt-3">
                <h4 className="text-2xl text-gray-800 dark:text-neutral-200">Date début</h4>
                <p className="mt-2 text-2xl font-bold text-red-600">{projet?.debut ? formatDate(projet?.debut, 'DD/MM/YYYY') : '?'}</p>
                <p className="mt-1 text-gray-500 dark:text-neutral-500"></p>
              </div>
            </div>
          </div>
          <div className="py-2 md:py-3 px-4 md:px-5 relative before:absolute before:top-0 before:start-0 before:w-full before:h-px sm:before:w-px sm:before:h-full before:bg-gray-200 before:first:bg-transparent dark:before:bg-neutral-800">
            <div>
              <div className="mt-3">

                <h4 className="text-2xl text-gray-800 dark:text-neutral-200">Date fin estimée</h4>
                <p className="mt-2 text-2xl font-bold text-red-600">{projet?.fin ? formatDate(projet?.fin, 'DD/MM/YYYY') : '?'}</p>
                <p className="mt-1 text-gray-500 dark:text-neutral-500"></p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="max-w-[85rem] px-4 sm:px-6 lg:px-8 mx-auto my-4 sm:my-10">
        <div className="mb-5 pb-5 flex justify-between items-center border-b border-gray-200 dark:border-neutral-700">
          <div>
            <h2 className="text-2xl font-semibold text-gray-800 dark:text-neutral-200">
              Établissement
            </h2>
          </div>

          <div className="inline-flex gap-x-2">
            {hasAuthority('EDIT_ETABLISSEMENT') && (
              <Link to={`/etablissements/${projet.etablissement?.sid}`}
                className="py-2 px-3 inline-flex items-center gap-x-2 text-sm font-semibold rounded-lg border border-transparent bg-red-600 text-white hover:bg-red-700 disabled:opacity-50 disabled:pointer-events-none">
                Mettre à jour
              </Link>
            )}
          </div>
        </div>
        <div className="grid grid-cols-12 gap-3">
          <div className="grid col-span-5">
            <div className="space-y-6">
              <dl className="grid sm:flex gap-x-3 text-sm">
                <dt className="min-w-36 max-w-[200px] text-gray-500 dark:text-neutral-500">
                  N° Gresa:
                </dt>
                <dd className="text-gray-800 dark:text-neutral-200">
                  <span className="inline-flex items-center gap-x-1.5 text-red-600 decoration-2 hover:underline font-medium dark:text-red-500">
                    {projet.etablissement?.greza}
                  </span>
                </dd>
              </dl>

              <dl className="grid sm:flex gap-x-3 text-sm">
                <dt className="min-w-36 max-w-[200px] text-gray-500 dark:text-neutral-500">
                  Nom:
                </dt>
                <dd className="font-medium text-gray-800 dark:text-neutral-200">
                  <span className="block font-semibold">{projet.etablissement?.nom}</span>
                </dd>
              </dl>
              <dl className="grid sm:flex gap-x-3 text-sm">
                <dt className="min-w-36 max-w-[200px] text-gray-500 dark:text-neutral-500">
                  Classe:
                </dt>
                <dd className="font-medium text-gray-800 dark:text-neutral-200">
                  <span className="block font-semibold">{projet.etablissement?.classe}</span>
                </dd>
              </dl>
              <dl className="grid sm:flex gap-x-3 text-sm">
                <dt className="min-w-36 max-w-[200px] text-gray-500 dark:text-neutral-500">
                  Province:
                </dt>
                <dd className="font-medium text-gray-800 dark:text-neutral-200">
                  <span className="block font-semibold">{projet.etablissement?.province?.nom}</span>
                </dd>
              </dl>
              <dl className="grid sm:flex gap-x-3 text-sm">
                <dt className="min-w-36 max-w-[200px] text-gray-500 dark:text-neutral-500">
                  Commune:
                </dt>
                <dd className="font-medium text-gray-800 dark:text-neutral-200">
                  <span className="block font-semibold">{projet.etablissement?.commune?.nom}</span>
                </dd>
              </dl>
            </div>
          </div>

          <div className="grid col-span-7">
            <div className="grid space-y-3">
              {projet.etablissement?.latitude && projet.etablissement?.longitude && (
                <img src={`https://api.journey.tech/v1/static-map?key=pk_e3ad4c4a4c514815ba96744d939eeb29&width=900&height=800&lat=${projet.etablissement.latitude}&lng=${projet.etablissement.longitude}&zoom=10&style=light&markers=lat%3A${projet.etablissement.latitude}%2Clng%3A${projet.etablissement.longitude}`} />
              )}
            </div>
          </div>
        </div>
      </div>

      {/* <!-- Liste intervenants --> */}
      <div className="px-6 py-4 grid gap-3 md:flex md:justify-between md:items-center border-b border-gray-200 dark:border-neutral-700">
        <div>
          <h2 className="text-2xl font-semibold text-gray-800 dark:text-neutral-200">
            Intervenants
          </h2>
        </div>
        <div>
          <div className="inline-flex gap-x-2">
            {hasAuthority('CREATE_AFFECTATION') && (
              <Link to="/affectations/add"
                className="py-2 px-3 inline-flex items-center gap-x-2 text-sm font-semibold rounded-lg border border-transparent bg-red-600 text-white hover:bg-red-700 disabled:opacity-50 disabled:pointer-events-none">
                <svg className="flex-shrink-0 size-4" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><path d="M5 12h14" /><path d="M12 5v14" /></svg>
                Ajouter un intervenant
              </Link>
            )}
          </div>
        </div>
      </div>

      {!affectations || affectations.length == 0
        ? (
          <EmptyState
            title="Aucun intervenant n'a été trouvé"
            description="Vous pouvez commencer par en créer..." />)
        : (
          <table className="min-w-full divide-y divide-gray-200 dark:divide-neutral-700">
            <thead className="bg-gray-50 dark:bg-neutral-800">
              <tr>
                <th scope="col" className="px-6 py-3 text-start">
                  <div className="flex items-center gap-x-2">
                    <span className="text-xs font-semibold uppercase tracking-wide text-gray-800 dark:text-neutral-200">
                      Nom
                    </span>
                  </div>
                </th>

                <th scope="col" className="px-6 py-3 text-start">
                  <div className="flex items-center gap-x-2">
                    <span className="text-xs font-semibold uppercase tracking-wide text-gray-800 dark:text-neutral-200">
                      Poste
                    </span>
                  </div>
                </th>

                <th scope="col" className="px-6 py-3 text-start">
                  <div className="flex items-center gap-x-2">
                    <span className="text-xs font-semibold uppercase tracking-wide text-gray-800 dark:text-neutral-200">
                      Email
                    </span>
                  </div>
                </th>
              </tr>
            </thead>

            <tbody className="divide-y divide-gray-200 dark:divide-neutral-700">
              {affectations.map((affectation) => (
                <tr>
                  <td className="size-px whitespace-nowrap">
                    <div className="px-6 py-3">
                      <span className="block text-sm font-semibold text-gray-800 dark:text-neutral-200">{affectation.user.firstName + ' ' + affectation.user.lastName}</span>
                    </div>
                  </td>
                  <td className="h-px w-36 whitespace-nowrap">
                    <div className="px-6 py-3">
                      <span className="block text-sm font-semibold text-gray-800 dark:text-neutral-200">{affectation.competence.libelle}</span>
                    </div>
                  </td>
                  <td className="size-px whitespace-nowrap">
                    <div className="px-6 py-3">
                      <span className="text-sm text-gray-500 dark:text-neutral-500">{affectation.user.email}</span>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        )}
    </FicheProjet>
  );
};

export default ViewDetailsProjetPage;
