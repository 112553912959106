import React, { useEffect, useState } from 'react';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import EtabTableRow from '../../components/etablissement/EtabTableRow';
import { EtablissementModel } from '../../data/models';
import { useFetch } from '../../hooks/useFetch';
import env from '../../utils/env';
import EmptyState from '../../components/EmptyState';
import { useAuthContext } from '../../contexts/AuthContextProvider';

const EtablissementsPage = () => {
  const [etablissements, setEtablissements] = useState<EtablissementModel[]>([]);
  const [error, setError] = useState<string>('');
  const [page, setPage] = useState<number>();
  const [searchByGreza, setSearchByGreza] = useState<string>('');

  const { hasAuthority } = useAuthContext();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  useEffect(() => {
    if (!page) {
      setPage(1);
    }
  }, []);

  const { data: loadEtabsResp, sendRequest: loadEtabs, error: loadEtabsError } = useFetch<EtablissementModel[]>(
    `${env.API_URL}/api/etablissements/page/${page}?` + (searchByGreza ? `greza=${searchByGreza}` : ''),
    { method: 'GET' }
  );

  useEffect(() => {
    if (page) {
      loadEtabs();
    }
  }, [page]);

  useEffect(() => {
    if (loadEtabsResp) {
      setEtablissements(loadEtabsResp);
    }
    if (loadEtabsError) { setError(loadEtabsError); }
  }, [loadEtabsResp, loadEtabsError]);

  const handleNextClick = (e: React.MouseEvent) => {
    if (etablissements && etablissements.length < 10) {
      e.preventDefault();
    } else if (page) {
      setPage(page + 1);
    }
  }

  const handlePreviousClick = (e: React.MouseEvent) => {
    if (page == 1) {
      e.preventDefault();
    } else if (page) {
      setPage(page - 1);
    }
  }

  const handleSearchClick = (e: React.MouseEvent) => {
    if (page == 1) {
      loadEtabs();
    } else {
      setPage(1);
    }
  }

  return (

    <div className="w-full lg:ps-64">
      <div className="p-4 sm:p-6 space-y-4 sm:space-y-6">

        <div className="flex flex-col">
          <div className="-m-1.5">
            <div className="p-1.5 w-full inline-block align-middle">
              <div className="bg-white border border-gray-200 rounded-xl shadow-sm overflow-hidden dark:bg-neutral-800 dark:border-neutral-700">

                <div className="px-6 py-4 grid gap-3 md:flex md:justify-between md:items-center border-b border-gray-200 dark:border-neutral-700">
                  <div>
                    <h2 className="text-xl font-semibold text-gray-800 dark:text-neutral-200">
                      Établissements
                    </h2>
                    <p className="text-sm text-gray-600 dark:text-neutral-400">
                      Ajouter, éditer un établissement.
                    </p>
                  </div>

                  <div>
                    <div className="inline-flex gap-x-2">
                      {/* <a className="py-2 px-3 inline-flex items-center gap-x-2 text-sm font-medium rounded-lg border border-gray-200 bg-white text-gray-800 shadow-sm hover:bg-gray-50 disabled:opacity-50 disabled:pointer-events-none dark:bg-neutral-800 dark:border-neutral-700 dark:text-white dark:hover:bg-neutral-800" href="#">
                        Exporter
                      </a> */}
                      {hasAuthority('CREATE_ETABLISSEMENT') && (
                        <Link to="/etablissements/add"
                          className="py-2 px-3 inline-flex items-center gap-x-2 text-sm font-semibold rounded-lg border border-transparent bg-red-600 text-white hover:bg-red-700 disabled:opacity-50 disabled:pointer-events-none">
                          <svg className="flex-shrink-0 size-4" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" stroke-linecap="round" stroke-linejoin="round"><path d="M5 12h14" /><path d="M12 5v14" /></svg>
                          Créer un établissement
                        </Link>
                      )}
                    </div>
                  </div>
                </div>

                <div className="p-7 mb-8">
                  <div className="grid grid-cols-6 gap-6">
                    <div className="col-start-2 col-span-3 grid space-y-3">
                      <label htmlFor="by-greza" className="inline-block text-sm font-medium text-gray-800 mt-2.5 dark:text-neutral-200">
                        Filtrer par GRESA
                      </label>
                      <input id="by-greza"
                        className="py-3 px-4 pe-9 block w-full border-gray-200 shadow-sm text-sm rounded-lg focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none dark:bg-neutral-900 dark:border-neutral-700 dark:text-neutral-400 dark:placeholder-neutral-500 dark:focus:ring-neutral-600"
                        type="text"
                        name="by-greza"
                        placeholder="Code gresa..."
                        value={searchByGreza}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => setSearchByGreza(e.target.value)} />
                    </div>
                    <div className="col-end-6 col-span-1 relative grid space-y-3">
                      <button className="w-full py-3 px-4 absolute inset-x-0 bottom-0 inline-flex justify-center items-center gap-x-2 text-sm font-semibold rounded-lg border border-transparent bg-gray-600 text-white hover:bg-gray-700 disabled:opacity-50 disabled:pointer-events-none"
                        onClick={handleSearchClick}>
                        Rechercher
                      </button>
                    </div>
                  </div>
                </div>

                {etablissements.length == 0
                  ? (
                    <EmptyState
                      title="Aucun établissement n'a été trouvé"
                      description="Vous pouvez commencer par en créer" />)
                  : (
                    <>
                      <div className="overflow-x-auto">
                        <table className="w-full divide-y divide-gray-200 dark:divide-neutral-700">
                          <thead className="bg-gray-50 dark:bg-neutral-800">
                            <tr>
                              <th scope="col" className="ps-6 py-3 text-start">
                                <label htmlFor="hs-at-with-checkboxes-main" className="flex">
                                  <input type="checkbox"
                                    className="shrink-0 border-gray-300 rounded text-red-600 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none dark:bg-neutral-800 dark:border-neutral-600 dark:checked:bg-red-500 dark:checked:border-blue-500 dark:focus:ring-offset-gray-800"
                                    id="hs-at-with-checkboxes-main" />
                                  <span className="sr-only">Checkbox</span>
                                </label>
                              </th>

                              <th scope="col" className="px-6 py-3 text-start">
                                <div className="flex items-center gap-x-2">
                                  <span className="text-xs font-semibold uppercase tracking-wide text-gray-800 dark:text-neutral-200">
                                    Nom
                                  </span>
                                </div>
                              </th>

                              <th scope="col" className="px-6 py-3 text-start">
                                <div className="flex items-center gap-x-2">
                                  <span className="text-xs font-semibold uppercase tracking-wide text-gray-800 dark:text-neutral-200">
                                    Province
                                  </span>
                                </div>
                              </th>

                              <th scope="col" className="px-6 py-3 text-start">
                                <div className="flex items-center gap-x-2">
                                  <span className="text-xs font-semibold uppercase tracking-wide text-gray-800 dark:text-neutral-200">
                                    Commune
                                  </span>
                                </div>
                              </th>

                              <th scope="col" className="px-6 py-3 text-start">
                                <div className="flex items-center gap-x-2">
                                  <span className="text-xs font-semibold uppercase tracking-wide text-gray-800 dark:text-neutral-200">
                                    Classe
                                  </span>
                                </div>
                              </th>

                              <th scope="col" className="px-6 py-3 text-start">
                                <div className="flex items-center gap-x-2">
                                  <span className="text-xs font-semibold uppercase tracking-wide text-gray-800 dark:text-neutral-200">
                                    Latitude
                                  </span>
                                </div>
                              </th>
                              <th scope="col" className="px-6 py-3 text-start">
                                <div className="flex items-center gap-x-2">
                                  <span className="text-xs font-semibold uppercase tracking-wide text-gray-800 dark:text-neutral-200">
                                    Longitude
                                  </span>
                                </div>
                              </th>
                            </tr>
                          </thead>

                          <tbody className="divide-y divide-gray-200 dark:divide-neutral-700">
                            {etablissements?.map((etab) => (
                              <EtabTableRow {...etab} />
                            ))}
                          </tbody>

                        </table>
                      </div>
                      <div className="px-6 py-4 grid gap-3 md:flex md:justify-between md:items-center border-t border-gray-200 dark:border-neutral-700">

                        <div></div>
                        <div>
                          <div className="inline-flex gap-x-2">
                            <button type="button" disabled={page == 1}
                              onClick={handlePreviousClick}
                              className="py-1.5 px-2 inline-flex items-center gap-x-2 text-sm font-medium rounded-lg border border-gray-200 bg-white text-gray-800 shadow-sm hover:bg-gray-50 disabled:opacity-50 disabled:pointer-events-none dark:bg-neutral-800 dark:border-neutral-700 dark:text-white dark:hover:bg-neutral-800">
                              <svg className="flex-shrink-0 size-4" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" stroke-linecap="round" stroke-linejoin="round"><path d="m15 18-6-6 6-6" /></svg>
                              Précédent
                            </button>
                            <button type="button" disabled={etablissements.length < 10}
                              onClick={handleNextClick}
                              className="py-1.5 px-2 inline-flex items-center gap-x-2 text-sm font-medium rounded-lg border border-gray-200 bg-white text-gray-800 shadow-sm hover:bg-gray-50 disabled:opacity-50 disabled:pointer-events-none dark:bg-neutral-800 dark:border-neutral-700 dark:text-white dark:hover:bg-neutral-800">
                              Suivant
                              <svg className="flex-shrink-0 size-4" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" stroke-linecap="round" stroke-linejoin="round"><path d="m9 18 6-6-6-6" /></svg>
                            </button>
                          </div>
                        </div>
                      </div>
                    </>
                  )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EtablissementsPage;
