import { formatDate } from '../../../utils/dateHelpers';
import { DecompteProjetModel, FaitMarquantProjetModel } from '../../../data/models';
import { Link } from 'react-router-dom';
import env from '../../../utils/env';
import { formatPrice } from '../../../utils/helpers';
import { useAuthContext } from '../../../contexts/AuthContextProvider';

const FaitMarquantTableRow = (decompte: DecompteProjetModel) => {

    const { hasAuthority } = useAuthContext();
    
    return (
        <tr>
            <td className="">
                <div className="px-6 py-3">
                    <span className="text-sm font-semibold text-gray-800 dark:text-neutral-200">
                        {decompte.numero}
                    </span>
                </div>
            </td>
            <td className="">
                <div className="px-3 py-3">
                    <span className="text-sm text-gray-800 dark:text-neutral-200">
                        {formatPrice(decompte.montant)}
                    </span>
                </div>
            </td>
{/*             <td className="">
                <div className="px-3 py-3">
                    <span className="text-sm text-gray-800 dark:text-neutral-200">
                        {decompte.user?.firstName + ' ' + decompte.user?.lastName}
                    </span>
                </div>
            </td> */}
            <td className="">
                <div className="px-3 py-3">
                    <span className="text-sm text-gray-800 dark:text-neutral-200">
                        {decompte.statut?.libelle}
                    </span>
                </div>
            </td>
            <td className="">
                <div className="px-3 py-3">
                    <span className="text-sm text-gray-800 dark:text-neutral-200">
                        {formatDate(decompte.depot, 'DD/MM/YYYY')}
                    </span>
                </div>
            </td>
            <td className="">
                <div className="px-3 py-3">
                    <span className="text-sm text-gray-800 dark:text-neutral-200">
                        {formatDate(decompte.traitement, 'DD/MM/YYYY')}
                    </span>
                </div>
            </td>
            <td className="">
                <div className="px-3 py-3">
                    <span className="text-sm text-gray-800 dark:text-neutral-200">
                        {formatDate(decompte.rejet, 'DD/MM/YYYY')}
                    </span>
                </div>
            </td>
            <td className="">
                <div className="px-6 py-3 flex items-center gap-x-2">
                    {hasAuthority('EDIT_DECOMPTE_PROJET') && (
                        <Link to={`/projets/${decompte.projet.sid}/decomptes/${decompte.sid}`}
                            className="inline-flex items-center gap-x-1 text-sm text-red-600 decoration-2 hover:underline font-medium dark:text-red-500">
                            Editer
                        </Link>
                    )}
                    {hasAuthority('VIEW_AVANCEMENTS_DECOMPTE_PROJET') && (
                        <Link to={`/projets/${decompte.projet.sid}/decomptes/${decompte.sid}/avancements`}
                            className="inline-flex items-center gap-x-1 text-sm text-red-600 decoration-2 hover:underline font-medium dark:text-red-500">
                            Avancements
                        </Link>
                    )}
                </div>
                
            </td>
        </tr>
    );
};

export default FaitMarquantTableRow;
