import { useState, useEffect } from 'react';
import { ProjetModel, EtablissementModel, DecomptesProjetStats } from '../../data/models';
import FicheProjet from './FicheProjet';
import env from '../../utils/env';
import { useFetch } from '../../hooks/useFetch';
import EmptyState from '../../components/EmptyState';
import { formatDate } from '../../utils/dateHelpers';
import { useAuthContext } from '../../contexts/AuthContextProvider';
import { Link } from 'react-router-dom';
import { formatPrice } from '../../utils/helpers';

const ViewDecomptesProjetPage = () => {

  const [decomptesStats, setDecomptesStats] = useState<DecomptesProjetStats>();
  const [error, setError] = useState('');

  const { hasAuthority } = useAuthContext();

  const [projet, setProjet] = useState<ProjetModel>({
    etablissement: {} as EtablissementModel
  } as ProjetModel);

  const {
    data: loadDecomptesStatsResp,
    sendRequest: loadDecomptesStats,
    error: loadDecomptesStatsError
  } = useFetch<DecomptesProjetStats>(
    `${env.API_URL}/api/projets/${projet.sid}/decomptes/stats`,
    { method: 'GET' }
  );

  useEffect(() => {
    if (loadDecomptesStatsResp) { setDecomptesStats(loadDecomptesStatsResp); }
  }, [loadDecomptesStatsResp]);

  useEffect(() => {
    if (projet.sid) {
      loadDecomptesStats();
    }
  }, [projet]);


  return (
    <FicheProjet tab="DECOMPTES" updateProjet={setProjet}>

      <div className="max-w-[85rem] px-4 py-5 mt-10 sm:px-6 lg:px-8 lg:py-14 mx-auto">
        <div className="relative grid grid-cols-2 gap-3">
          <div>
            <div className="grid space-y-3">
              <dl className="grid sm:flex gap-x-3">
                <dt className="min-w-36 text-gray-500 dark:text-neutral-500">
                  Total des décomptes:
                </dt>
                <dd className="font-medium text-gray-800 dark:text-neutral-200">
                  {formatPrice(decomptesStats?.total ? decomptesStats.total : 0.0)}
                </dd>
              </dl>
            </div>
          </div>

          <div>
            <div className="grid space-y-3">
              <dl className="grid sm:flex gap-x-3">
                <dt className="min-w-36 text-gray-500 dark:text-neutral-500">
                  Pourcentage:
                </dt>
                <dd className="not-italic font-medium text-gray-800 dark:text-neutral-200">
                  {decomptesStats?.pourcentage + "%"}
                </dd>
              </dl>
            </div>
          </div>
        </div>
      </div>
      <div>
        {/* Liste des décomptes */}
        <div className="px-6 py-4 grid gap-3 md:flex md:justify-between md:items-center border-b border-gray-200 dark:border-neutral-700">
          <div>
            <h2 className="text-2xl font-semibold text-gray-800 dark:text-neutral-200">
              Décomptes
            </h2>
          </div>

          <div>
            <div className="inline-flex gap-x-2">
            </div>
          </div>
        </div>

        {!decomptesStats?.decomptes || decomptesStats.decomptes.length === 0
          ? (
            <EmptyState
              title="Aucun décompte n'a été trouvé"
              description="Commencez par en créer..." />)
          : (
            <div className="block mt-6 mb-10 overflow-x-auto">
              <table className="min-w-full divide-y divide-gray-200 dark:divide-neutral-700">
                <thead className="bg-gray-50 dark:bg-neutral-800">
                  <tr>
                    <th scope="col" className="px-6 py-3 text-start">
                      <div className="flex items-center gap-x-2">
                        <span className="text-xs font-semibold uppercase tracking-wide text-gray-800 dark:text-neutral-200">
                          Numéro
                        </span>
                      </div>
                    </th>

                    <th scope="col" className="px-6 py-3 text-start">
                      <div className="flex items-center gap-x-2">
                        <span className="text-xs font-semibold uppercase tracking-wide text-gray-800 dark:text-neutral-200">
                          Montant
                        </span>
                      </div>
                    </th>

                    <th scope="col" className="px-6 py-3 text-start">
                      <div className="flex items-center gap-x-2">
                        <span className="text-xs font-semibold uppercase tracking-wide text-gray-800 dark:text-neutral-200">
                          Statut
                        </span>
                      </div>
                    </th>
                    <th scope="col" className="px-6 py-3 text-start">
                      <div className="flex items-center gap-x-2">
                        <span className="text-xs font-semibold uppercase tracking-wide text-gray-800 dark:text-neutral-200">
                          Dépôt
                        </span>
                      </div>
                    </th>
                    <th scope="col" className="px-6 py-3 text-start">
                      <div className="flex items-center gap-x-2">
                        <span className="text-xs font-semibold uppercase tracking-wide text-gray-800 dark:text-neutral-200">
                          Traitement
                        </span>
                      </div>
                    </th>
                    <th scope="col" className="px-6 py-3 text-start">
                      <div className="flex items-center gap-x-2">
                        <span className="text-xs font-semibold uppercase tracking-wide text-gray-800 dark:text-neutral-200">
                          Rejet
                        </span>
                      </div>
                    </th>
                  </tr>
                </thead>

                <tbody className="divide-y divide-gray-200 dark:divide-neutral-700">
                  {decomptesStats.decomptes.map((decompte) => (
                    <tr>
                      <td className="size-px whitespace-nowrap">
                        <div className="px-6 py-3">
                          <span className="block text-sm font-normal text-gray-800 dark:text-neutral-200">
                            {decompte.numero}
                          </span>
                        </div>
                      </td>
                      <td className="h-px w-36 whitespace-nowrap">
                        <div className="px-6 py-3">
                          <span className="block text-sm font-semibold text-gray-800 dark:text-neutral-200">
                            {formatPrice(decompte.montant ? decompte.montant : 0.0)}
                          </span>
                        </div>
                      </td>
                      <td className="size-px whitespace-nowrap">
                        <div className="px-6 py-3">
                          <span className="text-sm text-gray-500 dark:text-neutral-500">
                            {decompte.statut?.libelle}
                          </span>
                        </div>
                      </td>
                      <td className="size-px whitespace-nowrap">
                        <div className="px-6 py-3">
                          <span className="text-sm text-gray-500 dark:text-neutral-500">
                            {decompte.depot && formatDate(decompte.depot, 'DD/MM/YYYY')}
                          </span>
                        </div>
                      </td>
                      <td className="size-px whitespace-nowrap">
                        <div className="px-6 py-3">
                          <span className="text-sm text-gray-500 dark:text-neutral-500">
                            {decompte.traitement && formatDate(decompte.traitement, 'DD/MM/YYYY')}
                          </span>
                        </div>
                      </td>
                      <td className="size-px whitespace-nowrap">
                        <div className="px-6 py-3">
                          <span className="text-sm text-gray-500 dark:text-neutral-500">
                            {decompte.rejet && formatDate(decompte.rejet, 'DD/MM/YYYY')}
                          </span>
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          )}
      </div>
    </FicheProjet>
  );
};

export default ViewDecomptesProjetPage;
