//import NavbarLayout from '~/components/navbar/NavbarLayout';
import React, { useCallback, useEffect, useState } from 'react';
import { Link, useSearchParams } from 'react-router-dom';
import ProjetTableRow from '../../components/projet/ProjetTableRow';
import { InterventionModel, ProjetModel, ProvinceModel, RegionModel, TypeTravauxModel, UserModel } from '../../data/models';
import { useFetch } from '../../hooks/useFetch';
import env from '../../utils/env';
import EmptyState from '../../components/EmptyState';
import { useAuthContext } from '../../contexts/AuthContextProvider';

const ProjetsPage = () => {
  const [projets, setProjets] = useState<ProjetModel[]>([]);
  const [regions, setRegions] = useState<RegionModel[]>([]);
  const [provinces, setProvinces] = useState<ProvinceModel[]>([]);
  const [error, setError] = useState<string>('');
  const [page, setPage] = useState<number>(1);
  const [firstRendering, setFirstRendering] = useState<boolean>(true);

  const [searchByProvince, setSearchByProvince] = useState<string>('');
  const [searchByRegion, setSearchByRegion] = useState<string>('');
  const [searchByGreza, setSearchByGreza] = useState<string>('');
  const [searchByPhase, setSearchByPhase] = useState<string>('');
  const [searchByUser, setSearchByUser] = useState<string>('');
  const [searchByTravaux, setSearchByTravaux] = useState<string>('');

  const { authState, hasAuthority } = useAuthContext();
  const { username, profiles } = authState;

  const { data: loadProvincesResp, error: loadProvincesError, sendRequest: loadProvinces } = useFetch<ProvinceModel[]>(
    `${env.API_URL}/api/refs/provinces`
  );
  const {
    data: loadProvincesByRegionResp,
    error: loadProvincesByRegionError,
    sendRequest: loadProvincesByRegion } = useFetch<ProvinceModel[]>(
      `${env.API_URL}/api/refs/regions/${searchByRegion}/provinces`,
      { method: "GET" }
    );

  const {
    data: loadRegionsResp,
    error: loadRegionsError,
    sendRequest: loadRegions } = useFetch<RegionModel[]>(
      `${env.API_URL}/api/refs/regions`
    );
  const {
    data: loadRegionsByProvinceResp,
    error: loadRegionsByProvinceError,
    sendRequest: loadRegionsByProvince } = useFetch<RegionModel[]>(
      `${env.API_URL}/api/refs/provinces/${searchByProvince}/regions`,
      { method: "GET" }
    );

  const {
    data: currentUser,
    error: loadCurrentUserError,
    sendRequest: loadCurrentUser } = useFetch<UserModel>(
      `${env.API_URL}/api/users/${authState.username}`,
      { method: "GET" }
    );

  const { data: phases, error: loadPhasesError } = useFetch<InterventionModel[]>(
    `${env.API_URL}/api/interventions`
  );
  const { data: typesTravaux, error: loadTravauxError } = useFetch<TypeTravauxModel[]>(
    `${env.API_URL}/api/refs/travaux`
  );

  const {
    data: loadProjetsResp,
    sendRequest: loadProjets,
    error: loadProjetsError } = useFetch<ProjetModel[]>(
      `${env.API_URL}/api/projets/page/${page}?` + (searchByProvince ? `province=${searchByProvince}&` : '') +
      (searchByRegion ? `region=${searchByRegion}&` : '') + (searchByGreza ? `greza=${searchByGreza}&` : '')
      + (searchByPhase ? `phase=${searchByPhase}&` : '') + (searchByTravaux ? `travaux=${searchByTravaux}&` : '') + (searchByUser ? `userId=${searchByUser}&` : ''),
      { method: 'GET' }
    );

  useEffect(() => {
    if (page && firstRendering===false) {
      loadProjets();
    }
  }, [page, firstRendering]);

  useEffect(() => {
    if (currentUser) {
      setSearchByUser(currentUser.sid);
    }
  }, [currentUser]);

  useEffect(() => {
    if (loadProjetsResp) {
      setProjets(loadProjetsResp);
    }
    if (loadProjetsError) {
      setError(loadProjetsError);
    }
  }, [loadProjetsResp, loadProjetsError]);

  useEffect(() => {
    if (loadRegionsResp) {
      setRegions(loadRegionsResp);
    }
  }, [loadRegionsResp]);
  useEffect(() => {
    if (loadRegionsByProvinceResp) {
      setRegions(loadRegionsByProvinceResp);
    }
  }, [loadRegionsByProvinceResp]);

  useEffect(() => {
    if (loadProvincesResp) {
      setProvinces(loadProvincesResp);
    }
  }, [loadProvincesResp]);

  useEffect(() => {
    if (loadProvincesByRegionResp) {
      setProvinces(loadProvincesByRegionResp);
    }
  }, [loadProvincesByRegionResp]);

  useEffect(() => {
    if (searchByProvince) {
      loadRegionsByProvince();
    } else {
      loadRegions();
    }
  }, [searchByProvince]);

  useEffect(() => {
    if (searchByRegion) {
      loadProvincesByRegion();
    } else {
      loadProvinces();
    }
  }, [searchByRegion]);

  const handleNextClick = (e: React.MouseEvent) => {
    if (projets && projets.length < 10) {
      e.preventDefault();
    } else if (page) {
      setPage(page+1);
    }
  }

  const handlePreviousClick = (e: React.MouseEvent) => {
    if (page == 0) {
      e.preventDefault();
    } else if (page) {
      setPage(page-1);
    }
  }

  const handleSearchClick = (e: React.MouseEvent) => {
    if (page == 1) {
      loadProjets();
    } else {
      setPage(1);
    }
  }

  const handleFieldChange = (e: React.ChangeEvent<HTMLInputElement
    | HTMLTextAreaElement | HTMLSelectElement>) => {
    const { name, value, type } = e.target;

    if (type.startsWith('select') && name.startsWith('by-province')) {
      setRegions([]);
      setSearchByProvince(value);
    }
    if (type.startsWith('select') && name.startsWith('by-region')) {
      setProvinces([]);
      setSearchByRegion(value);
    }
  };

  useEffect(() => {
    if (searchByUser) {
      loadProjets();
      setFirstRendering(false);
    }
  }, [searchByUser]);

  useEffect(() => {
    if (username && profiles && profiles.length > 0) {
      if (hasAuthority('VIEW_MES_PROJETS', 'EDIT_MON_PROJET')) {
        loadCurrentUser();
      } else {
        setFirstRendering(false);
      }
    }
  }, [profiles]);

  return (

    <div className="w-full lg:ps-64">
      <div className="p-4 sm:p-6 space-y-4 sm:space-y-6">

        <div className="flex flex-col">
          <div className="-m-1.5 overflow-x-auto">
            <div className="p-1.5 w-full inline-block align-middle">
              <div className="bg-white border border-gray-200 rounded-xl shadow-sm overflow-hidden dark:bg-neutral-800 dark:border-neutral-700">

                <div className="px-6 py-4 grid gap-3 md:flex md:justify-between md:items-center border-b border-gray-200 dark:border-neutral-700">
                  <div>
                    <h2 className="text-xl font-semibold text-gray-800 dark:text-neutral-200">
                      Projets en cours
                    </h2>
                    <p className="text-sm text-gray-600 dark:text-neutral-400">
                      Ajouter, éditer un projet.
                    </p>
                  </div>

                  <div>
                    <div className="inline-flex gap-x-2">
                      {hasAuthority('CREATE_PROJET') && (
                        <Link to="/projets/add"
                          className="py-2 px-3 inline-flex items-center gap-x-2 text-sm font-semibold rounded-lg border border-transparent bg-red-600 text-white hover:bg-red-700 disabled:opacity-50 disabled:pointer-events-none">
                          <svg className="flex-shrink-0 size-4" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><path d="M5 12h14" /><path d="M12 5v14" /></svg>
                          Créer un projet
                        </Link>
                      )}
                    </div>
                  </div>
                </div>

                <div className="p-7 sm:p-7 mb-8 sm:mb-8">
                  <div className="space-y-4 sm:space-y-6">
                    <div className="grid grid-cols-2 gap-4">
                      <div className="grid space-y-3">
                        <label htmlFor="af-search-by-region" className="inline-block text-sm font-medium text-gray-800 mt-2.5 dark:text-neutral-200">
                          Filtrer par région
                        </label>
                        <select name="by-region" id="af-search-by-region"
                          className="py-2 px-3 pe-9 block w-full border-gray-200 shadow-sm text-sm rounded-lg focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none dark:bg-neutral-900 dark:border-neutral-700 dark:text-neutral-400 dark:placeholder-neutral-500 dark:focus:ring-neutral-600"
                          value={searchByRegion}
                          onChange={handleFieldChange}>
                          <option value=""></option>
                          {regions?.map(({ code, libelle }) => (
                            <option value={code} selected={searchByRegion === code}>
                              {libelle}
                            </option>
                          ))}
                        </select>
                      </div>
                      <div className="grid space-y-3">
                        <label htmlFor="af-search-by-province" className="inline-block text-sm font-medium text-gray-800 mt-2.5 dark:text-neutral-200">
                          Filtrer par province
                        </label>
                        <select name="by-province" id="af-search-by-province"
                          className="py-2 px-3 pe-9 block border-gray-200 shadow-sm text-sm rounded-lg focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none dark:bg-neutral-900 dark:border-neutral-700 dark:text-neutral-400 dark:placeholder-neutral-500 dark:focus:ring-neutral-600"
                          value={searchByProvince}
                          onChange={handleFieldChange}>
                          <option value=""></option>
                          {provinces?.map(({ code, nom }) => (
                            <option value={code} selected={searchByProvince === code}>
                              {nom}
                            </option>
                          ))}
                        </select>
                      </div>
                      <div className="grid space-y-3">
                        <label htmlFor="af-search-by-phase" className="inline-block text-sm font-medium text-gray-800 mt-2.5 dark:text-neutral-200">
                          Filtrer par phase
                        </label>
                        <select name="by-phase" id="af-search-by-phase"
                          className="py-2 px-3 pe-9 block w-full border-gray-200 shadow-sm text-sm rounded-lg focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none dark:bg-neutral-900 dark:border-neutral-700 dark:text-neutral-400 dark:placeholder-neutral-500 dark:focus:ring-neutral-600"
                          value={searchByPhase}
                          onChange={(e: React.ChangeEvent<HTMLSelectElement>) => setSearchByPhase(e.target.value)}>
                          <option value=""></option>
                          {phases?.map(({ sid, nom, typeTravauxLibelle }) => (
                            <option value={sid} selected={searchByPhase === sid}>
                              {typeTravauxLibelle + ' - ' + nom}
                            </option>
                          ))}
                        </select>
                      </div>
                      <div className="grid space-y-3">
                        <label htmlFor="af-search-by-greza" className="inline-block text-sm font-medium text-gray-800 mt-2.5 dark:text-neutral-200">
                          Filtrer par code Gresa
                        </label>
                        <input id="af-search-by-greza"
                          className="py-2 px-3 pe-9 block w-full border-gray-200 shadow-sm text-sm rounded-lg focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none dark:bg-neutral-900 dark:border-neutral-700 dark:text-neutral-400 dark:placeholder-neutral-500 dark:focus:ring-neutral-600"
                          type="text"
                          name="by-greza"
                          placeholder="Code gresa..."
                          value={searchByGreza}
                          onChange={(e: React.ChangeEvent<HTMLInputElement>) => setSearchByGreza(e.target.value)} />
                      </div>
                      <div className="grid space-y-3">
                        <label htmlFor="af-search-by-travaux" className="inline-block text-sm font-medium text-gray-800 mt-2.5 dark:text-neutral-200">
                          Filtrer par type travaux
                        </label>
                        <select name="by-travaux" id="af-search-by-travaux"
                          className="py-2 px-3 pe-9 block w-full border-gray-200 shadow-sm text-sm rounded-lg focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none dark:bg-neutral-900 dark:border-neutral-700 dark:text-neutral-400 dark:placeholder-neutral-500 dark:focus:ring-neutral-600"
                          value={searchByTravaux}
                          onChange={(e: React.ChangeEvent<HTMLSelectElement>) => setSearchByTravaux(e.target.value)}>
                          <option value=""></option>
                          {typesTravaux?.map(({ nom, libelle }) => (
                            <option value={nom} selected={searchByTravaux === nom}>
                              {libelle}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                    <div className="mt-5 pt-5 flex justify-between items-center border-t border-gray-200 dark:border-neutral-700">
                      <div>
                      </div>
                      <div className="inline-flex gap-x-2">
                        <button onClick={handleSearchClick}
                          className="py-2 px-3 inline-flex items-center gap-x-2 text-sm font-semibold rounded-lg border border-transparent bg-gray-600 text-white hover:bg-gray-700 disabled:opacity-50 disabled:pointer-events-none">
                          Rechercher
                        </button>
                      </div>
                    </div>
                  </div>
                </div>

                {projets.length == 0
                  ? (
                    <EmptyState
                      title="Aucun projet n'a été trouvé"
                      description="Vous pouvez commencer par en créer" />)
                  : (
                    <>
                      <div className="block overflow-x-auto">
                        <table className="min-w-full divide-y divide-gray-200 dark:divide-neutral-700">
                          <thead className="bg-gray-50 dark:bg-neutral-800">
                            <tr>
                              <th scope="col" className="px-6 py-3 text-end"></th>

                              <th scope="col" className="px-6 py-3 text-start">
                                <div className="flex items-center gap-x-2">
                                  <span className="text-xs font-semibold uppercase tracking-wide text-gray-800 dark:text-neutral-200">
                                    N° GRESA
                                  </span>
                                </div>
                              </th>

                              <th scope="col" className="px-6 py-3 text-start">
                                <div className="flex items-center gap-x-2">
                                  <span className="text-xs font-semibold uppercase tracking-wide text-gray-800 dark:text-neutral-200">
                                    Établissement
                                  </span>
                                </div>
                              </th>

                              <th scope="col" className="px-6 py-3 text-start">
                                <div className="flex items-center gap-x-2">
                                  <span className="text-xs font-semibold uppercase tracking-wide text-gray-800 dark:text-neutral-200">
                                    Statut
                                  </span>
                                </div>
                              </th>

                              <th scope="col" className="px-6 py-3 text-start">
                                <div className="flex items-center gap-x-2">
                                  <span className="text-xs font-semibold uppercase tracking-wide text-gray-800 dark:text-neutral-200">
                                    Zone
                                  </span>
                                </div>
                              </th>

                              <th scope="col" className="px-6 py-3 text-start">
                                <div className="flex items-center gap-x-2">
                                  <span className="text-xs font-semibold uppercase tracking-wide text-gray-800 dark:text-neutral-200">
                                    Date début
                                  </span>
                                </div>
                              </th>
                              <th scope="col" className="px-6 py-3 text-start">
                                <div className="flex items-center gap-x-2">
                                  <span className="text-xs font-semibold uppercase tracking-wide text-gray-800 dark:text-neutral-200">
                                    Date fin
                                  </span>
                                </div>
                              </th>
                              <th scope="col" className="px-6 py-3 text-start">
                                <div className="flex items-center gap-x-2">
                                  <span className="text-xs font-semibold uppercase tracking-wide text-gray-800 dark:text-neutral-200">
                                    Avancement
                                  </span>
                                </div>
                              </th>

                            </tr>
                          </thead>

                          <tbody className="divide-y divide-gray-200 dark:divide-neutral-700">
                            {projets?.map((p) => (
                              <ProjetTableRow {...p} />
                            ))}
                          </tbody>

                        </table>
                      </div>
                      <div className="px-6 py-4 grid gap-3 md:flex md:justify-between md:items-center border-t border-gray-200 dark:border-neutral-700">
                        <div></div>
                        <div>
                          <div className="inline-flex gap-x-2">
                            <button type="button" disabled={page == 1}
                              onClick={handlePreviousClick}
                              className="py-1.5 px-2 inline-flex items-center gap-x-2 text-sm font-medium rounded-lg border border-gray-200 bg-white text-gray-800 shadow-sm hover:bg-gray-50 disabled:opacity-50 disabled:pointer-events-none dark:bg-neutral-800 dark:border-neutral-700 dark:text-white dark:hover:bg-neutral-800">
                              <svg className="flex-shrink-0 size-4" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><path d="m15 18-6-6 6-6" /></svg>
                              Précédent
                            </button>
                            <button type="button" disabled={projets.length < 10}
                              onClick={handleNextClick}
                              className="py-1.5 px-2 inline-flex items-center gap-x-2 text-sm font-medium rounded-lg border border-gray-200 bg-white text-gray-800 shadow-sm hover:bg-gray-50 disabled:opacity-50 disabled:pointer-events-none dark:bg-neutral-800 dark:border-neutral-700 dark:text-white dark:hover:bg-neutral-800">
                              Suivant
                              <svg className="flex-shrink-0 size-4" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><path d="m9 18 6-6-6-6" /></svg>
                            </button>
                          </div>
                        </div>
                      </div>
                    </>
                  )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProjetsPage;