import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useFetch } from '../../../hooks/useFetch';
import env from '../../../utils/env';
import Alert from "../../../components/Alert";
import { DecompteProjetModel, ProjetModel, StatutDecompteModel } from '../../../data/models';
import DecompteForm from '../../../components/projet/decompte/DecompteForm';

const AddDecomptePage = () => {

  const [decompte, setDecompte] = useState<DecompteProjetModel>({} as DecompteProjetModel);

  const [error, setError] = useState('');
  const [validationErrors, setValidationErrors] = useState<string[]>([]);
  const [successful, setSuccessful] = useState<boolean>(false);

  const { sid: projetId } = useParams() || "";
  if (!projetId || !projetId.trim()) {
    setError("L'identifiant projet fourni est erroné!");
  }

  const { data: projet, error: loadProjetError } = useFetch<ProjetModel>(
    `${env.API_URL}/api/projets/${projetId}`
  );
  const { data: statuts, error: loadStatutsError } = useFetch<StatutDecompteModel[]>(
    `${env.API_URL}/api/refs/decompte/statuts`
  );

  useEffect(() => {
    if (projet) {
      setDecompte(d => ({
        ...d,
        projet: {
          ...d.projet,
          sid: projet.sid
        }
      }))
    }
  }, [projet]);

  useEffect(() => {
    if (loadProjetError) {
      setError(loadProjetError);
    }
  }, [loadProjetError]);

  useEffect(() => {
    if (loadStatutsError) { setError(loadStatutsError); }
  }, [loadStatutsError]);

  const { error: postDecompteError, data: postDecompteResp, sendRequest: postDecompte } = useFetch<DecompteProjetModel>(
    `${env.API_URL}/api/projets/${projetId}/decomptes`,
    {
      method: 'POST',
      payload: decompte
    }
  );

  useEffect(() => {
    if (postDecompteResp) {
      setSuccessful(true);
      setError('');
    }
  }, [postDecompteResp]);

  useEffect(() => {
    if (postDecompteError) {
      setError(postDecompteError);
      setSuccessful(true);
    }
  }, [postDecompteError]);

  const handleOnSubmit = (e: React.MouseEvent) => {
    e.preventDefault();

    setError('');
    setValidationErrors([]);

    let errorMsg = 'Merci de bien vouloir vérifier les champs suivants :\n';
    let errors: string[] = [];

    if (!decompte.numero) {
      errors.push("Le numéro est obligatoire!");
    }
    if (!decompte.montant
      || isNaN(Number(decompte.montant))) {
      errors.push("Le montant est obligatoire et doit être numérique");
    } else {
      let montant: number = Number(decompte.montant);
      if (montant < 0) {
        errors.push("Le montant doit être un nombre positif");
      }
    }
    
    if (errors.length == 0) {
      postDecompte();
    } else {
      setError(errorMsg);
      setValidationErrors(errors);
    }
  };

  const handleFieldChange = (e: React.ChangeEvent<HTMLInputElement
    | HTMLTextAreaElement | HTMLSelectElement>) => {
    const { name, value, type } = e.target;

    if (name.startsWith('statut-decompte-')) {
      let statutName = name.replace(/statut-decompte-/gi, "");
      setDecompte(d => ({
        ...d,
        statut: {
          ...d.statut,
          nom: statutName
        }
      }))
    }
    if (type === 'text' || type === 'textarea') {
      setDecompte(d => ({
        ...d,
        [name]: value || undefined
      }));
    }
  };

  const setDate = (_d_: Date, field: string) => {
    if (field && ['depot', 'rejet', 'traitement'].includes(field.toLowerCase())) {
      setDecompte(d => ({
        ...d,
        [field]: _d_
      }))
    }
  }

  return (
    <div className="w-full lg:ps-64">
      <div className="p-4 sm:p-6 space-y-4 sm:space-y-6">

        <div className="flex flex-col">
          <div className="overflow-x-auto">
            <div className="p-1.5 min-w-full inline-block align-middle">
              <div className="bg-white border border-gray-200 rounded-xl shadow-sm overflow-hidden dark:bg-neutral-800 dark:border-neutral-700">

                <div className="px-6 py-4 grid gap-3 md:flex md:justify-between md:items-center border-b border-gray-200 dark:border-neutral-700">
                  <div>
                    <h2 className="text-xl font-semibold text-gray-800 dark:text-neutral-200">
                      Enregistrement d'un décompte du projet {projet?.greza + ' - ' + projet?.etablissement?.nom}
                    </h2>
                    <p className="text-sm text-gray-600 dark:text-neutral-400">

                    </p>
                  </div>

                  <div>
                    <div className="inline-flex gap-x-2">
                    </div>
                  </div>
                </div>

                <div className="px-14 py-16">
                  {(validationErrors.length > 0 || error) && (
                    <Alert
                      type="error"
                      title={error}
                      details={validationErrors}
                    />
                  )}
                  {successful && (
                    <Alert
                      type="success"
                      title="Le décompte a bien été enregistré"
                      showLinks={true}
                      linkText='Aller à la liste des décomptes'
                      toLink={`/projets/${projetId}/decomptes`}
                    />
                  )}
                  <DecompteForm
                    handleFieldChange={handleFieldChange}
                    handleSubmit={handleOnSubmit}
                    decompte={decompte} setDate={setDate}
                    statuts={statuts} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddDecomptePage;
