import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { InterventionModel, ProjetModel, EtapeProjetModel } from '../../../data/models';
import { useFetch } from '../../../hooks/useFetch';
import env from '../../../utils/env';
import Alert from "../../../components/Alert";
import EtapeProjetForm from "../../../components/projet/etape/EtapeProjetForm";

import "react-datepicker/dist/react-datepicker.css";

const EditEtapeProjetPage = () => {

  const [_etape, setEtape] = useState<EtapeProjetModel>({
    typeIntervention: {} as InterventionModel
  } as EtapeProjetModel);

  const [interventions, setInterventions] = useState<InterventionModel[]>([]);
  const [typeTravaux, setTypeTravaux] = useState('');
  const [error, setError] = useState('');
  const [validationErrors, setValidationErrors] = useState<string[]>([]);
  const [successful, setSuccessful] = useState<boolean>(false);

  const { sid: projetId } = useParams() || "";
  const { eid: etapeId } = useParams() || "";
  if (!etapeId || !etapeId.trim()
    || !etapeId || !etapeId.trim()) {
    setError("L'identifiant étape projet fournie est erroné!");
  }

  const { data: projet, error: loadProjetError } = useFetch<ProjetModel>(
    `${env.API_URL}/api/projets/${projetId}`
  );

  const { data: loadEtapeProjetResp, error: loadEtapeProjetError } = useFetch<EtapeProjetModel>(
    `${env.API_URL}/api/projets/${projetId}/etapes/${etapeId}`
  );

  useEffect(() => {
    if (loadEtapeProjetResp && loadEtapeProjetResp.typeIntervention) {
      setEtape(loadEtapeProjetResp);
      setTypeTravaux(loadEtapeProjetResp.typeIntervention.typeTravaux);
    }
    if (loadEtapeProjetError) {
      setError(loadEtapeProjetError);
    }
  }, [loadEtapeProjetResp]);

  // Mise à jour de l'etape projet
  const { error: putEtapeProjetError, data: putEtapeProjetResp, sendRequest: putEtapeProjet } = useFetch<EtapeProjetModel>(
    `${env.API_URL}/api/projets/${projetId}/etapes/${etapeId}`,
    {
      method: 'PUT',
      payload: _etape
    }
  );

  // Check if establishment has been updated
  useEffect(() => {
    if (putEtapeProjetResp) {
      setSuccessful(true);
      setEtape(putEtapeProjetResp);
    }
    if (putEtapeProjetError) {
      setError(putEtapeProjetError);
    }
  }, [putEtapeProjetResp, putEtapeProjetError]);

  const { data: loadInterventionsResp, error: loadInterventionsError, sendRequest: loadInterventions } = useFetch<InterventionModel[]>(
    `${env.API_URL}/api/interventions/${typeTravaux}`, { method: "GET" }
  );

  useEffect(() => {
    if (typeTravaux) {
      loadInterventions();
    }
  }, [typeTravaux]);

  useEffect(() => {
    if (loadInterventionsResp) {
      setInterventions(loadInterventionsResp);
    }
    if (!loadInterventionsResp && loadInterventionsError) {
      setError(loadInterventionsError);
    }
  }, [loadInterventionsResp]);

  const removeIntervention = () => {
    let etapeProjet = { ..._etape };
    delete etapeProjet.typeIntervention;
    setEtape(etapeProjet);
  }

  const setDebutEtape = (date: Date) => {
    setEtape(e => ({
      ...e,
      debut: date
    }))
  }
  const setFinEtape = (date: Date) => {
    setEtape(e => ({
      ...e,
      fin: date
    }))
  }

  const handleOnSubmit = (e: React.MouseEvent) => {
    e.preventDefault();

    let errorMsg = 'Merci de bien vouloir saisir les champs suivants :\n';
    let errors = [];

    let value = `${_etape.typeIntervention}`.trim();
    if (!_etape.typeIntervention?.sid) {
      errors.push("Le type d'intervention est obligatoire");
    }

    if (!_etape.ordre
      || isNaN(Number(_etape.ordre))) {
      errors.push("L'ordre d'exécution est obligatoire et doit être numérique");
    } else {
      let ordreNum: number = Number(_etape.ordre);
      if (!Number.isInteger(ordreNum) || ordreNum < 0) {
        errors.push("L'ordre d'exécution doit être un nombre positif qui n'est pas une fraction (entier)");
      }
    }

    if (errors.length == 0) {
      putEtapeProjet();
    } else {
      setError(errorMsg);
      setValidationErrors(errors);
    }
  };

  const handleFieldChange = (e: React.ChangeEvent<HTMLInputElement
    | HTMLTextAreaElement | HTMLSelectElement>) => {
    const { name, value, type } = e.target;

    if (type.startsWith('select') && name.startsWith('projet')) {
      setInterventions([]);
      removeIntervention();
    }
    if (type.startsWith('select') && name.startsWith('intervention')) {
      if (!value) {
        removeIntervention();
      } else {
        let field = 'sid';
        setEtape(e => ({
          ...e,
          typeIntervention: {
            ...e.typeIntervention,
            [field]: value
          } as InterventionModel
        }))
      }
    }
    if (type === 'text' || type === 'textarea') {
      setEtape(prevEtape => ({
        ...prevEtape,
        [name]: value || undefined
      }));
    }
  };

  const handleClearForm = (e: React.MouseEvent) => {
    e.preventDefault();
  }

  return (
    <div className="w-full lg:ps-64">
      <div className="p-4 sm:p-6 space-y-4 sm:space-y-6">

        <div className="flex flex-col">
          <div className="-m-1.5 overflow-x-auto">
            <div className="p-1.5 min-w-full inline-block align-middle">
              <div className="bg-white border border-gray-200 rounded-xl shadow-sm overflow-hidden dark:bg-neutral-800 dark:border-neutral-700">

                <div className="px-6 py-4 grid gap-3 md:flex md:justify-between md:items-center border-b border-gray-200 dark:border-neutral-700">
                  <div>
                    <h2 className="text-xl font-semibold text-gray-800 dark:text-neutral-200">
                      Etape {loadEtapeProjetResp?.typeIntervention?.nom + ' du projet ' + projet?.etablissement?.nom}
                    </h2>
                    <p className="text-sm text-gray-600 dark:text-neutral-400">
                      Editer l'étape projet.
                    </p>
                  </div>
                  <div>
                    <div className="inline-flex gap-x-2">
                    </div>
                  </div>
                </div>

                <div className="bg-white p-4 sm:p-7 dark:bg-neutral-800">
                  {(validationErrors.length > 0 || error) && (
                    <Alert
                      type="error"
                      title={error}
                      details={validationErrors}
                    />
                  )}
                  {successful && (
                    <Alert
                      type="success"
                      title="L'étape projet a bien été mise à jour"
                      showLinks={true}
                      linkText='Aller à la page des étapes du projet'
                      toLink={`/projets/${projetId}/etapes`}
                    />
                  )}
                  <EtapeProjetForm
                    interventions={interventions}
                    handleFieldChange={handleFieldChange}
                    handleSubmit={handleOnSubmit}
                    handleClearForm={handleClearForm}
                    setDebutEtape={setDebutEtape}
                    setFinEtape={setFinEtape}
                    etape={_etape}
                  />
                </div>
                <div className="px-6 py-4 grid gap-3 md:flex md:justify-between md:items-center border-t border-gray-200 dark:border-neutral-700">
                  <div></div>
                  <div>
                    <div className="inline-flex gap-x-2">
                      <button type="button"
                        className="py-2 px-3 inline-flex items-center gap-x-2 text-sm font-semibold rounded-lg border border-transparent bg-red-600 text-white hover:bg-red-700 disabled:opacity-50 disabled:pointer-events-none"
                        onClick={handleOnSubmit}>
                        Enregistrer
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditEtapeProjetPage;
