import { Link } from 'react-router-dom';
import { formatDate } from '../../utils/dateHelpers';
import { UserModel } from '../../data/models';
import ActiveStatus from '../../assets/active.svg';
import DangerStatus from '../../assets/danger.svg';
import { useAuthContext } from '../../contexts/AuthContextProvider';

const dateFormat = 'DD/MM/YYYY';

const UserTableRow = (user: UserModel) => {

    const { hasAuthority } = useAuthContext();

    const initials = (user?.firstName ? user.firstName[0].toUpperCase() : '')
        + (user?.lastName ? user.lastName[0].toUpperCase() : '');

    const renderUserAvatar = (): React.JSX.Element => {
        if (user?.image)
            return (
                <img className="inline-block size-[38px] rounded-full"
                    src={user.image}
                    alt={user.name}
                />
            );

        return (
            <span className="inline-flex items-center justify-center size-[38px] rounded-full bg-white border border-gray-300 dark:bg-neutral-800 dark:border-neutral-700">
                <span className="font-medium text-sm text-gray-800 leading-none dark:text-neutral-200">
                    {initials || '?'}
                </span>
            </span>
        );
    };

    const renderUserStatus = (): React.JSX.Element => {
        var statusBadgeClass: string = "";
        var statusBadgeIcon: React.JSX.Element = <></>;
        var statusBadgeText: string = "Inconnu";

        if (user?.statut === "ACTIVE") {
            statusBadgeClass = 'bg-teal-100 text-teal-800 dark:bg-teal-500/10 dark:text-teal-500';
            statusBadgeIcon = <img src={ActiveStatus} />;
            statusBadgeText = 'En activité';
        }
        if (user?.statut === "SUSPENDED") {
            statusBadgeClass = 'bg-yellow-100 text-yellow-800 dark:bg-yellow-500/10 dark:text-yellow-500';
            statusBadgeIcon = <img src={DangerStatus} />;
            statusBadgeText = 'Suspendu';
        }
        if (user?.statut === "excluded") {
            statusBadgeClass = 'bg-red-100 text-red-800 dark:bg-red-500/10 dark:text-red-500';
            statusBadgeIcon = <img src={DangerStatus} />;
            statusBadgeText = 'Exclu';
        }
        return (
            <span className={statusBadgeClass + " py-1 px-1.5 inline-flex items-center gap-x-1 text-xs font-medium rounded-full"}>
                {statusBadgeIcon}
                {statusBadgeText}
            </span>
        );
    };

    return (
        <tr>
            <td className="size-px whitespace-nowrap">
                <div className="ps-6 py-3">
                    <label htmlFor="hs-at-with-checkboxes-1" className="flex">
                        <input type="checkbox"
                            className="shrink-0 border-gray-300 rounded text-red-600 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none dark:bg-neutral-800 dark:border-neutral-600 dark:checked:bg-red-500 dark:checked:border-blue-500 dark:focus:ring-offset-gray-800"
                            id="hs-at-with-checkboxes-1" />
                        <span className="sr-only">Checkbox</span>
                    </label>
                </div>
            </td>
            <td className="size-px whitespace-nowrap">
                <div className="ps-6 lg:ps-3 xl:ps-0 pe-6 py-3">
                    <div className="flex items-center gap-x-3">
                        {renderUserAvatar()}
                        <div className="grow">
                            <span className="block text-sm font-semibold text-gray-800 dark:text-neutral-200">
                                {user.firstName + ' ' + user.lastName}
                            </span>
                            <span className="block text-sm text-gray-500 dark:text-neutral-500">
                                {user.email}
                            </span>
                        </div>
                    </div>
                </div>
            </td>
            
            <td className="size-px whitespace-nowrap">
                <div className="px-6 py-3">
                    {renderUserStatus()}
                </div>
            </td>
            <td className="size-px whitespace-nowrap">
                <div className="px-6 py-3">
                    <span className="text-sm text-gray-500 dark:text-neutral-500">
                        {formatDate(user.creation, dateFormat)}
                    </span>
                </div>
            </td>
            <td className="size-px whitespace-nowrap">
                <div className="px-6 py-3">
                    <span className="text-sm text-gray-500 dark:text-neutral-500">
                        {formatDate(user.lastLogin, dateFormat)}
                    </span>
                </div>
            </td>
            <td className="size-px whitespace-nowrap">
                <div className="px-6 py-1.5">
                    {hasAuthority('EDIT_USER') && (
                        <Link to={`/users/${user.email}`}
                            className="inline-flex items-center gap-x-1 text-sm text-red-600 decoration-2 hover:underline font-medium dark:text-red-500">
                            Éditer
                        </Link>
                    )}
                </div>
            </td>
        </tr>
    );
};

export default UserTableRow;
