
import { useLocation, Link } from "react-router-dom";
import { useAuthContext } from "../../contexts/AuthContextProvider";

const Menu = () => {

    const { hasAuthority } = useAuthContext();

    const location = useLocation();
    const { pathname } = location;
    const routePaths = pathname.split("/");

    return (
        <nav className="hs-accordion-group p-6 w-full flex flex-col flex-wrap" data-hs-accordion-always-open>
            <ul className="space-y-1.5">
                <li>
                    {hasAuthority('VIEW_DASHBOARD') && (
                        <Link to="/"
                            className={(routePaths[1] === "" ? 'bg-gray-100' : '') + " flex items-center gap-x-3.5 py-2 px-2.5 text-sm text-neutral-700 rounded-lg hover:bg-gray-100 dark:bg-neutral-700 dark:text-white"}
                        >
                            <svg className="flex-shrink-0 size-4" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" stroke-linecap="round" stroke-linejoin="round" ><path d="m3 9 9-7 9 7v11a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2z" /><polyline points="9 22 9 12 15 12 15 22" /></svg>
                            Tableau de bord
                        </Link>
                    )}
                </li>

                <li>
                    {hasAuthority('VIEW_ETABLISSEMENTS') && (
                        <Link to="/etablissements"
                            className={(routePaths[1] === "etablissements" ? 'bg-gray-100' : '') + " w-full flex items-center gap-x-3.5 py-2 px-2.5 text-sm text-neutral-700 rounded-lg hover:bg-gray-100 dark:hover:bg-neutral-700 dark:text-neutral-400 dark:hover:text-neutral-300"}
                        >
                            <svg className="flex-shrink-0 size-4" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" stroke-linecap="round" stroke-linejoin="round"><rect width="20" height="14" x="2" y="7" rx="2" ry="2" /><path d="M16 21V5a2 2 0 0 0-2-2h-4a2 2 0 0 0-2 2v16" /></svg>
                            Établissements
                        </Link>
                    )}
                </li>

                <li>
                    {hasAuthority('VIEW_PROJETS', 'VIEW_MES_PROJETS') && (
                        <Link to="/projets"
                            className={(routePaths[1] === "projets" ? 'bg-gray-100' : '') + " w-full flex items-center gap-x-3.5 py-2 px-2.5 text-sm text-neutral-700 rounded-lg hover:bg-gray-100 dark:hover:bg-neutral-700 dark:text-neutral-400 dark:hover:text-neutral-300"}
                        >
                            <svg className="flex-shrink-0 size-4" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" stroke-linecap="round" stroke-linejoin="round"><rect width="20" height="14" x="2" y="7" rx="2" ry="2" /><path d="M16 21V5a2 2 0 0 0-2-2h-4a2 2 0 0 0-2 2v16" /></svg>
                            Projets
                        </Link>
                    )}
                </li>


                <li>
                    {hasAuthority('VIEW_AFFECTATIONS') && (
                        <Link to="/affectations"
                            className={(routePaths[1] === "affectations" ? 'bg-gray-100' : '') + " w-full flex items-center gap-x-3.5 py-2 px-2.5 text-sm text-neutral-700 rounded-lg hover:bg-gray-100 dark:hover:bg-neutral-700 dark:text-neutral-400 dark:hover:text-neutral-300"}
                        >
                            <svg className="flex-shrink-0 size-4" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" stroke-linecap="round" stroke-linejoin="round"><rect width="20" height="14" x="2" y="7" rx="2" ry="2" /><path d="M16 21V5a2 2 0 0 0-2-2h-4a2 2 0 0 0-2 2v16" /></svg>
                            Affectations
                        </Link>
                    )}
                </li>

                <li>
                    {hasAuthority('VIEW_USERS') && (
                        <Link to="/users"
                            className={(routePaths[1] === "users" ? 'bg-gray-100' : '') + " w-full flex items-center gap-x-3.5 py-2 px-2.5 text-sm text-neutral-700 rounded-lg hover:bg-gray-100 dark:hover:bg-neutral-700 dark:text-neutral-400 dark:hover:text-neutral-300"}
                        >
                            <svg className="flex-shrink-0 size-4" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" stroke-linecap="round" stroke-linejoin="round"><rect width="20" height="14" x="2" y="7" rx="2" ry="2" /><path d="M16 21V5a2 2 0 0 0-2-2h-4a2 2 0 0 0-2 2v16" /></svg>
                            Utilisateurs
                        </Link>
                    )}
                </li>

            </ul>
        </nav>
    );
};

export default Menu;
