import { Link } from 'react-router-dom';
import { FaitMarquantProjetModel } from '../../../data/models';
import DatePicker from "react-datepicker";
import { yesterday } from '../../../utils/dateHelpers';

type FaitMarquantFormProps = {
    handleFieldChange: (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => void;
    handleFileChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
    handleSubmit: (e: React.MouseEvent) => void;
    handleClearForm?: (e: React.MouseEvent) => void | null;
    setDate: (d: Date) => void;
    fait: FaitMarquantProjetModel;
    file?: File | null;
};

const FaitMarquantForm = (props: FaitMarquantFormProps) => {

    const __ = props.fait;

    return (
        <form>
            <div className="grid grid-cols-12 gap-2 gap-6">
                <div className="sm:col-span-3">
                    <label htmlFor="af-date" className="inline-block text-sm text-gray-800 mt-2.5 dark:text-neutral-200">
                        Date
                    </label>
                </div>
                <div className="sm:col-span-9">
                    <DatePicker id="af-date"
                        className="py-2 px-3 pe-11 block w-full border-gray-200 shadow-sm text-sm rounded-lg focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none dark:bg-neutral-900 dark:border-neutral-700 dark:text-neutral-400 dark:placeholder-neutral-500 dark:focus:ring-neutral-600"
                        selected={__.date}
                        onChange={(date: Date) => props.setDate(date)}
                        dateFormat="dd/MM/yyyy" minDate={yesterday()}
                        />
                </div>

                <div className="sm:col-span-3">
                    <label htmlFor="af-piece-jointe" className="inline-block text-sm text-gray-800 mt-2.5 dark:text-neutral-200">
                        Pièce jointe
                    </label>
                </div>
                <div className="sm:col-span-9">
                    {(__.pieceJointe || props.file) && (
                        <div className="mb-2 flex items-center gap-x-2 p-3 text-sm bg-white border text-gray-800 first:rounded-t-lg first:mt-0 last:rounded-b-lg dark:bg-neutral-900 dark:border-neutral-700 dark:text-neutral-200">
                            <div className="w-full flex justify-between truncate">
                                <span className="me-3 flex-1 w-0 truncate">
                                    {__.pieceJointe ? __.pieceJointe.document?.originalFilename : (props.file ? props.file.name : '')}
                                </span>
                                {__.pieceJointe && (
                                    <Link to={__.pieceJointe.downloadUrl}>
                                        <button type="button" className="flex items-center gap-x-2 text-gray-500 hover:text-blue-600 whitespace-nowrap dark:text-neutral-500 dark:hover:text-blue-500">
                                            <svg className="flex-shrink-0 size-4" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" stroke-linecap="round" stroke-linejoin="round"><path d="M21 15v4a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-4" /><polyline points="7 10 12 15 17 10" /><line x1="12" x2="12" y1="15" y2="3" /></svg>
                                            Consulter
                                        </button>
                                    </Link>
                                )}
                            </div>
                        </div>
                    )}
                    <label htmlFor="af-piece-jointe" className="group p-6 sm:p-6 block cursor-pointer text-center border-2 border-dashed border-gray-200 rounded-lg focus-within:outline-none focus-within:ring-2 focus-within:ring-blue-500 focus-within:ring-offset-2 dark:border-neutral-700">
                        <input id="af-piece-jointe" className="sr-only"
                            type="file"
                            onChange={props.handleFileChange} />
                        <svg className="size-10 mx-auto text-gray-400 dark:text-neutral-600" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" viewBox="0 0 16 16">
                            <path fill-rule="evenodd" d="M7.646 5.146a.5.5 0 0 1 .708 0l2 2a.5.5 0 0 1-.708.708L8.5 6.707V10.5a.5.5 0 0 1-1 0V6.707L6.354 7.854a.5.5 0 1 1-.708-.708l2-2z" />
                            <path d="M4.406 3.342A5.53 5.53 0 0 1 8 2c2.69 0 4.923 2 5.166 4.579C14.758 6.804 16 8.137 16 9.773 16 11.569 14.502 13 12.687 13H3.781C1.708 13 0 11.366 0 9.318c0-1.763 1.266-3.223 2.942-3.593.143-.863.698-1.723 1.464-2.383zm.653.757c-.757.653-1.153 1.44-1.153 2.056v.448l-.445.049C2.064 6.805 1 7.952 1 9.318 1 10.785 2.23 12 3.781 12h8.906C13.98 12 15 10.988 15 9.773c0-1.216-1.02-2.228-2.313-2.228h-.5v-.5C12.188 4.825 10.328 3 8 3a4.53 4.53 0 0 0-2.941 1.1z" />
                        </svg>
                        <span className="mt-2 block text-sm text-gray-800 dark:text-neutral-200">
                            Cliquer ici pour charger la pièce jointe...
                        </span>
                        <span className="mt-1 block text-xs text-gray-500 dark:text-neutral-500">
                            La taille maximum est de 2 MB
                        </span>
                    </label>
                </div>

                <div className="sm:col-span-3">
                    <label htmlFor="af-localisation" className="inline-block text-sm text-gray-800 mt-2.5 dark:text-neutral-200">
                        Localisation géographique
                    </label>
                </div>
                <div className="sm:col-span-9">
                    <div className="sm:flex">
                        <input id="af-localisation"
                            className="py-2 px-3 pe-11 block w-full border-gray-200 shadow-sm -mt-px -ms-px first:rounded-t-lg last:rounded-b-lg sm:first:rounded-s-lg sm:mt-0 sm:first:ms-0 sm:first:rounded-se-none sm:last:rounded-es-none sm:last:rounded-e-lg text-sm relative focus:z-10 focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none dark:bg-neutral-800 dark:border-neutral-700 dark:text-neutral-400 dark:placeholder-neutral-500 dark:focus:ring-neutral-600"
                            type="text"
                            name="latitude"
                            placeholder="Latitude..."
                            value={__.latitude}
                            onChange={props.handleFieldChange}
                        />
                        <input
                            className="py-2 px-3 pe-11 block w-full border-gray-200 shadow-sm -mt-px -ms-px first:rounded-t-lg last:rounded-b-lg sm:first:rounded-s-lg sm:mt-0 sm:first:ms-0 sm:first:rounded-se-none sm:last:rounded-es-none sm:last:rounded-e-lg text-sm relative focus:z-10 focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none dark:bg-neutral-800 dark:border-neutral-700 dark:text-neutral-400 dark:placeholder-neutral-500 dark:focus:ring-neutral-600"
                            type="text"
                            name="longitude"
                            placeholder="Longitude..."
                            value={__.longitude}
                            onChange={props.handleFieldChange}
                        />
                    </div>
                </div>
                
                <div className="sm:col-span-3">
                    <label htmlFor="af-desc" className="inline-block text-sm text-gray-800 mt-2.5 dark:text-neutral-200">
                        Description
                    </label>
                </div>
                <div className="sm:col-span-9">
                    <textarea id="af-desc" name="description"
                        className="py-2 px-3 block w-full border-gray-200 rounded-lg text-sm focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none dark:bg-neutral-800 dark:border-neutral-700 dark:text-neutral-400 dark:placeholder-neutral-500 dark:focus:ring-neutral-600"
                        rows={10} placeholder="Décrire le fait..."
                        value={__.description}
                        onChange={props.handleFieldChange} />
                </div>
            </div>
            <div className="mt-8 px-6 py-4 grid-cols-2 gap-3 flex justify-between items-center border-t border-gray-200 dark:border-neutral-700">
                <div></div>

                <div className="inline-flex gap-x-2 -mr-6 ">
                    {props.handleClearForm && (
                        <button type="button"
                            onClick={props.handleClearForm}
                            className="py-2 px-3 inline-flex items-center gap-x-2 text-sm font-medium rounded-lg border border-gray-200 bg-white text-gray-800 shadow-sm hover:bg-gray-50 disabled:opacity-50 disabled:pointer-events-none dark:bg-neutral-800 dark:border-neutral-700 dark:text-white dark:hover:bg-neutral-800">
                            Effacer
                        </button>
                    )}
                    <button type="button"
                        className="py-2 px-3 inline-flex items-center gap-x-2 text-sm font-semibold rounded-lg border border-transparent bg-red-600 text-white hover:bg-red-700 disabled:opacity-50 disabled:pointer-events-none"
                        onClick={props.handleSubmit}>
                        Enregistrer
                    </button>
                </div>

            </div>
        </form>
    );
};

export default FaitMarquantForm;
