import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useFetch } from '../../../../hooks/useFetch';
import env from '../../../../utils/env';
import Alert from "../../../../components/Alert";
import { AvancementEtapeProjetModel } from '../../../../data/models';
import AvancementEtapeForm from '../../../../components/projet/etape/avancement/AvancementEtapeForm';

const EditAvancementEtapePage = () => {

  const [avancement, setAvancement] = useState<AvancementEtapeProjetModel>({} as AvancementEtapeProjetModel);
  const [file, setFile] = useState<File|null>(null);
  
  const [error, setError] = useState('');
  const [validationErrors, setValidationErrors] = useState<string[]>([]);
  const [successful, setSuccessful] = useState<boolean>(false);

  const { sid: projetId } = useParams() || "";
  if (!projetId || !projetId.trim()) {
    setError("L'identifiant projet fourni est erroné!");
  }

  const { eid: etapeId } = useParams() || "";
  if (!etapeId || !etapeId.trim()) {
    setError("L'identifiant étape fourni est erroné!");
  }
  const { aid: avancementId } = useParams() || "";
  if (!etapeId || !etapeId.trim()) {
    setError("L'identifiant avancement fourni est erroné!");
  }

  const { data: loadAvancementResp, error: loadAvancementError } = useFetch<AvancementEtapeProjetModel>(
    `${env.API_URL}/api/projets/${projetId}/etapes/${etapeId}/avancements/${avancementId}`
  );

  useEffect(() => {
    if (loadAvancementResp) {
      setAvancement(loadAvancementResp);
    }
    if (loadAvancementError) {
      setError(loadAvancementError);
    }
  }, [loadAvancementResp]);

  const {
    error: updateAvancementError,
    data: updateAvancementResp,
    sendRequest: updateAvancement
  } = useFetch<AvancementEtapeProjetModel>(
    `${env.API_URL}/api/projets/${projetId}/etapes/${etapeId}/avancements/${avancementId}`,
    {
      method: 'PUT',
      payload: avancement
    }
  );

  useEffect(() => {
    if (updateAvancementResp) {
      setSuccessful(true);
      setError('');
    }
  }, [updateAvancementResp]);

  useEffect(() => {
    if (updateAvancementError) {
      setError(updateAvancementError);
      setSuccessful(false);
    }
  }, [updateAvancementError]);

  const handleOnSubmit = (e: React.MouseEvent) => {
    e.preventDefault();

    let errorMsg = 'Merci de bien vouloir saisir les champs suivants :\n';
    let errors: string[] = [];

    if (isNaN(Number(avancement.pourcentage)) 
      || avancement.pourcentage?.length  < 1 || avancement.pourcentage?.length  > 2 ) {
      errors.push("Le taux d'avancement doit être numérique");
    }

    if (errors.length == 0) {
      updateAvancement();
    } else {
      setError(errorMsg);
      setValidationErrors(errors);
    }
  };

  const handleFieldChange = (e: React.ChangeEvent<HTMLInputElement
    | HTMLTextAreaElement | HTMLSelectElement>) => {
    const { name, value, type } = e.target;

    if (type === 'checkbox' && name.startsWith('blocage')) {
      var isBlocage = avancement.blocage;
      if ('checked' in e.target && !e.target.checked) {
        isBlocage = false;
      }
      if ('checked' in e.target && e.target.checked) {
        isBlocage = true;
      }
      setAvancement(a => ({
        ...a,
        blocage: isBlocage
      }));
    }
    if (type === 'text' || type === 'textarea') {
      setAvancement(a => ({
        ...a,
        [name]: value || undefined
      }));
    }
  };

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      setFile(e.target.files[0]);
    }
  };

  return (
    <div className="w-full lg:ps-64">
      <div className="p-4 sm:p-6 space-y-4 sm:space-y-6">

        <div className="flex flex-col">
          <div className="overflow-x-auto">
            <div className="p-1.5 min-w-full inline-block align-middle">
              <div className="bg-white border border-gray-200 rounded-xl shadow-sm overflow-hidden dark:bg-neutral-800 dark:border-neutral-700">

                <div className="px-6 py-4 grid gap-3 md:flex md:justify-between md:items-center border-b border-gray-200 dark:border-neutral-700">
                  <div>
                    <h2 className="text-xl font-semibold text-gray-800 dark:text-neutral-200">
                      Modification de l'avancement de la phase projet {avancement.etape?.typeIntervention?.nom}
                    </h2>
                    <p className="text-sm text-gray-600 dark:text-neutral-400">
                      Alimenter le formulaire de création de l'avancement.
                    </p>
                  </div>
                  <div>
                    <div className="inline-flex gap-x-2">
                      {/* <a className="py-2 px-3 inline-flex items-center gap-x-2 text-sm font-medium rounded-lg border border-gray-200 bg-white text-gray-800 shadow-sm hover:bg-gray-50 disabled:opacity-50 disabled:pointer-events-none dark:bg-neutral-800 dark:border-neutral-700 dark:text-white dark:hover:bg-neutral-800" href="#">
                      Exporter
                    </a> */}

                      {/* <Link to={`/projets/${projetId}/etapes/${etapeId}/avancements/add`}
                      className="py-2 px-3 inline-flex items-center gap-x-2 text-sm font-semibold rounded-lg border border-transparent bg-red-600 text-white hover:bg-red-700 disabled:opacity-50 disabled:pointer-events-none">
                      <svg className="flex-shrink-0 size-4" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><path d="M5 12h14" /><path d="M12 5v14" /></svg>
                      Créer un avancement
                    </Link> */}
                    </div>
                  </div>
                </div>

                <div className="px-14 py-16">
                  {(validationErrors.length > 0 || error) && (
                    <Alert
                      type="error"
                      title={error}
                      details={validationErrors}
                    />
                  )}
                  {successful && (
                    <Alert
                      type="success"
                      title="L'avancement de l'étape projet a bien été mis à jour"
                      showLinks={true}
                      linkText='Aller à la liste des avancements'
                      toLink={`/projets/${projetId}/etapes/${etapeId}/avancements`}
                    />
                  )}
                  <AvancementEtapeForm
                    handleFieldChange={handleFieldChange}
                    handleFileChange={handleFileChange}
                    handleSubmit={handleOnSubmit}
                    avancement={avancement} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditAvancementEtapePage;
