//import NavbarLayout from '~/components/navbar/NavbarLayout';
import React, { useCallback, useEffect, useState } from 'react';
import { Link, useSearchParams } from 'react-router-dom';
import UserTableRow from '../../components/user/UserTableRow';
import { UserModel } from '../../data/models';
import { useFetch } from '../../hooks/useFetch';
import env from '../../utils/env';
import ErrorPage from '../ErrorPage';
import { useAuthContext } from '../../contexts/AuthContextProvider';

const UsersPage = () => {
  const [users, setUsers] = useState<UserModel[]>([]);
  const [error, setError] = useState<string>('');

  const { hasAuthority } = useAuthContext();

  const [searchParams] = useSearchParams();

  var page = parseInt(searchParams.get('page') as string) || 1;

  const { data: loadUsersResp, error: loadUsersError } = useFetch<UserModel[]>(
    `${env.API_URL}/api/users/page/${page}`
  );

  useEffect(() => {
    if (loadUsersResp) {
      setUsers(loadUsersResp);
    } else if (loadUsersError) {
      setError(loadUsersError);
    }
  }, [loadUsersResp]);

  if (error) {
    return <ErrorPage message={error} />;
  }

  const handleNextClick = (e: React.MouseEvent) => {
    if (users.length < 10) {
      e.preventDefault();
    }
  }

  const handlePreviousClick = (e: React.MouseEvent) => {
    if (page == 0) {
      e.preventDefault();
    }
  }

  return (

    <div className="w-full lg:ps-64">
      <div className="p-4 sm:p-6 space-y-4 sm:space-y-6">

        <div className="flex flex-col">
          <div className="-m-1.5 overflow-x-auto">
            <div className="p-1.5 min-w-full inline-block align-middle">
              <div className="bg-white border border-gray-200 rounded-xl shadow-sm overflow-hidden dark:bg-neutral-800 dark:border-neutral-700">

                <div className="px-6 py-4 grid gap-3 md:flex md:justify-between md:items-center border-b border-gray-200 dark:border-neutral-700">
                  <div>
                    <h2 className="text-xl font-semibold text-gray-800 dark:text-neutral-200">
                      Utilisateurs ayant accès au système
                    </h2>
                    <p className="text-sm text-gray-600 dark:text-neutral-400">
                      Ajouter, éditer un utilisateur.
                    </p>
                  </div>

                  <div>
                    <div className="inline-flex gap-x-2">
                      {hasAuthority('CREATE_USER') && (
                        <Link to="/users/add"
                          className="py-2 px-3 inline-flex items-center gap-x-2 text-sm font-semibold rounded-lg border border-transparent bg-red-600 text-white hover:bg-red-700 disabled:opacity-50 disabled:pointer-events-none">
                          <svg className="flex-shrink-0 size-4" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><path d="M5 12h14" /><path d="M12 5v14" /></svg>
                          Ajouter un utilisateur
                        </Link>
                      )}
                    </div>
                  </div>
                </div>

                <table className="min-w-full divide-y divide-gray-200 dark:divide-neutral-700">
                  <thead className="bg-gray-50 dark:bg-neutral-800">
                    <tr>
                      <th scope="col" className="ps-6 py-3 text-start">
                        <label htmlFor="hs-at-with-checkboxes-main" className="flex">
                          <input
                            type="checkbox"
                            className="shrink-0 border-gray-300 rounded text-red-600 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none dark:bg-neutral-800 dark:border-neutral-600 dark:checked:bg-red-500 dark:checked:border-blue-500 dark:focus:ring-offset-gray-800"
                            id="hs-at-with-checkboxes-main" />
                          <span className="sr-only">Checkbox</span>
                        </label>
                      </th>

                      <th scope="col" className="ps-6 lg:ps-3 xl:ps-0 pe-6 py-3 text-start">
                        <div className="flex items-center gap-x-2">
                          <span className="text-xs font-semibold uppercase tracking-wide text-gray-800 dark:text-neutral-200">
                            Identité
                          </span>
                        </div>
                      </th>

                      <th scope="col" className="px-6 py-3 text-start">
                        <div className="flex items-center gap-x-2">
                          <span className="text-xs font-semibold uppercase tracking-wide text-gray-800 dark:text-neutral-200">
                            Statut
                          </span>
                        </div>
                      </th>

                      <th scope="col" className="px-6 py-3 text-start">
                        <div className="flex items-center gap-x-2">
                          <span className="text-xs font-semibold uppercase tracking-wide text-gray-800 dark:text-neutral-200">
                            Création
                          </span>
                        </div>
                      </th>

                      <th scope="col" className="px-6 py-3 text-start">
                        <div className="flex items-center gap-x-2">
                          <span className="text-xs font-semibold uppercase tracking-wide text-gray-800 dark:text-neutral-200">
                            Dernière connexion
                          </span>
                        </div>
                      </th>

                      <th scope="col" className="px-6 py-3 text-end"></th>
                    </tr>
                  </thead>

                  <tbody className="divide-y divide-gray-200 dark:divide-neutral-700">
                    {users?.map((u) => (
                      <UserTableRow {...u} />
                    ))}
                  </tbody>

                </table>
                <div className="px-6 py-4 grid gap-3 md:flex md:justify-between md:items-center border-t border-gray-200 dark:border-neutral-700">
                  {/* <div>
                    <p className="text-sm text-gray-600 dark:text-neutral-400">
                      <span className="font-semibold text-gray-800 dark:text-neutral-200">12</span> projets trouvés
                    </p>
                  </div> */}
                  <div></div>
                  <div>
                    <div className="inline-flex gap-x-2">
                      <Link to={`/users?page=${page - 1}`} onClick={handlePreviousClick}>
                        <button type="button" disabled={page == 1}
                          className="py-1.5 px-2 inline-flex items-center gap-x-2 text-sm font-medium rounded-lg border border-gray-200 bg-white text-gray-800 shadow-sm hover:bg-gray-50 disabled:opacity-50 disabled:pointer-events-none dark:bg-neutral-800 dark:border-neutral-700 dark:text-white dark:hover:bg-neutral-800">
                          <svg className="flex-shrink-0 size-4" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><path d="m15 18-6-6 6-6" /></svg>
                          Précédent
                        </button>
                      </Link>
                      <Link to={`/users?page=${page + 1}`} onClick={handleNextClick}>
                        <button type="button" disabled={users.length < 10}
                          className="py-1.5 px-2 inline-flex items-center gap-x-2 text-sm font-medium rounded-lg border border-gray-200 bg-white text-gray-800 shadow-sm hover:bg-gray-50 disabled:opacity-50 disabled:pointer-events-none dark:bg-neutral-800 dark:border-neutral-700 dark:text-white dark:hover:bg-neutral-800">
                          Suivant
                          <svg className="flex-shrink-0 size-4" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><path d="m9 18 6-6-6-6" /></svg>
                        </button>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UsersPage;