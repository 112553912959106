//import NavbarLayout from '~/components/navbar/NavbarLayout';
import { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import EtapeProjetTableRow from '../../../components/projet/etape/EtapeProjetTableRow';
import { ProjetModel } from '../../../data/models';
import { useFetch } from '../../../hooks/useFetch';
import env from '../../../utils/env';
import EmptyState from '../../../components/EmptyState';
import { EtapeProjetModel } from '../../../data/models';
import { useAuthContext } from '../../../contexts/AuthContextProvider';

const EtapesProjetPage = () => {
  const [error, setError] = useState<string>('');
  const { hasAuthority } = useAuthContext();
  const { sid: projetId } = useParams() || "";
  if (!projetId || !projetId.trim()) {
    setError("L'identifiant projet fourni est erroné!");
  }

  const { data: projet, error: loadProjetError } = useFetch<ProjetModel>(
    `${env.API_URL}/api/projets/${projetId}`
  );

  const { data: etapes, error: loadEtapesError } = useFetch<EtapeProjetModel[]>(
    `${env.API_URL}/api/projets/${projetId}/etapes`
  );

  useEffect(() => {
    /*     if (loadEtapesResp) {
          setProjets(loadProjetsResp);
        } */
    if (loadEtapesError) {
      setError(loadEtapesError);
    }
  }, [loadEtapesError]);

  return (

    <div className="w-full lg:ps-64">
      <div className="p-4 sm:p-6 space-y-4 sm:space-y-6">

        <div className="flex flex-col">
          <div className="-m-1.5 overflow-x-auto">
            <div className="p-1.5 min-w-full inline-block align-middle">
              <div className="bg-white border border-gray-200 rounded-xl shadow-sm overflow-hidden dark:bg-neutral-800 dark:border-neutral-700">

                <div className="px-6 py-4 grid gap-3 md:flex md:justify-between md:items-center border-b border-gray-200 dark:border-neutral-700">
                  <div>
                    <h2 className="text-xl font-semibold text-gray-800 dark:text-neutral-200">
                      Etapes du projet {projet?.greza + ' - ' + projet?.etablissement?.nom}
                    </h2>
                    <p className="text-sm text-gray-600 dark:text-neutral-400">
                      Ajouter, éditer une étape du projet.
                    </p>
                  </div>

                  <div>
                    <div className="inline-flex gap-x-2">
                      {hasAuthority('CREATE_ETAPE_PROJET') && (
                        <Link to={`/projets/${projetId}/etapes/add`}
                          className="py-2 px-3 inline-flex items-center gap-x-2 text-sm font-semibold rounded-lg border border-transparent bg-red-600 text-white hover:bg-red-700 disabled:opacity-50 disabled:pointer-events-none">
                          <svg className="flex-shrink-0 size-4" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><path d="M5 12h14" /><path d="M12 5v14" /></svg>
                          Créer une étape
                        </Link>
                      )}
                    </div>
                  </div>
                </div>

                {!etapes || etapes.length == 0
                  ? (
                    <EmptyState
                      title="Aucune étape n'a été trouvée"
                      description="Vous pouvez commencer par en créer" />)
                  : (
                    <table className="min-w-full divide-y divide-gray-200 dark:divide-neutral-700">
                      <thead className="bg-gray-50 dark:bg-neutral-800">
                        <tr>
                          <th scope="col" className="px-6 py-3 text-start">
                            <div className="flex items-center gap-x-2">
                              <span className="text-xs font-semibold uppercase tracking-wide text-gray-800 dark:text-neutral-200">
                                Ordre
                              </span>
                            </div>
                          </th>

                          <th scope="col" className="px-3 py-3 text-start">
                            <div className="flex items-center gap-x-2">
                              <span className="text-xs font-semibold uppercase tracking-wide text-gray-800 dark:text-neutral-200">
                                Description
                              </span>
                            </div>
                          </th>

                          <th scope="col" className="px-3 py-3 text-start">
                            <div className="flex items-center gap-x-2">
                              <span className="text-xs font-semibold uppercase tracking-wide text-gray-800 dark:text-neutral-200">
                                Type intervention
                              </span>
                            </div>
                          </th>

                          <th scope="col" className="px-3 py-3 text-start">
                            <div className="flex items-center gap-x-2">
                              <span className="text-xs font-semibold uppercase tracking-wide text-gray-800 dark:text-neutral-200">
                                Date début
                              </span>
                            </div>
                          </th>
                          <th scope="col" className="px-3 py-3 text-start">
                            <div className="flex items-center gap-x-2">
                              <span className="text-xs font-semibold uppercase tracking-wide text-gray-800 dark:text-neutral-200">
                                Date fin
                              </span>
                            </div>
                          </th>

                          <th scope="col" className="px-6 py-3 text-end"></th>
                        </tr>
                      </thead>

                      <tbody className="divide-y divide-gray-200 dark:divide-neutral-700">
                        {etapes?.map((etape) => (
                          <EtapeProjetTableRow {...etape} />
                        ))}
                      </tbody>

                    </table>

                  )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EtapesProjetPage;