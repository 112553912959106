import React, { Dispatch, SetStateAction, useState, useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { ProjetModel } from '../../data/models';
import { useFetch } from '../../hooks/useFetch';
import env from '../../utils/env';

type FicheProjetProps = {
  tab: string;
  children: React.ReactNode;
  updateProjet: Dispatch<SetStateAction<ProjetModel>>;
};

const FicheProjet = ({ tab, updateProjet, children }: FicheProjetProps) => {

  const [error, setError] = useState('');

  const { sid: projetId } = useParams() || "";
  if (!projetId || !projetId.trim()) {
    setError("L'identifiant projet fourni est erroné!");
  }

  const { data: loadedProjet, error: loadProjetError } = useFetch<ProjetModel>(
    `${env.API_URL}/api/projets/${projetId}`
  );

  // Récupérer le projet
  useEffect(() => {
    if (loadedProjet) { updateProjet(loadedProjet); }
    if (loadProjetError) { setError(loadProjetError); }
  }, [loadedProjet]);

  return (
    <div>
      <div className="w-full lg:ps-64">
        <div className="p-4 sm:p-6 space-y-4 sm:space-y-6">

          <div className="flex flex-col">
            <div className="-m-1.5 overflow-x-auto">
              <div className="p-1.5 w-full inline-block align-middle">
                <div className="bg-white border border-gray-200 rounded-xl shadow-sm overflow-hidden dark:bg-neutral-800 dark:border-neutral-700">

                  <div className="relative overflow-hidden min-h-32 bg-red-950 text-center rounded-t-xl dark:bg-neutral-950">
                    <figure className="absolute inset-x-0 bottom-0 -mb-px">
                      <svg preserveAspectRatio="none" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 1920 100.1">
                        <path fill="currentColor" className="fill-white dark:fill-neutral-800" d="M0,0c0,0,934.4,93.4,1920,0v100.1H0L0,0z"></path>
                      </svg>
                    </figure>
                  </div>

                  <div className="relative z-10 -mt-12">
                    <span className="mx-auto flex justify-center items-center size-[62px] rounded-full border border-gray-200 bg-white text-gray-700 shadow-sm dark:bg-neutral-800 dark:border-neutral-700 dark:text-neutral-400">
                      <svg className="flex-shrink-0 size-6" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" viewBox="0 0 16 16">
                        <path d="M1.92.506a.5.5 0 0 1 .434.14L3 1.293l.646-.647a.5.5 0 0 1 .708 0L5 1.293l.646-.647a.5.5 0 0 1 .708 0L7 1.293l.646-.647a.5.5 0 0 1 .708 0L9 1.293l.646-.647a.5.5 0 0 1 .708 0l.646.647.646-.647a.5.5 0 0 1 .708 0l.646.647.646-.647a.5.5 0 0 1 .801.13l.5 1A.5.5 0 0 1 15 2v12a.5.5 0 0 1-.053.224l-.5 1a.5.5 0 0 1-.8.13L13 14.707l-.646.647a.5.5 0 0 1-.708 0L11 14.707l-.646.647a.5.5 0 0 1-.708 0L9 14.707l-.646.647a.5.5 0 0 1-.708 0L7 14.707l-.646.647a.5.5 0 0 1-.708 0L5 14.707l-.646.647a.5.5 0 0 1-.708 0L3 14.707l-.646.647a.5.5 0 0 1-.801-.13l-.5-1A.5.5 0 0 1 1 14V2a.5.5 0 0 1 .053-.224l.5-1a.5.5 0 0 1 .367-.27zm.217 1.338L2 2.118v11.764l.137.274.51-.51a.5.5 0 0 1 .707 0l.646.647.646-.646a.5.5 0 0 1 .708 0l.646.646.646-.646a.5.5 0 0 1 .708 0l.646.646.646-.646a.5.5 0 0 1 .708 0l.646.646.646-.646a.5.5 0 0 1 .708 0l.646.646.646-.646a.5.5 0 0 1 .708 0l.509.509.137-.274V2.118l-.137-.274-.51.51a.5.5 0 0 1-.707 0L12 1.707l-.646.647a.5.5 0 0 1-.708 0L10 1.707l-.646.647a.5.5 0 0 1-.708 0L8 1.707l-.646.647a.5.5 0 0 1-.708 0L6 1.707l-.646.647a.5.5 0 0 1-.708 0L4 1.707l-.646.647a.5.5 0 0 1-.708 0l-.509-.51z" />
                        <path d="M3 4.5a.5.5 0 0 1 .5-.5h6a.5.5 0 1 1 0 1h-6a.5.5 0 0 1-.5-.5zm0 2a.5.5 0 0 1 .5-.5h6a.5.5 0 1 1 0 1h-6a.5.5 0 0 1-.5-.5zm0 2a.5.5 0 0 1 .5-.5h6a.5.5 0 1 1 0 1h-6a.5.5 0 0 1-.5-.5zm0 2a.5.5 0 0 1 .5-.5h6a.5.5 0 0 1 0 1h-6a.5.5 0 0 1-.5-.5zm8-6a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 0 1h-1a.5.5 0 0 1-.5-.5zm0 2a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 0 1h-1a.5.5 0 0 1-.5-.5zm0 2a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 0 1h-1a.5.5 0 0 1-.5-.5zm0 2a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 0 1h-1a.5.5 0 0 1-.5-.5z" />
                      </svg>
                    </span>
                  </div>

                  <div className="px-4 pt-4 sm:px-7 sm:pt-7 overflow-y-auto">
                    <div className="text-center">
                      <h3 className="text-xl pb-4 font-semibold text-gray-800 dark:text-neutral-200">
                        {loadedProjet?.nomTypeTravaux + " de l'établissement " + loadedProjet?.etablissement?.nom}
                      </h3>
                      <p className="text-base text-gray-500 dark:text-neutral-500">
                        Projet #{loadedProjet?.greza}
                      </p>
                    </div>
                    <div className="grid sm:grid-cols-4 gap-4 sm:gap-6 md:gap-8 lg:gap-12 mt-7 mb-8">
                      <div className="pl-8 col-span-2">
                        <p className="text-base text-gray-600 dark:text-neutral-400">
                          Phase
                        </p>
                        <div className="mb-2 mt-1 bg-gradient-to-r from-gray-200 via-gray-50 to-transparent h-0.5 dark:from-neutral-700 dark:via-neutral-900">
                          <div className="bg-gray-400 w-9 h-0.5 dark:bg-neutral-600"></div>
                        </div>
                        <p>
                          <span className="relative inline-block font-medium text-black before:absolute before:bottom-0.5 before:start-0 before:-z-[1] before:w-full before:h-1 before:bg-lime-400 hover:before:bg-black focus:outline-none focus:before:bg-black dark:text-white dark:hover:before:bg-white dark:focus:before:bg-white">
                            {loadedProjet?.typeIntervention?.nom}
                          </span>
                        </p>
                      </div>
                      <div className="pl-8">
                        <p className="text-base text-gray-600 dark:text-neutral-400">
                          Type travaux
                        </p>
                        <div className="mb-2 mt-1 bg-gradient-to-r from-gray-200 via-gray-50 to-transparent h-0.5 dark:from-neutral-700 dark:via-neutral-900">
                          <div className="bg-gray-400 w-9 h-0.5 dark:bg-neutral-600"></div>
                        </div>
                        <p>
                          <span className="relative inline-block font-medium text-black before:absolute before:bottom-0.5 before:start-0 before:-z-[1] before:w-full before:h-1 before:bg-lime-400 hover:before:bg-black focus:outline-none focus:before:bg-black dark:text-white dark:hover:before:bg-white dark:focus:before:bg-white">
                            {loadedProjet?.nomTypeTravaux}
                          </span>
                        </p>
                      </div>

                      <div className="pl-8">
                        <p className="text-base text-gray-600 dark:text-neutral-400">
                          Statut
                        </p>
                        <div className="mb-2 mt-1 bg-gradient-to-r from-gray-200 via-gray-50 to-transparent h-0.5 dark:from-neutral-700 dark:via-neutral-900">
                          <div className="bg-gray-400 w-9 h-0.5 dark:bg-neutral-600"></div>
                        </div>
                        <p>
                          <span className="relative inline-block font-medium text-black before:absolute before:bottom-0.5 before:start-0 before:-z-[1] before:w-full before:h-1 before:bg-lime-400 hover:before:bg-black focus:outline-none focus:before:bg-black dark:text-white dark:hover:before:bg-white dark:focus:before:bg-white">
                            {loadedProjet?.nomStatut}
                          </span>
                        </p>
                      </div>

                    </div>
                    <nav className="flex space-x-1" aria-label="Tabs" role="tablist">
                      <Link to={`/projets/${projetId}/view/details`}>
                        <button type="button" className={(tab === "DETAILS" ? "active " : "") + "hs-tab-active:font-semibold hs-tab-active:border-red-600 hs-tab-active:text-red-600 py-4 px-3 inline-flex items-center gap-x-2 border-b-2 border-transparent text-base whitespace-nowrap text-gray-500 hover:text-red-600 focus:outline-none focus:text-red-600 disabled:opacity-50 disabled:pointer-events-none dark:text-neutral-400 dark:hover:text-red-500"} id="tabs-with-icons-item-1" data-hs-tab="#tabs-with-icons-1" aria-controls="tabs-with-icons-1" role="tab">
                          {/* <svg className="flex-shrink-0 size-4" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                            <path d="m3 9 9-7 9 7v11a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2z"></path>
                            <polyline points="9 22 9 12 15 12 15 22"></polyline>
                          </svg> */}
                          Informations générales
                        </button>
                      </Link>
                      <Link to={`/projets/${projetId}/view/avancements`}>
                        <button type="button" className={(tab === "AVANCEMENTS" ? "active " : "") + "hs-tab-active:font-semibold hs-tab-active:border-red-600 hs-tab-active:text-red-600 py-4 px-3 inline-flex items-center gap-x-2 border-b-2 border-transparent text-base whitespace-nowrap text-gray-500 hover:text-red-600 focus:outline-none focus:text-red-600 disabled:opacity-50 disabled:pointer-events-none dark:text-neutral-400 dark:hover:text-red-500"} id="tabs-with-icons-item-2" data-hs-tab="#tabs-with-icons-2" aria-controls="tabs-with-icons-2" role="tab">
                          {/* <svg className="flex-shrink-0 size-4" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                            <circle cx="12" cy="12" r="10"></circle>
                            <circle cx="12" cy="10" r="3"></circle>
                            <path d="M7 20.662V19a2 2 0 0 1 2-2h6a2 2 0 0 1 2 2v1.662"></path>
                          </svg> */}
                          Avancements
                        </button>
                      </Link>
                      <Link to={`/projets/${projetId}/view/faits`}>
                        <button type="button" className={(tab === "FAITS" ? "active " : "") + "hs-tab-active:font-semibold hs-tab-active:border-red-600 hs-tab-active:text-red-600 py-4 px-3 inline-flex items-center gap-x-2 border-b-2 border-transparent text-base whitespace-nowrap text-gray-500 hover:text-red-600 focus:outline-none focus:text-red-600 disabled:opacity-50 disabled:pointer-events-none dark:text-neutral-400 dark:hover:text-red-500"} id="tabs-with-icons-item-3" data-hs-tab="#tabs-with-icons-3" aria-controls="tabs-with-icons-3" role="tab">
                          {/* <svg className="flex-shrink-0 size-4" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                            <path d="M12.22 2h-.44a2 2 0 0 0-2 2v.18a2 2 0 0 1-1 1.73l-.43.25a2 2 0 0 1-2 0l-.15-.08a2 2 0 0 0-2.73.73l-.22.38a2 2 0 0 0 .73 2.73l.15.1a2 2 0 0 1 1 1.72v.51a2 2 0 0 1-1 1.74l-.15.09a2 2 0 0 0-.73 2.73l.22.38a2 2 0 0 0 2.73.73l.15-.08a2 2 0 0 1 2 0l.43.25a2 2 0 0 1 1 1.73V20a2 2 0 0 0 2 2h.44a2 2 0 0 0 2-2v-.18a2 2 0 0 1 1-1.73l.43-.25a2 2 0 0 1 2 0l.15.08a2 2 0 0 0 2.73-.73l.22-.39a2 2 0 0 0-.73-2.73l-.15-.08a2 2 0 0 1-1-1.74v-.5a2 2 0 0 1 1-1.74l.15-.09a2 2 0 0 0 .73-2.73l-.22-.38a2 2 0 0 0-2.73-.73l-.15.08a2 2 0 0 1-2 0l-.43-.25a2 2 0 0 1-1-1.73V4a2 2 0 0 0-2-2z"></path>
                            <circle cx="12" cy="12" r="3"></circle>
                          </svg> */}
                          Faits marquants
                        </button>
                      </Link>
                      <Link to={`/projets/${projetId}/view/decomptes`}>
                        <button type="button" className={(tab === "DECOMPTES" ? "active " : "") + "hs-tab-active:font-semibold hs-tab-active:border-red-600 hs-tab-active:text-red-600 py-4 px-3 inline-flex items-center gap-x-2 border-b-2 border-transparent text-base whitespace-nowrap text-gray-500 hover:text-red-600 focus:outline-none focus:text-red-600 disabled:opacity-50 disabled:pointer-events-none dark:text-neutral-400 dark:hover:text-red-500"} id="tabs-with-icons-item-3" data-hs-tab="#tabs-with-icons-3" aria-controls="tabs-with-icons-3" role="tab">
                          {/* <svg className="flex-shrink-0 size-4" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                            <path d="M12.22 2h-.44a2 2 0 0 0-2 2v.18a2 2 0 0 1-1 1.73l-.43.25a2 2 0 0 1-2 0l-.15-.08a2 2 0 0 0-2.73.73l-.22.38a2 2 0 0 0 .73 2.73l.15.1a2 2 0 0 1 1 1.72v.51a2 2 0 0 1-1 1.74l-.15.09a2 2 0 0 0-.73 2.73l.22.38a2 2 0 0 0 2.73.73l.15-.08a2 2 0 0 1 2 0l.43.25a2 2 0 0 1 1 1.73V20a2 2 0 0 0 2 2h.44a2 2 0 0 0 2-2v-.18a2 2 0 0 1 1-1.73l.43-.25a2 2 0 0 1 2 0l.15.08a2 2 0 0 0 2.73-.73l.22-.39a2 2 0 0 0-.73-2.73l-.15-.08a2 2 0 0 1-1-1.74v-.5a2 2 0 0 1 1-1.74l.15-.09a2 2 0 0 0 .73-2.73l-.22-.38a2 2 0 0 0-2.73-.73l-.15.08a2 2 0 0 1-2 0l-.43-.25a2 2 0 0 1-1-1.73V4a2 2 0 0 0-2-2z"></path>
                            <circle cx="12" cy="12" r="3"></circle>
                          </svg> */}
                          Décomptes
                        </button>
                      </Link>
                    </nav>
                  </div>

                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="w-full lg:ps-64">
        <div className="p-4 sm:p-6 space-y-4 sm:space-y-6">

          <div className="flex flex-col">
            <div className="-m-1.5 overflow-x-auto">
              <div className="p-1.5 w-full inline-block align-middle">
                <div className="bg-white border border-gray-200 rounded-xl shadow-sm overflow-hidden dark:bg-neutral-800 dark:border-neutral-700">

                  {children}

                  <div className="mt-3">
                    <div id="tabs-with-icons-1" role="tabpanel" aria-labelledby="tabs-with-icons-item-1">
{/*                       <p className="text-gray-500 dark:text-neutral-400">
                        This is the <em className="font-semibold text-gray-800 dark:text-neutral-200">first</em> item's tab body.
                      </p> */}
                    </div>
                    <div id="tabs-with-icons-2" className="hidden" role="tabpanel" aria-labelledby="tabs-with-icons-item-2">
{/*                       <p className="text-gray-500 dark:text-neutral-400">
                        This is the <em className="font-semibold text-gray-800 dark:text-neutral-200">second</em> item's tab body.
                      </p> */}
                    </div>
                    <div id="tabs-with-icons-3" className="hidden" role="tabpanel" aria-labelledby="tabs-with-icons-item-3">
{/*                       <p className="text-gray-500 dark:text-neutral-400">
                        This is the <em className="font-semibold text-gray-800 dark:text-neutral-200">third</em> item's tab body.
                      </p> */}
                    </div>
                  </div>

                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>
  );
};

export default FicheProjet;
