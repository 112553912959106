import { AvancementDecompteModel } from '../../../../data/models';
import { Link } from 'react-router-dom';

type AvancementEtapeFormProps = {
    handleFieldChange: (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => void;
    handleFileChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
    handleSubmit: (e: React.MouseEvent) => void;
    handleClearForm?: (e: React.MouseEvent) => void | null;
    avancement: AvancementDecompteModel;
    file?: File | null;
};

const AvancementDecompteForm = (props: AvancementEtapeFormProps) => {

    const __ = props.avancement;

    return (
        <form>
            <div className="grid grid-cols-12 gap-2 gap-6">
                <div className="sm:col-span-3">
                    <label htmlFor="af-pourcentage" className="inline-block text-sm text-gray-800 mt-2.5 dark:text-neutral-200">
                        Pourcentage
                    </label>
                    <div className="hs-tooltip inline-block">
                        <button type="button" className="hs-tooltip-toggle ms-1">
                            <svg className="inline-block size-3 text-gray-400 dark:text-neutral-600" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" viewBox="0 0 16 16">
                                <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                                <path d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0z" />
                            </svg>
                        </button>
                        <span className="hs-tooltip-content hs-tooltip-shown:opacity-100 hs-tooltip-shown:visible opacity-0 transition-opacity inline-block absolute invisible w-40 text-center z-10 py-1 px-2 bg-gray-900 text-xs font-medium text-white rounded shadow-sm dark:bg-neutral-700" role="tooltip">
                            En deux chiffres et sans saisir le symbole '%'
                        </span>
                    </div>
                </div>

                <div className="sm:col-span-9">
                    <input id="af-pourcentage"
                        className="py-2 px-3 pe-11 block w-full border-gray-200 shadow-sm -mt-px -ms-px first:rounded-t-lg last:rounded-b-lg sm:first:rounded-s-lg sm:mt-0 sm:first:ms-0 sm:first:rounded-se-none sm:last:rounded-es-none sm:last:rounded-e-lg text-sm relative focus:z-10 focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none dark:bg-neutral-800 dark:border-neutral-700 dark:text-neutral-400 dark:placeholder-neutral-500 dark:focus:ring-neutral-600"
                        type="text"
                        name="pourcentage"
                        placeholder="Pourcentage d'avancement..."
                        value={__.pourcentage}
                        onChange={props.handleFieldChange}
                    />
                </div>

                <div className="sm:col-span-3">
                    <label htmlFor="af-piece-jointe" className="inline-block text-sm text-gray-800 mt-2.5 dark:text-neutral-200">
                        Pièce jointe
                    </label>
                </div>
                <div className="sm:col-span-9">
                    {(__.pieceJointe || props.file) && (
                        <div className="mb-2 flex items-center gap-x-2 p-3 text-sm bg-white border text-gray-800 first:rounded-t-lg first:mt-0 last:rounded-b-lg dark:bg-neutral-900 dark:border-neutral-700 dark:text-neutral-200">
                            <div className="w-full flex justify-between truncate">
                                <span className="me-3 flex-1 w-0 truncate">
                                    {__.pieceJointe ? __.pieceJointe.document?.originalFilename : (props.file ? props.file.name : '')}
                                </span>
                                {__.pieceJointe && (
                                    <Link to={__.pieceJointe.downloadUrl}>
                                        <button type="button" className="flex items-center gap-x-2 text-gray-500 hover:text-blue-600 whitespace-nowrap dark:text-neutral-500 dark:hover:text-blue-500">
                                            <svg className="flex-shrink-0 size-4" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" stroke-linecap="round" stroke-linejoin="round"><path d="M21 15v4a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-4" /><polyline points="7 10 12 15 17 10" /><line x1="12" x2="12" y1="15" y2="3" /></svg>
                                            Consulter
                                        </button>
                                    </Link>
                                )}
                            </div>
                        </div>
                    )}
                    <label htmlFor="af-piece-jointe" className="group p-6 sm:p-6 block cursor-pointer text-center border-2 border-dashed border-gray-200 rounded-lg focus-within:outline-none focus-within:ring-2 focus-within:ring-blue-500 focus-within:ring-offset-2 dark:border-neutral-700">
                        <input id="af-piece-jointe" className="sr-only"
                            type="file"
                            onChange={props.handleFileChange} />
                        <svg className="size-10 mx-auto text-gray-400 dark:text-neutral-600" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" viewBox="0 0 16 16">
                            <path fill-rule="evenodd" d="M7.646 5.146a.5.5 0 0 1 .708 0l2 2a.5.5 0 0 1-.708.708L8.5 6.707V10.5a.5.5 0 0 1-1 0V6.707L6.354 7.854a.5.5 0 1 1-.708-.708l2-2z" />
                            <path d="M4.406 3.342A5.53 5.53 0 0 1 8 2c2.69 0 4.923 2 5.166 4.579C14.758 6.804 16 8.137 16 9.773 16 11.569 14.502 13 12.687 13H3.781C1.708 13 0 11.366 0 9.318c0-1.763 1.266-3.223 2.942-3.593.143-.863.698-1.723 1.464-2.383zm.653.757c-.757.653-1.153 1.44-1.153 2.056v.448l-.445.049C2.064 6.805 1 7.952 1 9.318 1 10.785 2.23 12 3.781 12h8.906C13.98 12 15 10.988 15 9.773c0-1.216-1.02-2.228-2.313-2.228h-.5v-.5C12.188 4.825 10.328 3 8 3a4.53 4.53 0 0 0-2.941 1.1z" />
                        </svg>
                        <span className="mt-2 block text-sm text-gray-800 dark:text-neutral-200">
                            Cliquer ici pour charger la pièce jointe...
                        </span>
                        <span className="mt-1 block text-xs text-gray-500 dark:text-neutral-500">
                            La taille maximum est de 2 MB
                        </span>
                    </label>
                </div>

                <div className="sm:col-span-3">
                    <label htmlFor="af-localisation" className="inline-block text-sm text-gray-800 mt-2.5 dark:text-neutral-200">
                        Localisation géographique
                    </label>
                </div>
                <div className="sm:col-span-9">
                    <div className="sm:flex">
                        <input id="af-localisation"
                            className="py-2 px-3 pe-11 block w-full border-gray-200 shadow-sm -mt-px -ms-px first:rounded-t-lg last:rounded-b-lg sm:first:rounded-s-lg sm:mt-0 sm:first:ms-0 sm:first:rounded-se-none sm:last:rounded-es-none sm:last:rounded-e-lg text-sm relative focus:z-10 focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none dark:bg-neutral-800 dark:border-neutral-700 dark:text-neutral-400 dark:placeholder-neutral-500 dark:focus:ring-neutral-600"
                            type="text"
                            name="latitude"
                            placeholder="Latitude..."
                            value={__.latitude}
                            onChange={props.handleFieldChange}
                        />
                        <input
                            className="py-2 px-3 pe-11 block w-full border-gray-200 shadow-sm -mt-px -ms-px first:rounded-t-lg last:rounded-b-lg sm:first:rounded-s-lg sm:mt-0 sm:first:ms-0 sm:first:rounded-se-none sm:last:rounded-es-none sm:last:rounded-e-lg text-sm relative focus:z-10 focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none dark:bg-neutral-800 dark:border-neutral-700 dark:text-neutral-400 dark:placeholder-neutral-500 dark:focus:ring-neutral-600"
                            type="text"
                            name="longitude"
                            placeholder="Longitude..."
                            value={__.longitude}
                            onChange={props.handleFieldChange}
                        />
                    </div>
                </div>

                <div className="sm:col-span-3">
                    <label htmlFor="hs-checkbox-blocage" className="inline-block text-sm text-gray-800 mt-2.5 dark:text-neutral-200">
                    </label>
                </div>
                <div className="sm:col-span-9">
                    <div className="grid sm:grid-cols-2 gap-2">
                        <label htmlFor='hs-checkbox-blocage' className="flex p-3 w-full bg-white border border-gray-200 rounded-lg text-sm focus:border-blue-500 focus:ring-blue-500 dark:bg-neutral-900 dark:border-neutral-700 dark:text-neutral-400">
                            <input type="checkbox" name='blocage'
                                className="shrink-0 mt-0.5 border-gray-200 rounded text-red-600 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none dark:bg-neutral-800 dark:border-neutral-700 dark:checked:bg-blue-500 dark:checked:border-blue-500 dark:focus:ring-offset-gray-800"
                                id='hs-checkbox-blocage'
                                onChange={props.handleFieldChange}
                                checked={__.blocage} />
                            <span className="text-sm text-gray-500 ms-3 dark:text-neutral-400">Blocage</span>
                        </label>
                    </div>
                </div>

                <div className="sm:col-span-3">
                    <label htmlFor="af-detail-blocage" className="inline-block text-sm text-gray-800 mt-2.5 dark:text-neutral-200">
                        Détail blocage
                    </label>
                </div>

                <div className="sm:col-span-9">
                    <textarea id="af-detail-blocage" name="detailBlocage"
                        className="py-2 px-3 block w-full border-gray-200 rounded-lg text-sm focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none dark:bg-neutral-800 dark:border-neutral-700 dark:text-neutral-400 dark:placeholder-neutral-500 dark:focus:ring-neutral-600"
                        rows={6} placeholder="Saisir les détails de blocage..."
                        value={__.detailBlocage}
                        onChange={props.handleFieldChange} />
                </div>

                <div className="sm:col-span-3">
                    <label htmlFor="af-commentaire" className="inline-block text-sm text-gray-800 mt-2.5 dark:text-neutral-200">
                        Commentaire
                    </label>
                </div>

                <div className="sm:col-span-9">
                    <textarea id="af-commentaire" name="commentaire"
                        className="py-2 px-3 block w-full border-gray-200 rounded-lg text-sm focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none dark:bg-neutral-800 dark:border-neutral-700 dark:text-neutral-400 dark:placeholder-neutral-500 dark:focus:ring-neutral-600"
                        rows={6} placeholder="Saisir le commentaire..."
                        value={__.commentaire}
                        onChange={props.handleFieldChange} />
                </div>
            </div>
            <div className="mt-8 px-6 py-4 grid-cols-2 gap-3 flex justify-between items-center border-t border-gray-200 dark:border-neutral-700">
                <div></div>

                <div className="inline-flex gap-x-2 -mr-6 ">
                    {props.handleClearForm && (
                        <button type="button"
                            onClick={props.handleClearForm}
                            className="py-2 px-3 inline-flex items-center gap-x-2 text-sm font-medium rounded-lg border border-gray-200 bg-white text-gray-800 shadow-sm hover:bg-gray-50 disabled:opacity-50 disabled:pointer-events-none dark:bg-neutral-800 dark:border-neutral-700 dark:text-white dark:hover:bg-neutral-800">
                            Effacer
                        </button>
                    )}
                    <button type="button"
                        className="py-2 px-3 inline-flex items-center gap-x-2 text-sm font-semibold rounded-lg border border-transparent bg-red-600 text-white hover:bg-red-700 disabled:opacity-50 disabled:pointer-events-none"
                        onClick={props.handleSubmit}>
                        Enregistrer
                    </button>
                </div>

            </div>

            {/* <div className="mt-5 flex justify-end gap-x-2">
                    {props.handleClearForm && (
                        <button type="button"
                            onClick={props.handleClearForm}
                            className="py-2 px-3 inline-flex items-center gap-x-2 text-sm font-medium rounded-lg border border-gray-200 bg-white text-gray-800 shadow-sm hover:bg-gray-50 disabled:opacity-50 disabled:pointer-events-none dark:bg-neutral-800 dark:border-neutral-700 dark:text-white dark:hover:bg-neutral-800">
                            Effacer
                        </button>
                    )}
                    <button type="button"
                        className="py-2 px-3 inline-flex items-center gap-x-2 text-sm font-semibold rounded-lg border border-transparent bg-red-600 text-white hover:bg-red-700 disabled:opacity-50 disabled:pointer-events-none"
                        onClick={props.handleSubmit}>
                        Enregistrer
                    </button>
                </div> */}

        </form>
    );
};

export default AvancementDecompteForm;
