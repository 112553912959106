const getEnv = (key: string) => {
    const envVariable = process.env[key];
    if (!envVariable) {
      throw new Error(`Env variable ${key} not found`);
    }
  
    return envVariable as string;
};

export default {
    API_URL: process.env.REACT_APP_API_URL,
    API_KEY: process.env.REACT_APP_API_KEY,
    NODE_ENV: process.env.NODE_ENV,
    DEFAULT_TITLE: process.env.REACT_APP_TITLE
};