import { TrancheProgrammeModel } from '../../data/models';
import env from '../../utils/env';

type ThisProps = {
    currentTranche: string | undefined;
    tranches: TrancheProgrammeModel[] | null;
    handleTrancheChange: (e: React.ChangeEvent<HTMLSelectElement>) => void;
    handleGenererRapportClick: (e: React.MouseEvent<HTMLElement>) => void;
};

const Reporting = (props: ThisProps) => {

    return (
        <div className="flex flex-col">
            <div className="-m-1.5 overflow-x-auto">
                <div className="p-1.5 min-w-full inline-block align-middle">
                    <div className="bg-white border border-gray-200 rounded-xl shadow-sm overflow-hidden dark:bg-neutral-800 dark:border-neutral-700">
                        <div className="max-w-[85rem] px-2 py-6 mx-auto">
                            {/* <div className="px-6 py-4 grid gap-3 md:flex md:justify-between md:items-center border-b border-gray-200 dark:border-neutral-700">
                            <div>
                                <h2 className="text-xl font-semibold text-gray-800 dark:text-neutral-200">
                                    Projets par statut
                                </h2>
                                <p className="text-sm text-gray-600 dark:text-neutral-400">
                                    Répartition des projets par statut.
                                </p>
                            </div>

                            <div>
                                <div className="inline-flex gap-x-2">
                                </div>
                            </div>
                        </div> */}

                            <div className="grid grid-cols-6 gap-6">
                                <div className="col-start-2 col-span-2">
                                    <select name="tranche" id="tranche-programme"
                                        className="py-3 px-4 pe-9 block w-full border-gray-200 shadow-sm text-sm rounded-lg focus:border-red-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none dark:bg-neutral-900 dark:border-neutral-700 dark:text-neutral-400 dark:placeholder-neutral-500 dark:focus:ring-neutral-600"
                                        value={props.currentTranche}
                                        onChange={props.handleTrancheChange}>
                                        <option value="">Tranche du programme...</option>
                                        {props.tranches?.map(({ nom, libelle }) => (
                                            <option value={nom} selected={props.currentTranche ? props.currentTranche === nom : false}>{libelle}</option>
                                        ))}
                                    </select>
                                </div>
                                <div className="col-end-6 col-span-2">
                                    <button className="w-full py-3 px-4 inline-flex justify-center items-center gap-x-2 text-sm font-semibold rounded-lg border border-transparent bg-red-600 text-white hover:bg-red-700 disabled:opacity-50 disabled:pointer-events-none"
                                        onClick={props.handleGenererRapportClick}
                                        disabled={props.currentTranche === undefined}>
                                        Générer le rapport Excel
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div >
    );
};

export default Reporting;
