import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useLocalStorage } from '../hooks/useLocalStorage';
import { isBefore } from '../utils/dateHelpers';
import { decodeJwtClaims } from '../utils/jwt';
import { UserProfile } from '../data/models';
import env from '../utils/env';

type AuthContextType = {
  exp: number | null;
  token: string | null;
  isLogged: boolean;
  role: string | null;
  username: string | null;
  userId: number | null;
  profiles: UserProfile[] | null;
};

const initialState: AuthContextType = {
  exp: null,
  token: null,
  isLogged: false,
  role: null,
  username: null,
  userId: null,
  profiles: null
};

const AuthUpdateContext = React.createContext({
  updateAuthState: (_token: string | null): void => undefined,
  checkTokenExpiration: (): void => undefined,
  authState: initialState,
  hasAuthority: (...authorities: string[]): boolean => false,
});

export const useAuthContext = (): {
  authState: AuthContextType;
  updateAuthState: (token: string | null) => void;
  checkTokenExpiration: () => void;
  hasAuthority: (...authorities: string[]) => boolean;
} => React.useContext(AuthUpdateContext);

type AuthProviderProps = { children: React.ReactNode };

const AuthProvider = ({ children }: AuthProviderProps) => {
  const { localStorageItem: token, setLocalStorageItem: setLocalStorageToken } =
    useLocalStorage<string>('JWT_TOKEN');
  const [exp, setExp] = useState(0);
  const [username, setUsername] = useState<string | null>(null);
  const [isLogged, setIsLogged] = useState(false);
  const [role, setRole] = useState<string | null>(null);
  const [userId, setUserId] = useState<number | null>(null);
  const [profiles, setProfiles] = useState<UserProfile[]>([]);
  
  useEffect(() => {
    if (token && username) {
      getUserProfiles()
        .then((data) => setProfiles(data))
        .catch((reason) => console.log("Message:" + reason.message));
    }
  }, [username]);

  const getUserProfiles = async () => {
    if (username) {
      try {
        const result = await fetch(
          `${env.API_URL}/api/users/${username}/profiles`,
          {
            method: "GET",
            headers: { 'authorization': `Bearer ${token}` }
          });

        const data = await result.json();
        return data;
      } catch (e) {
        console.error(e);
      }
    }
  };

  const hasAuthority = useCallback((...authorities: string[]) => {
    if (profiles && profiles.length > 0) {
      let missingAuthorities: string[] = [];
      missingAuthorities = missingAuthorities.concat(authorities);
      profiles.forEach(({ name, roles }) => {
        missingAuthorities = missingAuthorities.filter(a => !roles.includes(a));
      });
      return missingAuthorities.length < authorities.length;
    }
    return false;
  }, [profiles]);

  const setNotLoggedState = useCallback(() => {
    setLocalStorageToken(null);

    setExp((_prevState) => 0);
    setRole(null);
    setUsername(null);
    setIsLogged(false);
    setUserId(null);
    setProfiles([]);
  }, [setLocalStorageToken]);

  const updateAuthState = useCallback(
    (token: string | null) => {
      if (token === null) {
        setNotLoggedState();
        return;
      }

      setLocalStorageToken(token);

      const { exp, role, sub, userId } = decodeJwtClaims(token);
      setExp(exp);
      setRole(role);
      setUsername(sub);
      setIsLogged(true);
      setUserId(userId);
    },
    [setLocalStorageToken, setNotLoggedState]
  );

  const authState = useMemo(
    () => ({
      exp,
      role,
      token,
      username,
      isLogged,
      userId,
      profiles
    }),
    [exp, isLogged, role, token, username, userId, profiles]
  );

  const checkTokenExpiration = useCallback(() => {
    if (token === null) {
      setNotLoggedState();
      return;
    }

    const { exp, role, sub, userId } = decodeJwtClaims(token);

    var now = new Date();
    now.setHours(now.getHours() + 1);

    if (isBefore(exp, new Date())) {
      setNotLoggedState();
    }

    setExp(exp);
    setRole(role);
    setUsername(sub);
    setIsLogged(true);
    setUserId(userId);
  }, [setNotLoggedState, token]);

  const contextState = useMemo(
    () => ({
      authState,
      updateAuthState,
      checkTokenExpiration,
      hasAuthority
    }),
    [authState, checkTokenExpiration, updateAuthState, hasAuthority]
  );

  return (
    <AuthUpdateContext.Provider value={contextState}>
      {children}
    </AuthUpdateContext.Provider>
  );
};

export default AuthProvider;