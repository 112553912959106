import { useState, useEffect } from 'react';
import { ProjetModel, AffectationModel, UserModel, CompetenceModel } from '../../data/models';
import { useFetch } from '../../hooks/useFetch';
import env from '../../utils/env';
import Alert from "../../components/Alert";
import AffectationForm from '../../components/affectation/AffectationForm';

const AddAffectationPage = () => {

  const [_affectation_, setAffectation] = useState<AffectationModel>(
    {
      projet: {} as ProjetModel,
      user: {} as UserModel
    } as AffectationModel
  );

  const [error, setError] = useState('');
  const [validationErrors, setValidationErrors] = useState<string[]>([]);
  const [successful, setSuccessful] = useState<boolean>(false);

  const {
    error: postAffectationError,
    data: postAffectationResp,
    sendRequest: postAffectation } = useFetch<AffectationModel>(
      `${env.API_URL}/api/affectations`,
      {
        method: 'POST',
        payload: _affectation_
      }
    );

  useEffect(() => {
    if (postAffectationResp) {
      setSuccessful(true);
      setError('');
    }
    if (postAffectationError) {
      setError(postAffectationError);
      setSuccessful(false);
    }
  }, [postAffectationResp, postAffectationError]);

  const { data: projets, error: loadProjetsError } = useFetch<ProjetModel[]>(
    `${env.API_URL}/api/projets`
  );
  const { data: users, error: loadUsersError } = useFetch<UserModel[]>(
    `${env.API_URL}/api/users`
  );
  const { data: competences, error: loadCompetsError } = useFetch<CompetenceModel[]>(
    `${env.API_URL}/api/refs/competences`
  );

  useEffect(() => {
    if (loadProjetsError) { setError(loadProjetsError); }
    if (loadUsersError) { setError(loadUsersError); }
    if (loadCompetsError) { setError(loadCompetsError); }
  }, [loadProjetsError, loadUsersError, loadCompetsError]);

  const handleOnSubmit = (e: React.MouseEvent) => {
    e.preventDefault();

    let errorMsg = 'Merci de bien vouloir saisir les champs suivants :\n';
    let errors = [];

    //let value = `${_.nom}`.trim();
    if (!_affectation_.projet?.sid || !_affectation_.projet?.sid.trim()) {
      errors.push("Le projet est obligatoire");
    }
    if (!_affectation_.user?.sid || !_affectation_.user?.sid.trim()) {
      errors.push("Le responsable est obligatoire");
    }
    if (!_affectation_.competence?.nom || !_affectation_.competence?.nom.trim()) {
      errors.push("La compétence est obligatoire");
    }

    if (errors.length == 0) {
      postAffectation();
    } else {
      setError(errorMsg);
      setValidationErrors(errors);
    }
  };

  const handleFieldChange = (e: React.ChangeEvent<HTMLInputElement
    | HTMLTextAreaElement | HTMLSelectElement>) => {
    const { name, value, type } = e.target;

    if (type.startsWith('select') && name.startsWith('user')) {
      setAffectation(a => ({
        ...a,
        user: {
          ...a.user,
          sid: value || undefined
        } as UserModel
      }))
    }
    if (type.startsWith('select') && name.startsWith('projet')) {
      setAffectation(a => ({
        ...a,
        projet: {
          ...a.projet,
          sid: value || undefined
        } as ProjetModel
      }))
    }
    if (name.startsWith('affect-competence-')) {
      let field = 'statut';
      let selectedCompet = name.replace(/affect-competence-/gi, "");
      setAffectation(a => ({
        ...a,
        competence: {
          ...a.competence,
          nom: selectedCompet
        }
      }))
    }
    if (type === 'text' || type === 'textarea') {
      setAffectation(a => ({
        ...a,
        [name]: value || undefined
      }));
    }
  };

  const handleClearForm = (e: React.MouseEvent) => {
    e.preventDefault();
  }

  return (
    <div className="w-full lg:ps-64">
      <div className="p-4 sm:p-6 space-y-4 sm:space-y-6">

        <div className="flex flex-col">
          <div className="-m-1.5 overflow-x-auto">
            <div className="p-1.5 min-w-full inline-block align-middle">
              <div className="bg-white border border-gray-200 rounded-xl shadow-sm overflow-hidden dark:bg-neutral-800 dark:border-neutral-700">

                <div className="px-6 py-4 grid gap-3 md:flex md:justify-between md:items-center border-b border-gray-200 dark:border-neutral-700">
                  <div>
                    <h2 className="text-xl font-semibold text-gray-800 dark:text-neutral-200">
                      Affectation de projet à l'intervenant
                    </h2>
                    <p className="text-sm text-gray-600 dark:text-neutral-400">
                    </p>
                  </div>
                  <div>
                    <div className="inline-flex gap-x-2">
                    </div>
                  </div>
                </div>

                <div className="bg-white p-4 sm:p-7 dark:bg-neutral-800">
                  {(validationErrors.length > 0 || error) && (
                    <Alert
                      type="error"
                      title={error}
                      details={validationErrors}
                    />
                  )}
                  {successful && (
                    <Alert
                      type="success"
                      title="Le projet a bien été affecté"
                      showLinks={true}
                      linkText='Aller à la page des affectations'
                      toLink='/affectations'
                    />
                  )}
                  <AffectationForm
                    projets={projets}
                    users={users}
                    competences={competences}
                    handleFieldChange={handleFieldChange}
                    handleSubmit={handleOnSubmit}
                    handleClearForm={handleClearForm}
                    affectation={_affectation_}
                  />
                </div>
                <div className="px-6 py-4 grid gap-3 md:flex md:justify-between md:items-center border-t border-gray-200 dark:border-neutral-700">
                  <div></div>
                  <div>
                    <div className="inline-flex gap-x-2">
                      <button type="button"
                        className="py-2 px-3 inline-flex items-center gap-x-2 text-sm font-semibold rounded-lg border border-transparent bg-red-600 text-white hover:bg-red-700 disabled:opacity-50 disabled:pointer-events-none"
                        onClick={handleOnSubmit}>
                        Enregistrer
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddAffectationPage;
