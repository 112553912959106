
type StatCardProps = {
  title: string,
  tooltip?: string,
  value: string | number,
  percentage?: number
};

const StatCard = ({ ...props }: StatCardProps) => {

  return (
    <div className="flex flex-col bg-white border shadow-sm rounded-xl dark:bg-neutral-800 dark:border-neutral-700">
      <div className="p-4 md:p-5">
        <div className="flex items-center gap-x-2">
          <p className="text-base font-bold tracking-wide text-gray-500 dark:text-neutral-500">
            {props.title}
          </p>
          {props.tooltip && (
            <div className="hs-tooltip">
              <div className="hs-tooltip-toggle">
                <svg className="flex-shrink-0 size-4 text-gray-500 dark:text-neutral-500" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" stroke-linecap="round" stroke-linejoin="round"><circle cx="12" cy="12" r="10" /><path d="M9.09 9a3 3 0 0 1 5.83 1c0 2-3 3-3 3" /><path d="M12 17h.01" /></svg>
                <span className="hs-tooltip-content hs-tooltip-shown:opacity-100 hs-tooltip-shown:visible opacity-0 transition-opacity inline-block absolute invisible z-10 py-1 px-2 bg-gray-900 text-xs font-medium text-white rounded shadow-sm dark:bg-neutral-700" role="tooltip">
                  {props.tooltip}
                </span>
              </div>
            </div>
          )}
        </div>

        <div className="mt-1 flex items-center gap-x-2">
          <h3 className="text-2xl font-semibold text-gray-800 dark:text-neutral-200">
            {props.value}
          </h3>
          {props.percentage && (
            <span className="inline-flex items-center gap-x-1 py-0.5 px-2 rounded-full bg-gray-100 text-gray-900 dark:bg-gray-800 dark:text-gray-100">
              {/* <svg className="inline-block size-4 self-center" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" stroke-linecap="round" stroke-linejoin="round"><polyline points="22 7 13.5 15.5 8.5 10.5 2 17" /><polyline points="16 7 22 7 22 13" /></svg> */}
              <span className="inline-block text-xs font-medium">
                {props.percentage + "%"}
              </span>
            </span>
          )}
        </div>
      </div>
    </div>
  );
};

export default StatCard;
