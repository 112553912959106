//import NavbarLayout from '~/components/navbar/NavbarLayout';
import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuthContext } from '../../contexts/AuthContextProvider';
import UserForm from '../../components/user/UserForm';
import { StatutUserModel, UserModel, UserProfile } from '../../data/models';
import { useFetch } from '../../hooks/useFetch';
import env from '../../utils/env';
import ErrorPage from '../ErrorPage';
import Editable from "../../components/Editable";
import Alert from "../../components/Alert";

const AddUserPage = () => {

  const navigate = useNavigate();

  const [_user, setUser] = useState<UserModel>({} as UserModel);

  const [selectedProfiles, setSelectedProfiles] = useState<string[]>([]);

  const [errorMsg, setErrorMsg] = useState('');
  const [validationErrors, setValidationErrors] = useState<string[]>([]);
  const [successful, setSuccessful] = useState<boolean>(false);

  const postUserConfig = {
    method: 'POST',
    payload: _user
  } as const;

  const { error: postUserError, data: postUserResp, sendRequest: postUser } = useFetch<UserModel>(
    `${env.API_URL}/api/users`,
    postUserConfig
  );

  // Open new created user
  useEffect(() => {
    if (postUserResp) {
      setSuccessful(true);
      //navigate('/users');
    }
    if (postUserError) {
      setErrorMsg(postUserError);
    }
  }, [postUserResp, postUserError]);

  const { data: userProfiles, error: loadUserProfilesError } = useFetch<UserProfile[]>(
    `${env.API_URL}/api/refs/profiles`
  );
  const { data: statuts, error: loadUserStatutsError } = useFetch<StatutUserModel[]>(
    `${env.API_URL}/api/refs/user/statuts`
  );

  useEffect(() => {
    if (loadUserProfilesError) { setErrorMsg(loadUserProfilesError); }
    if (loadUserStatutsError) { setErrorMsg(loadUserStatutsError); }
  }, [loadUserProfilesError, loadUserStatutsError]);

  const handleOnSubmit = (e: React.MouseEvent) => {
    e.preventDefault();

    let errorMsg = 'Merci de bien vouloir saisir les champs suivants :\n';
    let errors = [];

    let value = `${_user.email}`.trim();
    if (!value || value.length <= 0) {
      errors.push("L'adresse Email est obligatoire");
    }

    value = `${_user.firstName} ${_user.lastName}`.trim();
    if (!value || value.length <= 0) {
      errors.push("Au moins le prénom ou le nom doit être saisi");
    }

    if (errors.length == 0) {
      postUser();
    } else {
      setErrorMsg(errorMsg);
      setValidationErrors(errors);
    }
  };

  const handleFieldChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value, type } = e.target;
    let oldUser = { ..._user };
    if (type === 'checkbox' && name.startsWith('profile-')) {
      var profile = name.replace(/profile-/gi, "");
      var index = selectedProfiles.indexOf(profile);
      if ('checked' in e.target && !e.target.checked && index >= 0) {
        selectedProfiles.splice(index, 1);
      }
      if ('checked' in e.target && e.target.checked && index < 0) {
        selectedProfiles.push(profile);
      }
      oldUser.profiles = selectedProfiles;
      setUser(prevUser => ({
        ...oldUser
      }));
    }
    else if (name.startsWith('statut-user-')) {
      let statutVal = name.replace(/statut-user-/gi, "");
      setUser(u => ({
        ...u,
        statut: statutVal
      }))
    }
    if (type === 'text' || type === 'email' || type === 'textarea') {
      setUser(prevUser => ({
        ...prevUser,
        [name]: value
      }));
    }

  };

  const handleClearForm = (e: React.MouseEvent) => {
    e.preventDefault();
    setUser(prevUser => ({
      ...prevUser,
      firstName: '',
      lastName: '',
      email: '',
      notes: '',
      profiles: []
    }));
    setSelectedProfiles([]);
    setValidationErrors([]);
    setSuccessful(false);
    setErrorMsg('');
  }

  return (
    <div className="w-full lg:ps-64">
      <div className="p-4 sm:p-6 space-y-4 sm:space-y-6">

        <div className="flex flex-col">
          <div className="-m-1.5 overflow-x-auto">
            <div className="p-1.5 min-w-full inline-block align-middle">
              <div className="bg-white border border-gray-200 rounded-xl shadow-sm overflow-hidden dark:bg-neutral-800 dark:border-neutral-700">

                <div className="px-6 py-4 grid gap-3 md:flex md:justify-between md:items-center border-b border-gray-200 dark:border-neutral-700">
                  <div>
                    <h2 className="text-xl font-semibold text-gray-800 dark:text-neutral-200">
                      Création d'un nouvel utilisateur
                    </h2>
                    <p className="text-sm text-gray-600 dark:text-neutral-400">
                      Saisir le profil utilisateur.
                    </p>
                  </div>
                  <div>
                    <div className="inline-flex gap-x-2">
                    </div>
                  </div>
                </div>

                <div className="bg-white p-4 sm:p-7 dark:bg-neutral-800">
                  {(validationErrors.length > 0 || errorMsg) && (
                    <Alert
                      type="error"
                      title={errorMsg}
                      details={validationErrors}
                      showLinks={successful}
                    />
                  )}
                  {successful && (
                    <Alert
                      type="success"
                      title="L'utilisateur a bien été enregistré"
                      showLinks={true}
                      linkText='Aller à la page des utilisateurs'
                      toLink='/users'
                    />
                  )}
                  <UserForm
                    userProfiles={userProfiles}
                    statuts={statuts}
                    handleFieldChange={handleFieldChange}
                    handleSubmit={handleOnSubmit}
                    handleClearForm={handleClearForm}
                    user={_user}
                    canUpdatePassword={false}
                    disableEmail={false}
                  />
                </div>
                <div className="px-6 py-4 grid gap-3 md:flex md:justify-between md:items-center border-t border-gray-200 dark:border-neutral-700">
                  <div></div>
                  <div>
                    <div className="inline-flex gap-x-2">
                      <button type="button"
                        onClick={handleClearForm}
                        className="py-2 px-3 inline-flex items-center gap-x-2 text-sm font-medium rounded-lg border border-gray-200 bg-white text-gray-800 shadow-sm hover:bg-gray-50 disabled:opacity-50 disabled:pointer-events-none dark:bg-neutral-800 dark:border-neutral-700 dark:text-white dark:hover:bg-neutral-800">
                        Effacer
                      </button>
                      <button type="button"
                        className="py-2 px-3 inline-flex items-center gap-x-2 text-sm font-semibold rounded-lg border border-transparent bg-red-600 text-white hover:bg-red-700 disabled:opacity-50 disabled:pointer-events-none"
                        onClick={handleOnSubmit}>
                        Enregistrer
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddUserPage;
