import { Link } from 'react-router-dom';
import WarningAlertIcon from '../assets/alertWarning.svg';
import InfoAlertIcon from '../assets/alertInfo.svg';
import DangerAlertIcon from '../assets/alertDanger.svg';
import SuccessAlertIcon from '../assets/alertSuccess.svg';

type AlertProps = {
    type: string,
    title: string,
    description?: string,
    details?: string[] | null,
    showIcon?: boolean,
    showLinks?: boolean,
    linkText?: string,
    toLink?: string
};

const Alert = (props: AlertProps) => {

    const alertIcon = (type: string) => {
        switch (type) {
            case 'warning':
                return <WarningAlertIcon />;
            case 'info':
                return <InfoAlertIcon />;
            case 'error':
                return <DangerAlertIcon />;
            case 'success':
                return <SuccessAlertIcon />;
            default:
        }
    }

    const alertColor = (type: string) => {
        switch (type) {
            case 'warning':
                return 'yellow';
            case 'info':
                return 'blue';
            case 'error':
                return 'red';
            case 'success':
                return 'teal';
            default:
                return 'gray';
        }
    }

    const color = alertColor(props.type);

    const alertClassName = (type: string) => {
        //let color = alertColor(type);
        return `bg-${color}-100 border border-${color}-200 text-sm text-${color}-800 rounded-lg p-4 dark:bg-${color}-800/10 dark:border-${color}-900 dark:text-${color}-500`;
    }

    return (
        <div className={"mb-8 " + alertClassName(props.type)}
            role="alert">
            <div className="flex">
                {props.showIcon && (
                    <div className="flex-shrink-0">
                        {props.type === 'warning' ? WarningAlertIcon : props.type === 'error' ? DangerAlertIcon : InfoAlertIcon}
                    </div>
                )}
                <div className="ms-4">
                    <h3 className="text-sm font-semibold">
                        {props.title}
                    </h3>
                    {props.description && (
                        <div className="mt-1 text-sm text-yellow-700">
                            {props.description}
                        </div>
                    )}
                    {props.details && (
                        <div className={`mt-2 text-sm text-${color}-700 dark:text-${color}-400`}>
                            <ul className="list-disc space-y-1 ps-5">
                            {
                                props.details.map((item) => {
                                    return <li>{item}</li>;
                                })
                            }
                            </ul>
                        </div>
                    )}
                    {props.showLinks && (
                        <div className="mt-4">
                            <div className="flex space-x-3">
                                <Link to={props.toLink || '#'}>
                                    <button type="button" className="inline-flex items-center gap-x-2 text-sm font-semibold rounded-lg border border-transparent text-teal-600 hover:text-teal-800 disabled:opacity-50 disabled:pointer-events-none dark:text-teal-500 dark:hover:text-teal-400">
                                        {props.linkText}
                                    </button>
                                </Link>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

export default Alert;
