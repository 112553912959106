import { Link } from 'react-router-dom';
import { formatDate } from '../../../utils/dateHelpers';
import { EtapeProjetModel } from '../../../data/models';
import { useAuthContext } from '../../../contexts/AuthContextProvider';

const dateFormat = 'DD/MM/YYYY';

const EtapeProjetTableRow = (etape: EtapeProjetModel) => {

    const { hasAuthority } = useAuthContext();

    return (
        <tr>
            <td className="size-px whitespace-nowrap">
                <div className="px-6 py-3">
                    <span className="block text-sm text-gray-500 dark:text-neutral-500">
                        {etape.ordre}
                    </span>
                </div>
            </td>
            <td className="size-px whitespace-nowrap">
                <div className="px-3 py-3">
                    <span className="block text-sm text-gray-500 dark:text-neutral-500">
                        {etape.description}
                    </span>
                </div>
            </td>
            <td className="h-px w-72 whitespace-nowrap">
                <div className="px-3 py-3">
                    <span className="block text-sm font-semibold text-gray-800 dark:text-neutral-200">
                        {etape.typeIntervention?.nom}
                    </span>
                    {/* <span className="block text-sm text-gray-500 dark:text-neutral-500">Human resources</span> */}
                </div>
            </td>

            <td className="size-px whitespace-nowrap">
                <div className="px-3 py-3">
                    <span className="text-sm text-gray-500 dark:text-neutral-500">
                        {formatDate(etape.debut, dateFormat)}
                    </span>
                </div>
            </td>
            <td className="size-px whitespace-nowrap">
                <div className="px-3 py-3">
                    <span className="text-sm text-gray-500 dark:text-neutral-500">
                        {formatDate(etape.fin, dateFormat)}
                    </span>
                </div>
            </td>

            <td className="size-px whitespace-nowrap">
                <div className="px-6 py-3 flex items-center gap-x-2">
                    {hasAuthority('EDIT_ETAPE_PROJET') && (
                        <Link to={`/projets/${etape.projet}/etapes/${etape.sid}`}
                            className="inline-flex items-center gap-x-1 text-sm text-red-600 decoration-2 hover:underline font-medium dark:text-red-500">
                            Editer
                        </Link>
                    )}
                    {hasAuthority('VIEW_AVANCEMENTS_ETAPE_PROJET') && (
                        <Link to={`/projets/${etape.projet}/etapes/${etape.sid}/avancements`}
                            className="inline-flex items-center gap-x-1 text-sm text-red-600 decoration-2 hover:underline font-medium dark:text-red-500">
                            Avancements
                        </Link>
                    )}
                </div>
                
            </td>
        </tr>
    );
};

export default EtapeProjetTableRow;
