import { useState, useCallback } from 'react';
import { useAuthContext } from '../contexts/AuthContextProvider';

type ErrorResponse = {
  timestamp: string;
  status: number;
  error: string;
  message: string;
};

/**
 * Utility for downloading files in new tab.
 * @param url Url to fetch
 * @param config Config, e.g. Method
 * @returns void, api response is in hook
 */
export const useDownload = (url: string) => {
  const [file, setFile] = useState<Blob | null>(null);
  const [filename, setFilename] = useState<string>('document');
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const [statusCode, setStatusCode] = useState<number | null>(null);

  const { authState } = useAuthContext();
  const authorizationHeader = authState.token;

  const download = useCallback(async () => {
    try {
      setLoading(true);
      setError('');
      setFile(null);
      setFilename('document');

      const headers: HeadersInit = {};
      const method = 'GET';

      if (authorizationHeader) {
        headers['authorization'] = `Bearer ${authorizationHeader}`;
      }

      const options: RequestInit = {
        method,
        headers
      };

      const response = await fetch(url, options);
      setStatusCode(response.status);

      if (!response?.ok) {
        const res = (await response.json()) as ErrorResponse;
        setError(res.message);
        return;
      }

      let fname = filename; 
      const contentDisposition = response.headers.get('Content-Disposition');
      if (contentDisposition) {
        fname = contentDisposition.split('filename=')[1];
        setFilename(fname);
      }

      //console.log(filename.replace(/['"]+/g, ''));
      response.blob().then(blob => {
        setFile(blob);
        // Create blob link to download
        const linkHref = window.URL.createObjectURL(
          new Blob([blob]),
        );
        const link = document.createElement('a');
        link.href = linkHref;
        link.target = "_blank";
        link.setAttribute('download', fname);
        // Append to html link element page
        document.body.appendChild(link);
        // Start download
        link.click();
        // Clean up and remove the link
        link.parentNode?.removeChild(link);
      });

    } catch (error) {
      setError('Internal Server Error');
    } finally {
      setLoading(false);
    }
  }, [authorizationHeader, url]);

  const nullError = () => setError('');
  const nullFile = () => setFile(null);

  return {
    file,
    loading,
    error,
    statusCode,
    download,
    nullError,
    nullFile,
    filename
  };

};