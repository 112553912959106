import { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { useFetch } from '../../../hooks/useFetch';
import env from '../../../utils/env';
import EmptyState from '../../../components/EmptyState';
import { FaitMarquantProjetModel, ProjetModel } from '../../../data/models';
import FaitMarquantTableRow from '../../../components/projet/fait/FaitMarquantTableRow';
import { useAuthContext } from '../../../contexts/AuthContextProvider';
import { useDownload } from '../../../hooks/useDownload';

const FaitsMarquantsPage = () => {
  const [error, setError] = useState<string>('');
  const [pieceJointeToDownload, setPieceJointeToDownload] = useState<string|null>(null);
  const { hasAuthority } = useAuthContext();
  const { sid: projetId } = useParams() || "";
  if (!projetId || !projetId.trim()) {
    setError("L'identifiant projet fourni est erroné!");
  }

  const { data: projet, error: loadProjetError } = useFetch<ProjetModel>(
    `${env.API_URL}/api/projets/${projetId}`
  );

  const { data: faits, error: loadFaitsError } = useFetch<FaitMarquantProjetModel[]>(
    `${env.API_URL}/api/projets/${projetId}/faits`
  );

  useEffect(() => {
    if (loadFaitsError) {
      setError(loadFaitsError);
    }
  }, [loadFaitsError]);

  const { download, file, error: downloadError, filename } = useDownload(
    `${env.API_URL}/api/documents/${pieceJointeToDownload}`
  );

  useEffect(() => {
    if (downloadError) {
      setError(downloadError);
    }
  }, [downloadError]);

  useEffect(() => {
    if (filename) {
      setPieceJointeToDownload(null);
    }
  }, [filename]);

  useEffect(() => {
    if (pieceJointeToDownload) {
      try {
        download();
      } catch (e) {
        setError("Une erreur est survenue lors de téléchargement de la pièce jointe");
      }
    }
  }, [pieceJointeToDownload]);

  const handleDownloadPieceJointe = (pieceJointeId: string, e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault();
    if (pieceJointeId) {
      setPieceJointeToDownload(pieceJointeId);
    }
  }

  return (

    <div className="w-full lg:ps-64">
      <div className="p-4 sm:p-6 space-y-4 sm:space-y-6">

        <div className="flex flex-col">
          <div className="-m-1.5">
            <div className="p-1.5 w-full inline-block align-middle">
              <div className="bg-white border border-gray-200 rounded-xl shadow-sm overflow-hidden dark:bg-neutral-800 dark:border-neutral-700">

                <div className="px-6 py-4 grid gap-3 md:flex md:justify-between md:items-center border-b border-gray-200 dark:border-neutral-700">
                  <div>
                    <h2 className="text-xl font-semibold text-gray-800 dark:text-neutral-200">
                      Faits marquants du projet {projet?.greza + '-' + projet?.etablissement?.nom}
                    </h2>
                    <p className="text-sm text-gray-600 dark:text-neutral-400">
                      Créer un fait marquant du projet.
                    </p>
                  </div>

                  <div>
                    <div className="inline-flex gap-x-2">
                      { hasAuthority('CREATE_FAIT_MARQUANT_PROJET') && (
                        <Link to={`/projets/${projetId}/faits/add`}
                          className="py-2 px-3 inline-flex items-center gap-x-2 text-sm font-semibold rounded-lg border border-transparent bg-red-600 text-white hover:bg-red-700 disabled:opacity-50 disabled:pointer-events-none">
                          <svg className="flex-shrink-0 size-4" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><path d="M5 12h14" /><path d="M12 5v14" /></svg>
                          Créer un fait marquant
                        </Link>
                      )}
                    </div>
                  </div>
                </div>

                {!faits || faits.length == 0
                  ? (
                    <EmptyState
                      title="Aucun fait marquant n'a été trouvé"
                      description="Vous pouvez commencer par en créer" />)
                  : (
                    <table className="w-full table-auto divide-y divide-gray-200 dark:divide-neutral-700">
                      <thead className="bg-gray-50 dark:bg-neutral-800">
                        <tr>

                          <th scope="col" className="px-6 py-3 text-start">
                            <div className="flex items-center gap-x-2">
                              <span className="text-xs font-semibold uppercase tracking-wide text-gray-800 dark:text-neutral-200">
                                Date
                              </span>
                            </div>
                          </th>

                          <th scope="col" className="px-3 py-3 text-start">
                            <div className="flex items-center gap-x-2">
                              <span className="text-xs font-semibold uppercase tracking-wide text-gray-800 dark:text-neutral-200">
                                Responsable
                              </span>
                            </div>
                          </th>
                          <th scope="col" className="px-3 py-3 text-start">
                            <div className="flex items-center gap-x-2">
                              <span className="text-xs font-semibold uppercase tracking-wide text-gray-800 dark:text-neutral-200">
                                Localisation
                              </span>
                            </div>
                          </th>
                          <th scope="col" className="px-3 py-3 text-start">
                            <div className="flex items-center gap-x-2">
                              <span className="text-xs font-semibold uppercase tracking-wide text-gray-800 dark:text-neutral-200">
                                Description
                              </span>
                            </div>
                          </th>
                          <th scope="col" className="px-3 py-3 text-start">
                            <div className="flex items-center gap-x-2">
                              <span className="text-xs font-semibold uppercase tracking-wide text-gray-800 dark:text-neutral-200">
                                Pièce jointe
                              </span>
                            </div>
                          </th>
                        </tr>
                      </thead>

                      <tbody className="divide-y divide-gray-200 dark:divide-neutral-700">
                        {faits?.map((fait) => (
                          <FaitMarquantTableRow 
                            fait={fait} 
                            handleDownloadPieceJointeClick={handleDownloadPieceJointe} />
                        ))}
                      </tbody>

                    </table>

                  )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FaitsMarquantsPage;