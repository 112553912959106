import { Stats } from '../../data/models';
import EmptyState from '../EmptyState';

type ThisProps = {
    stats: Stats;
};

const ProjetParProvinceStats = (props: ThisProps) => {

    return (
        < div className="flex flex-col">
            <div className="-m-1.5 overflow-x-auto">
                <div className="p-1.5 min-w-full inline-block align-middle">
                    <div className="bg-white border border-gray-200 rounded-xl shadow-sm overflow-hidden dark:bg-neutral-800 dark:border-neutral-700">

                        {/* <div className="px-6 py-4 grid gap-3 md:flex md:justify-between md:items-center border-b border-gray-200 dark:border-neutral-700">
                            <div>
                                <h2 className="text-xl font-semibold text-gray-800 dark:text-neutral-200">
                                    Projets par région
                                </h2>
                                <p className="text-sm text-gray-600 dark:text-neutral-400">
                                    Répartition des projets par région.
                                </p>
                            </div>

                            <div>
                                <div className="inline-flex gap-x-2">
                                </div>
                            </div>
                        </div> */}

                        <div className="max-w-[85rem] px-4 py-6 sm:px-6 lg:px-6 lg:py-10 mx-auto">
                            {!props.stats?.repartition || props.stats.repartition.length === 0
                                ? (
                                    <EmptyState
                                        title="Aucune statistique à afficher"
                                        description="Vérifiez que la base de données a bien été alimentée" />)
                                : (
                                    <div className="grid gap-6 grid-cols-2 sm:gap-12 lg:grid-cols-4 lg:gap-4">
                                        {props.stats.repartition.map((partition, index) => (
                                            <div>
                                                <h4 className="text-2xl text-gray-800 dark:text-neutral-200">{partition.sujet['nom']}</h4>
                                                <p className="mt-2 text-2xl font-bold text-red-600">
                                                    {partition.valeur}
                                                    <span className="ms-3 inline-flex align-middle items-center gap-x-1 bg-gray-200 font-medium text-gray-800 text-base leading-4 rounded-full py-1.5 px-4 dark:bg-neutral-800 dark:text-neutral-300">
                                                        {partition.pourcentage+"%"}
                                                    </span>
                                                </p>
                                                <p className="mt-1 text-gray-500 dark:text-neutral-500"></p>
                                            </div>
                                        ))}
                                    </div>
                                )}
                        </div>
                    </div>
                </div>
            </div>
        </div >
    );
};

export default ProjetParProvinceStats;
