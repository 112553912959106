import { EtapeProjetModel, InterventionModel, ProjetModel } from '../../../data/models';
import DatePicker from "react-datepicker";

type EtapeProjetFormProps = {
    handleFieldChange: (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => void;
    handleSubmit: (e: React.MouseEvent) => void;
    handleClearForm?: (e: React.MouseEvent) => void | null;
    interventions: InterventionModel[] | null;
    setDebutEtape: (d: Date) => void;
    setFinEtape: (d: Date) => void;
    etape: EtapeProjetModel;
};

const EtapeProjetForm = (props: EtapeProjetFormProps) => {

    const currentEtape = props.etape;

    return (
        <form>
            <div className="grid sm:grid-cols-12 gap-2 sm:gap-6">

                <div className="sm:col-span-3">
                    <label htmlFor="af-etape-intervention" className="inline-block text-sm text-gray-800 mt-2.5 dark:text-neutral-200">
                        Type intervention
                    </label>
                </div>
                <div className="sm:col-span-9">
                    <select name="intervention" id="af-etape-intervention"
                        className="py-2 px-3 pe-9 block w-full border-gray-200 shadow-sm text-sm rounded-lg focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none dark:bg-neutral-900 dark:border-neutral-700 dark:text-neutral-400 dark:placeholder-neutral-500 dark:focus:ring-neutral-600"
                        value={currentEtape.typeIntervention?.sid}
                        onChange={props.handleFieldChange}>
                        <option value="">Choisir le type d'intervention...</option>
                        {props.interventions?.map(({ sid, nom }) => (
                            <option value={sid} selected={currentEtape.typeIntervention?.sid ? currentEtape.typeIntervention?.sid === sid : false}>
                                {nom}
                            </option>
                        ))}
                    </select>
                </div>

                <div className="sm:col-span-3">
                    <label htmlFor="af-etape-ordre" className="inline-block text-sm text-gray-800 mt-2.5 dark:text-neutral-200">
                        Ordre d'exécution
                    </label>
                </div>
                <div className="sm:col-span-9">
                    <input type="text" id="af-etape-ordre"
                        name="ordre"
                        className="py-2 px-3 pe-11 block w-full border-gray-200 shadow-sm text-sm rounded-lg focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none dark:bg-neutral-900 dark:border-neutral-700 dark:text-neutral-400 dark:placeholder-neutral-500 dark:focus:ring-neutral-600"
                        placeholder="Ordre d'exécution de la phase projet"
                        value={currentEtape.ordre}
                        onChange={props.handleFieldChange}
                    />
                </div>

                <div className="sm:col-span-3">
                    <label htmlFor="af-etape-description" className="inline-block text-sm text-gray-800 mt-2.5 dark:text-neutral-200">
                        Description
                    </label>
                </div>
                <div className="sm:col-span-9">
                    <textarea id="af-etape-description" name="description"
                        className="py-2 px-3 block w-full border-gray-200 rounded-lg text-sm focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none dark:bg-neutral-800 dark:border-neutral-700 dark:text-neutral-400 dark:placeholder-neutral-500 dark:focus:ring-neutral-600"
                        rows={6} placeholder="Saisir la description ici..."
                        value={currentEtape.description}
                        onChange={props.handleFieldChange} />
                </div>

                <div className="sm:col-span-3">
                    <label htmlFor="af-etape-debut" className="inline-block text-sm text-gray-800 mt-2.5 dark:text-neutral-200">
                        Début
                    </label>
                </div>
                <div className="sm:col-span-9">
                    <DatePicker id="af-etape-debut"
                        className="py-2 px-3 pe-11 block w-full border-gray-200 shadow-sm text-sm rounded-lg focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none dark:bg-neutral-900 dark:border-neutral-700 dark:text-neutral-400 dark:placeholder-neutral-500 dark:focus:ring-neutral-600"
                        selected={currentEtape.debut}
                        onChange={(date: Date) => props.setDebutEtape(date)}
                        dateFormat="dd/MM/yyyy"
                    />
                </div>

                <div className="sm:col-span-3">
                    <label htmlFor="af-etape-fin" className="inline-block text-sm text-gray-800 mt-2.5 dark:text-neutral-200">
                        Fin
                    </label>
                </div>
                <div className="sm:col-span-9">
                    <DatePicker id="af-etape-fin"
                        className="py-2 px-3 pe-11 block w-full border-gray-200 shadow-sm text-sm rounded-lg focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none dark:bg-neutral-900 dark:border-neutral-700 dark:text-neutral-400 dark:placeholder-neutral-500 dark:focus:ring-neutral-600"
                        selected={currentEtape.fin}
                        onChange={(date: Date) => props.setFinEtape(date)}
                        dateFormat="dd/MM/yyyy"
                    />
                </div>
            </div>
        </form>
    );
};

export default EtapeProjetForm;
