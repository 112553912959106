//import NavbarLayout from '~/components/navbar/NavbarLayout';
import { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import EtabliForm from '../../components/etablissement/EtabliForm';
import { ProvinceModel, CommuneModel, EtablissementModel, CategorieEtablissementModel } from '../../data/models';
import { useFetch } from '../../hooks/useFetch';
import env from '../../utils/env';
import Alert from "../../components/Alert";

const EditEtablissementPage = () => {

  const navigate = useNavigate();

  const [_etabli, setEtablissment] = useState<EtablissementModel>({} as EtablissementModel);

  const [communes, setCommunes] = useState<CommuneModel[]>([]);
  const [provinceCode, setProvinceCode] = useState('');
  const [error, setError] = useState('');
  const [validationErrors, setValidationErrors] = useState<string[]>([]);
  const [successful, setSuccessful] = useState<boolean>(false);

  const { sid: etabliId } = useParams() || "";
  if (!etabliId || !etabliId.trim()) {
    setError("L'identifiant établissement fourni est invalide!");
  }

  const { data: loadEtabliResp, error: loadEtabliError } = useFetch<EtablissementModel>(
    `${env.API_URL}/api/etablissements/${etabliId}`
  );

  useEffect(() => {
    if (loadEtabliResp) {
      setEtablissment(loadEtabliResp);
      setProvinceCode(loadEtabliResp.province?.code || '');
    }
    if (loadEtabliError) {
      setError(loadEtabliError);
    }
  }, [loadEtabliResp]);

  const { data: provinces, error: loadProvincesError } = useFetch<ProvinceModel[]>(
    `${env.API_URL}/api/refs/provinces`
  );
  const { data: loadCommunesResp, error: loadCommunesError, sendRequest: loadCommunes } = useFetch<CommuneModel[]>(
    `${env.API_URL}/api/refs/provinces/${provinceCode}/communes`, { method: "GET" }
  );
  const { data: categories, error: loadCategoriesEtabliError } = useFetch<CategorieEtablissementModel[]>(
    `${env.API_URL}/api/refs/etablissement/categories`
  );

  useEffect(() => {
    if (loadProvincesError) { setError(loadProvincesError); }
    if (loadCategoriesEtabliError) { setError(loadCategoriesEtabliError); }
  }, [loadProvincesError, loadCategoriesEtabliError]);

  useEffect(() => {
    if (provinceCode) {
      loadCommunes();
    }
  }, [provinceCode]);

  useEffect(() => {
    if (loadCommunesResp) {
      setCommunes(loadCommunesResp);
    }
    if (!loadCommunesResp && loadCommunesError) {
      setError(loadCommunesError);
    }
  }, [loadCommunesResp]);

  // Mise à jour de l'établissement
  const { error: putEtabliError, data: putEtabliResp, sendRequest: putEtabli } = useFetch<EtablissementModel>(
    `${env.API_URL}/api/etablissements/${etabliId}`,
    {
      method: 'PUT',
      payload: _etabli
    }
  );

  // Check if establishment has been updated
  useEffect(() => {
    if (putEtabliResp) {
      setSuccessful(true);
    }
    if (putEtabliError) {
      setError(putEtabliError);
    }
  }, [putEtabliResp, putEtabliError]);

  const handleOnSubmit = (e: React.MouseEvent) => {
    e.preventDefault();

    let errorMsg = 'Merci de bien vouloir saisir les champs suivants :\n';
    let errors = [];

    if (!_etabli.nom || !_etabli.nom.trim()) {
      errors.push("Le nom de l'établissement est obligatoire");
    }

    if (errors.length == 0) {
      putEtabli();
    } else {
      setError(errorMsg);
      setValidationErrors(errors);
    }
  };

  const handleFieldChange = (e: React.ChangeEvent<HTMLInputElement
    | HTMLTextAreaElement | HTMLSelectElement>) => {
    const { name, value, type } = e.target;

    if (type.startsWith('select') && name.startsWith('province')) {
      setCommunes([]);
      setProvinceCode(value);

      setEtablissment(prevEtabli => ({
        ...prevEtabli,
        commune: {} as CommuneModel
      }));

      setEtablissment(prevEtabli => ({
        ...prevEtabli,
        province: {
          ...prevEtabli.province,
          code: value
        } as ProvinceModel
      }));
    }
    if (type.startsWith('select') && name.startsWith('commune')) {
      if (!value) {
        setEtablissment(prevEtabli => ({
          ...prevEtabli,
          commune: {} as CommuneModel
        }));

      } else {
        setEtablissment(prevEtabli => ({
          ...prevEtabli,
          commune: {
            ...prevEtabli.commune,
            code: value
          } as CommuneModel
        }));
      }
    }
    if (name.startsWith('categorie-etabli-')) {
      let categorieValue = name.replace(/categorie-etabli-/gi, "");
      setEtablissment(e => ({
        ...e,
        categorie: {
          ...e.categorie,
          nom: categorieValue
        } as CategorieEtablissementModel
      }))
    }
    if (type === 'text') {
      setEtablissment(prevEtabli => ({
        ...prevEtabli,
        [name]: value || undefined
      }));
    }
  };

  return (
    <div className="w-full lg:ps-64">
      <div className="p-4 sm:p-6 space-y-4 sm:space-y-6">

        <div className="flex flex-col">
          <div className="-m-1.5 overflow-x-auto">
            <div className="p-1.5 min-w-full inline-block align-middle">
              <div className="bg-white border border-gray-200 rounded-xl shadow-sm overflow-hidden dark:bg-neutral-800 dark:border-neutral-700">

                <div className="px-6 py-4 grid gap-3 md:flex md:justify-between md:items-center border-b border-gray-200 dark:border-neutral-700">
                  <div>
                    <h2 className="text-xl font-semibold text-gray-800 dark:text-neutral-200">
                      Etablissement {_etabli.nom}
                    </h2>
                    <p className="text-sm text-gray-600 dark:text-neutral-400">
                      Editer la fiche établissement.
                    </p>
                  </div>
                  <div>
                    <div className="inline-flex gap-x-2">
                    </div>
                  </div>
                </div>


                <div className="bg-white p-4 sm:p-7 dark:bg-neutral-800">
                  {(validationErrors.length > 0 || error) && (
                    <Alert
                      type="error"
                      title={error}
                      details={validationErrors}
                    />
                  )}
                  {successful && (
                    <Alert
                      type="success"
                      title="L'établissement a bien été mis à jour"
                      showLinks={true}
                      linkText='Aller à la page des établissements'
                      toLink='/etablissements'
                    />
                  )}
                  <EtabliForm
                    provinces={provinces}
                    communes={communes}
                    categories={categories}
                    handleFieldChange={handleFieldChange}
                    handleSubmit={handleOnSubmit}
                    etabli={_etabli}
                  />
                  <div className="my-6 w-4/5 mx-auto flex justify-center">
                    {_etabli.latitude && _etabli.longitude && (
                      <img className="mx-auto"
                        src={`https://api.journey.tech/v1/static-map?key=pk_e3ad4c4a4c514815ba96744d939eeb29&width=900&height=800&lat=${_etabli.latitude}&lng=${_etabli.longitude}&zoom=10&style=light&markers=lat%3A${_etabli.latitude}%2Clng%3A${_etabli.longitude}`} />
                    )}
                  </div>
                </div>
                <div className="px-6 py-4 grid gap-3 md:flex md:justify-between md:items-center border-t border-gray-200 dark:border-neutral-700">
                  <div></div>
                  <div>
                    <div className="inline-flex gap-x-2">
                      <button type="button"
                        className="py-2 px-3 inline-flex items-center gap-x-2 text-sm font-semibold rounded-lg border border-transparent bg-red-600 text-white hover:bg-red-700 disabled:opacity-50 disabled:pointer-events-none"
                        onClick={handleOnSubmit}>
                        Enregistrer
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditEtablissementPage;
