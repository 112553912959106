import { Link } from 'react-router-dom';
import { EtablissementModel } from '../../data/models';
import { useAuthContext } from '../../contexts/AuthContextProvider';

const dateFormat = 'DD/MM/YYYY';

const EtabTableRow = (etabli: EtablissementModel) => {

    const { hasAuthority } = useAuthContext();

    return (
        <tr>
            <td className="size-px whitespace-nowrap">
                <div className="ps-6 py-3">
                    <label htmlFor="hs-at-with-checkboxes-1" className="flex">
                        <input type="checkbox"
                            className="shrink-0 border-gray-300 rounded text-red-600 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none dark:bg-neutral-800 dark:border-neutral-600 dark:checked:bg-red-500 dark:checked:border-blue-500 dark:focus:ring-offset-gray-800"
                            id="hs-at-with-checkboxes-1" />
                        <span className="sr-only">Checkbox</span>
                    </label>
                </div>
            </td>
            <td className="size-px whitespace-nowrap">
                <div className="px-6 py-3">
                    {hasAuthority('EDIT_ETABLISSEMENT') ?
                        (
                            <Link to={`/etablissements/${etabli.sid}`}>
                                <button type="button" className="block" data-hs-overlay="#hs-bg-gray-on-hover-cards">
                                    <span className="block text-sm font-semibold text-red-600 dark:text-red-500 hover:underline">
                                        {etabli.nom.toUpperCase()}
                                    </span>
                                </button>
                            </Link>
                        ) : (
                            <span className="block text-sm font-semibold text-red-600 dark:text-red-500 hover:underline">
                                {etabli.nom.toUpperCase()}
                            </span>
                        )}
                </div>
            </td>
            <td className="h-px w-72 whitespace-nowrap">
                <div className="px-6 py-3">
                    <span className="block text-sm font-semibold text-gray-800 dark:text-neutral-200">
                        {etabli.province?.nom}
                    </span>
                    {/* <span className="block text-sm text-gray-500 dark:text-neutral-500">Human resources</span> */}
                </div>
            </td>
            <td className="size-px whitespace-nowrap">
                <div className="px-6 py-3">
                    <span className="text-sm text-gray-500 dark:text-neutral-500">
                        {etabli.commune?.nom}
                    </span>
                </div>
            </td>
            <td className="h-px w-72 whitespace-nowrap">
                <div className="px-6 py-3">
                    <span className="text-sm text-gray-500 dark:text-neutral-500">
                        {etabli.classe}
                    </span>
                </div>
            </td>
            <td className="h-px w-72 whitespace-nowrap">
                <div className="px-6 py-3">
                    <span className="block text-sm font-semibold text-gray-800 dark:text-neutral-200">
                        {etabli.latitude}
                    </span>
                </div>
            </td>
            <td className="h-px w-72 whitespace-nowrap">
                <div className="px-6 py-3">
                    <span className="block text-sm font-semibold text-gray-800 dark:text-neutral-200">
                        {etabli.longitude}
                    </span>
                </div>
            </td>
        </tr>
    );
};

export default EtabTableRow;
