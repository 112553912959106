import { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { useFetch } from '../../../../hooks/useFetch';
import env from '../../../../utils/env';
import EmptyState from '../../../../components/EmptyState';
import { EtapeProjetModel } from '../../../../data/models';
import { AvancementEtapeProjetModel } from '../../../../data/models';
import AvancementEtapeTableRow from '../../../../components/projet/etape/avancement/AvancementEtapeTableRow';
import { useAuthContext } from '../../../../contexts/AuthContextProvider';
import { useDownload } from '../../../../hooks/useDownload';

const AvancementsEtapePage = () => {
  const [error, setError] = useState<string>('');
  const [pieceJointeToDownload, setPieceJointeToDownload] = useState<string|null>(null);
  const { hasAuthority } = useAuthContext();
  const { sid: projetId } = useParams() || "";
  if (!projetId || !projetId.trim()) {
    setError("L'identifiant projet fourni est erroné!");
  }

  const { eid: etapeId } = useParams() || "";
  if (!etapeId || !etapeId.trim()) {
    setError("L'identifiant étape fourni est erroné!");
  }

  const { data: etapeProjet, error: loadEtapeProjetError } = useFetch<EtapeProjetModel>(
    `${env.API_URL}/api/projets/${projetId}/etapes/${etapeId}`
  );

  const { data: avancements, error: loadAvancementsError } = useFetch<AvancementEtapeProjetModel[]>(
    `${env.API_URL}/api/projets/${projetId}/etapes/${etapeId}/avancements`
  );

  useEffect(() => {
    if (loadAvancementsError) {
      setError(loadAvancementsError);
    }
  }, [loadAvancementsError]);

  const { download, file, error: downloadError, filename } = useDownload(
    `${env.API_URL}/api/documents/${pieceJointeToDownload}`
  );

  useEffect(() => {
    if (downloadError) {
      setError(downloadError);
    }
  }, [downloadError]);

  useEffect(() => {
    if (filename) {
      setPieceJointeToDownload(null);
    }
  }, [filename]);

  useEffect(() => {
    if (pieceJointeToDownload) {
      try {
        download();
      } catch (e) {
        setError("Une erreur est survenue lors de téléchargement de la pièce jointe");
      }
    }
  }, [pieceJointeToDownload]);

  const handleDownloadPieceJointe = (pieceJointeId: string, e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault();
    if (pieceJointeId) {
      setPieceJointeToDownload(pieceJointeId);
    }
  }

  return (

    <div className="w-full lg:ps-64">
      <div className="p-4 sm:p-6 space-y-4 sm:space-y-6">

        <div className="flex flex-col">
          <div className="-m-1.5">
            <div className="p-1.5 w-full inline-block align-middle">
              <div className="bg-white border border-gray-200 rounded-xl shadow-sm overflow-hidden dark:bg-neutral-800 dark:border-neutral-700">

                <div className="px-6 py-4 grid gap-3 md:flex md:justify-between md:items-center border-b border-gray-200 dark:border-neutral-700">
                  <div>
                    <h2 className="text-xl font-semibold text-gray-800 dark:text-neutral-200">
                      Avancements de la phase projet {etapeProjet?.typeIntervention?.nom}
                    </h2>
                    <p className="text-sm text-gray-600 dark:text-neutral-400">
                      Ajouter, éditer l'avancement de l'étape projet.
                    </p>
                  </div>

                  <div>
                    <div className="inline-flex gap-x-2">
                      {hasAuthority('CREATE_AVANCEMENT_ETAPE_PROJET') && (
                        <Link to={`/projets/${projetId}/etapes/${etapeId}/avancements/add`}
                          className="py-2 px-3 inline-flex items-center gap-x-2 text-sm font-semibold rounded-lg border border-transparent bg-red-600 text-white hover:bg-red-700 disabled:opacity-50 disabled:pointer-events-none">
                          <svg className="flex-shrink-0 size-4" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><path d="M5 12h14" /><path d="M12 5v14" /></svg>
                          Créer un avancement
                        </Link>
                      )}
                    </div>
                  </div>
                </div>

                {!avancements || avancements.length == 0
                  ? (
                    <EmptyState
                      title="Aucun avancement n'a été trouvé"
                      description="Vous pouvez commencer par en créer" />)
                  : (
                    <table className="w-full table-auto divide-y divide-gray-200 dark:divide-neutral-700">
                      <thead className="bg-gray-50 dark:bg-neutral-800">
                        <tr>

                          <th scope="col" className="px-6 py-3 text-start">
                            <div className="flex items-center gap-x-2">
                              <span className="text-xs font-semibold uppercase tracking-wide text-gray-800 dark:text-neutral-200">
                                Création
                              </span>
                            </div>
                          </th>

                          <th scope="col" className="px-3 py-3 text-start">
                            <div className="flex items-center gap-x-2">
                              <span className="text-xs font-semibold uppercase tracking-wide text-gray-800 dark:text-neutral-200">
                                Responsable
                              </span>
                            </div>
                          </th>
                          <th scope="col" className="px-3 py-3 text-start">
                            <div className="flex items-center gap-x-2">
                              <span className="text-xs font-semibold uppercase tracking-wide text-gray-800 dark:text-neutral-200">
                                %
                              </span>
                            </div>
                          </th>
                          <th scope="col" className="px-3 py-3 text-start">
                            <div className="flex items-center gap-x-2">
                              <span className="text-xs font-semibold uppercase tracking-wide text-gray-800 dark:text-neutral-200">
                                Localisation
                              </span>
                            </div>
                          </th>
                          <th scope="col" className="px-3 py-3 text-start">
                            <div className="flex items-center gap-x-2">
                              <span className="text-xs font-semibold uppercase tracking-wide text-gray-800 dark:text-neutral-200">
                                Blocage
                              </span>
                            </div>
                          </th>
                          <th scope="col" className="px-3 py-3 text-start">
                            <div className="flex items-center gap-x-2">
                              <span className="text-xs font-semibold uppercase tracking-wide text-gray-800 dark:text-neutral-200">
                                Détails
                              </span>
                            </div>
                          </th>
                          <th scope="col" className="px-3 py-3 text-start">
                            <div className="flex items-center gap-x-2">
                              <span className="text-xs font-semibold uppercase tracking-wide text-gray-800 dark:text-neutral-200">
                                Commentaire
                              </span>
                            </div>
                          </th>
                          <th scope="col" className="px-6 py-3 text-end">
                            <div className="flex items-center gap-x-2">
                              <span className="text-xs font-semibold uppercase tracking-wide text-gray-800 dark:text-neutral-200">
                                Pièce jointe
                              </span>
                            </div>
                          </th>
                        </tr>
                      </thead>

                      <tbody className="divide-y divide-gray-200 dark:divide-neutral-700">
                        {avancements?.map((avancement) => (
                          <AvancementEtapeTableRow 
                            avancement={avancement} 
                            handleDownloadPieceJointeClick={handleDownloadPieceJointe} />
                        ))}
                      </tbody>

                    </table>

                  )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AvancementsEtapePage;