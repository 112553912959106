import { useState, useEffect } from 'react';
import { ProjetModel, EtablissementModel, FaitMarquantProjetModel } from '../../data/models';
import FicheProjet from './FicheProjet';
import env from '../../utils/env';
import { useFetch } from '../../hooks/useFetch';
import EmptyState from '../../components/EmptyState';
import { formatDate } from '../../utils/dateHelpers';
import { Link } from 'react-router-dom';
import { useAuthContext } from '../../contexts/AuthContextProvider';

const ViewFaitsMarquantsProjetPage = () => {

  const [faitsMarquants, setFaitsMarquants] = useState<FaitMarquantProjetModel[]>([]);
  const [error, setError] = useState('');

  const { hasAuthority } = useAuthContext();

  const [projet, setProjet] = useState<ProjetModel>({
    etablissement: {} as EtablissementModel
  } as ProjetModel);

  const {
    data: loadFaitsResp,
    sendRequest: loadFaits,
    error: loadFaitsError
  } = useFetch<FaitMarquantProjetModel[]>(
    `${env.API_URL}/api/projets/${projet.sid}/faits`,
    { method: 'GET' }
  );

  useEffect(() => {
    if (loadFaitsResp) { setFaitsMarquants(loadFaitsResp); }
  }, [loadFaitsResp]);

  useEffect(() => {
    if (projet.sid) {
      loadFaits();
    }
  }, [projet]);


  return (
    <FicheProjet tab="FAITS" updateProjet={setProjet}>

      {/* Liste faits marquants */}
      <div className="px-6 py-4 grid gap-3 md:flex md:justify-between md:items-center border-b border-gray-200 dark:border-neutral-700">
        <div>
          <h2 className="text-2xl font-semibold text-gray-800 dark:text-neutral-200">
            Faits marquants
          </h2>
        </div>

        <div>
          <div className="inline-flex gap-x-2">
            {hasAuthority('VIEW_FAITS_MARQUANTS_PROJET') && (
              <Link to={`/projets/${projet.sid}/faits`}
                className="py-2 px-3 inline-flex items-center gap-x-2 text-sm font-semibold rounded-lg border border-transparent bg-red-600 text-white hover:bg-red-700 disabled:opacity-50 disabled:pointer-events-none">
                Mettre à jour
              </Link>
            )}
          </div>
        </div>
      </div>

      {!faitsMarquants || faitsMarquants.length === 0
        ? (
          <EmptyState
            title="Aucun fait marquant n'a été trouvé"
            description="Vous pouvez commencer par en créer..." />)
        : (
          <div className="mt-6 mb-10 overflow-x-auto">
            <table className="min-w-full divide-y divide-gray-200 dark:divide-neutral-700">
              <thead className="bg-gray-50 dark:bg-neutral-800">
                <tr>
                  <th scope="col" className="px-6 py-3 text-start">
                    <div className="flex items-center gap-x-2">
                      <span className="text-xs font-semibold uppercase tracking-wide text-gray-800 dark:text-neutral-200">
                        Création
                      </span>
                    </div>
                  </th>

                  <th scope="col" className="px-6 py-3 text-start">
                    <div className="flex items-center gap-x-2">
                      <span className="text-xs font-semibold uppercase tracking-wide text-gray-800 dark:text-neutral-200">
                        Responsable
                      </span>
                    </div>
                  </th>

                  <th scope="col" className="px-6 py-3 text-start">
                    <div className="flex items-center gap-x-2">
                      <span className="text-xs font-semibold uppercase tracking-wide text-gray-800 dark:text-neutral-200">
                        Description
                      </span>
                    </div>
                  </th>
                  <th scope="col" className="px-6 py-3 text-end"></th>
                </tr>
              </thead>

              <tbody className="divide-y divide-gray-200 dark:divide-neutral-700">
                {faitsMarquants.map((fait) => (
                  <tr>
                    <td className="size-px whitespace-nowrap">
                      <div className="px-6 py-3">
                        <span className="block text-sm font-normal text-gray-800 dark:text-neutral-200">
                          {formatDate(fait.date, 'DD/MM/YYYY')}
                        </span>
                      </div>
                    </td>
                    <td className="h-px w-36 whitespace-nowrap">
                      <div className="px-6 py-3">
                        <span className="block text-sm font-semibold text-gray-800 dark:text-neutral-200">
                          {fait.user?.firstName + ' ' + fait.user?.lastName}
                        </span>
                      </div>
                    </td>
                    <td className="size-px whitespace-nowrap">
                      <div className="px-6 py-3">
                        <span className="text-sm text-gray-500 dark:text-neutral-500">
                          {fait.description}
                        </span>
                      </div>
                    </td>
                    <td className="size-px whitespace-nowrap">
                      <div className="px-6 py-3">
                        {fait.pieceJointe && (
                          <a href={`${env.API_URL}/api/documents/${fait.pieceJointe.sid}`}
                            target="_blank"
                            className="inline-flex items-center gap-x-1 text-sm text-red-600 decoration-2 hover:underline font-medium dark:text-red-500">
                            Pièce jointe
                          </a>
                        )}
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}
    </FicheProjet>
  );
};

export default ViewFaitsMarquantsProjetPage;
