import { Link } from 'react-router-dom';
import { DecompteProjetModel, StatutDecompteModel } from '../../../data/models';
import DatePicker from "react-datepicker";
import { yesterday } from '../../../utils/dateHelpers';
import { formatPrice } from '../../../utils/helpers';

type DecompteFormProps = {
    handleFieldChange: (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => void;
    handleSubmit: (e: React.MouseEvent) => void;
    handleClearForm?: (e: React.MouseEvent) => void | null;
    setDate: (d: Date, field: string) => void;
    statuts?: StatutDecompteModel[] | null;
    decompte: DecompteProjetModel;
};

const DecompteForm = (props: DecompteFormProps) => {

    const __ = props.decompte;

    return (
        <form>
            <div className="grid grid-cols-12 gap-2 gap-6">

                <div className="sm:col-span-3">
                    <label htmlFor="af-numero" className="inline-block text-sm text-gray-800 mt-2.5 dark:text-neutral-200">
                        Numéro
                    </label>
                </div>
                <div className="sm:col-span-9">
                    <input id="af-numero"
                        className="py-2 px-3 pe-11 block w-full border-gray-200 shadow-sm -mt-px -ms-px first:rounded-t-lg last:rounded-b-lg sm:first:rounded-s-lg sm:mt-0 sm:first:ms-0 sm:first:rounded-se-none sm:last:rounded-es-none sm:last:rounded-e-lg text-sm relative focus:z-10 focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none dark:bg-neutral-800 dark:border-neutral-700 dark:text-neutral-400 dark:placeholder-neutral-500 dark:focus:ring-neutral-600"
                        type="text"
                        name="numero"
                        placeholder="Numéro..."
                        value={__.numero}
                        onChange={props.handleFieldChange}
                    />
                </div>

                <div className="sm:col-span-3">
                    <label htmlFor="af-montant" className="inline-block text-sm text-gray-800 mt-2.5 dark:text-neutral-200">
                        Montant
                    </label>
                </div>
                <div className="sm:col-span-9">
                    <input id="af-montant"
                        className="py-2 px-3 pe-11 block w-full border-gray-200 shadow-sm -mt-px -ms-px first:rounded-t-lg last:rounded-b-lg sm:first:rounded-s-lg sm:mt-0 sm:first:ms-0 sm:first:rounded-se-none sm:last:rounded-es-none sm:last:rounded-e-lg text-sm relative focus:z-10 focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none dark:bg-neutral-800 dark:border-neutral-700 dark:text-neutral-400 dark:placeholder-neutral-500 dark:focus:ring-neutral-600"
                        type="text"
                        name="montant"
                        placeholder="Montant..."
                        value={__.montant}
                        onChange={props.handleFieldChange}
                    />
                </div>

                <div className="sm:col-span-3">
                    <label htmlFor="af-depot" className="inline-block text-sm text-gray-800 mt-2.5 dark:text-neutral-200">
                        Dépôt
                    </label>
                </div>
                <div className="sm:col-span-9">
                    <DatePicker id="af-depot" name=""
                        className="py-2 px-3 pe-11 block w-full border-gray-200 shadow-sm text-sm rounded-lg focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none dark:bg-neutral-900 dark:border-neutral-700 dark:text-neutral-400 dark:placeholder-neutral-500 dark:focus:ring-neutral-600"
                        selected={__.depot}
                        onChange={(date: Date) => props.setDate(date, 'depot')}
                        dateFormat="dd/MM/yyyy"
                    />
                </div>

                <div className="sm:col-span-3">
                    <label htmlFor="af-traitement" className="inline-block text-sm text-gray-800 mt-2.5 dark:text-neutral-200">
                        Traitement
                    </label>
                </div>
                <div className="sm:col-span-9">
                    <DatePicker id="af-traitement" name=""
                        className="py-2 px-3 pe-11 block w-full border-gray-200 shadow-sm text-sm rounded-lg focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none dark:bg-neutral-900 dark:border-neutral-700 dark:text-neutral-400 dark:placeholder-neutral-500 dark:focus:ring-neutral-600"
                        selected={__.traitement}
                        onChange={(date: Date) => props.setDate(date, 'traitement')}
                        dateFormat="dd/MM/yyyy"
                    />
                </div>

                <div className="sm:col-span-3">
                    <label htmlFor="af-rejet" className="inline-block text-sm text-gray-800 mt-2.5 dark:text-neutral-200">
                        Rejet
                    </label>
                </div>
                <div className="sm:col-span-9">
                    <DatePicker id="af-rejet" name=""
                        className="py-2 px-3 pe-11 block w-full border-gray-200 shadow-sm text-sm rounded-lg focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none dark:bg-neutral-900 dark:border-neutral-700 dark:text-neutral-400 dark:placeholder-neutral-500 dark:focus:ring-neutral-600"
                        selected={__.rejet}
                        onChange={(date: Date) => props.setDate(date, 'rejet')}
                        dateFormat="dd/MM/yyyy"
                    />
                </div>

                <div className="sm:col-span-3">
                    <label htmlFor="af-statut" className="inline-block text-sm text-gray-800 mt-2.5 dark:text-neutral-200">
                        Statut
                    </label>
                </div>
                <div className="sm:col-span-9">
                    {props.statuts?.map(({ nom, libelle }) => (
                        <label htmlFor={`statut-decompte-${nom}`}
                            className="flex p-3 mb-2 w-full bg-white border border-gray-200 rounded-lg text-sm focus:border-blue-500 focus:ring-blue-500 dark:bg-neutral-900 dark:border-neutral-700 dark:text-neutral-400">
                            <input id={`statut-decompte-${nom}`}
                                type="radio"
                                name={`statut-decompte-${nom}`}
                                className="shrink-0 mt-0.5 border-gray-200 rounded-full text-red-600 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none dark:bg-neutral-800 dark:border-neutral-700 dark:checked:bg-red-500 dark:checked:border-blue-500 dark:focus:ring-offset-gray-800"
                                onChange={props.handleFieldChange}
                                checked={__.statut?.nom === nom} />
                            <span className="text-sm text-gray-800 ms-3 dark:text-neutral-400">
                                {libelle}
                            </span>
                        </label>
                    ))}
                </div>
            </div>
            <div className="mt-8 px-6 py-4 grid-cols-2 gap-3 flex justify-between items-center border-t border-gray-200 dark:border-neutral-700">
                <div></div>

                <div className="inline-flex gap-x-2 -mr-6 ">
                    {props.handleClearForm && (
                        <button type="button"
                            onClick={props.handleClearForm}
                            className="py-2 px-3 inline-flex items-center gap-x-2 text-sm font-medium rounded-lg border border-gray-200 bg-white text-gray-800 shadow-sm hover:bg-gray-50 disabled:opacity-50 disabled:pointer-events-none dark:bg-neutral-800 dark:border-neutral-700 dark:text-white dark:hover:bg-neutral-800">
                            Effacer
                        </button>
                    )}
                    <button type="button"
                        className="py-2 px-3 inline-flex items-center gap-x-2 text-sm font-semibold rounded-lg border border-transparent bg-red-600 text-white hover:bg-red-700 disabled:opacity-50 disabled:pointer-events-none"
                        onClick={props.handleSubmit}>
                        Enregistrer
                    </button>
                </div>

            </div>
        </form>
    );
};

export default DecompteForm;
