import { createBrowserRouter } from 'react-router-dom';
import App from '../App';

import LoginPage from '../pages/login/LoginPage';
import UsersPage from '../pages/user/UsersPage';
import AddUserPage from '../pages/user/AddUserPage';
import EditUserPage from '../pages/user/EditUserPage';
import ProjetsPage from '../pages/projet/ProjetsPage';
import AddProjetPage from '../pages/projet/AddProjetPage';
import EditProjetPage from '../pages/projet/EditProjetPage';
import EtapesProjetPage from '../pages/projet/etape/EtapesProjetPage';
import AddEtapeProjetPage from '../pages/projet/etape/AddEtapeProjetPage';
import EditEtapeProjetPage from '../pages/projet/etape/EditEtapeProjetPage';
import ErrorPage from '../pages/ErrorPage';
import EtablissementsPage from '../pages/etablissement/EtablissementsPage';
import AddEtablissementPage from '../pages/etablissement/AddEtablissementPage';
import EditEtablissementPage from '../pages/etablissement/EditEtablissementPage';
import AffectationsPage from '../pages/affectation/AffectationsPage';
import AddAffectationPage from '../pages/affectation/AddAffectationPage';
import EditAffectationPage from '../pages/affectation/EditAffectationPage';
import ViewDetailsProjetPage from '../pages/projet/ViewDetailsProjetPage';
import ViewAvancementsProjetPage from '../pages/projet/ViewAvancementsProjetPage';
import ViewFaitsMarquantsProjetPage from '../pages/projet/ViewFaitsMarquantsProjetPage';
import ViewDecomptesProjetPage from '../pages/projet/ViewDecomptesProjetPage';
import DashboardPage from '../pages/DashboardPage';
import AvancementsEtapePage from '../pages/projet/etape/avancement/AvancementsEtapePage';
import AddAvancementEtapePage from '../pages/projet/etape/avancement/AddAvancementEtapePage';
import EditAvancementEtapePage from '../pages/projet/etape/avancement/EditAvancementEtapePage';
import FaitsMarquantsPage from '../pages/projet/fait/FaitsMarquantsPage';
import AddFaitMarquantPage from '../pages/projet/fait/AddFaitMarquantPage';
import DecomptesPage from '../pages/projet/decompte/DecomptesPage';
import AddDecomptePage from '../pages/projet/decompte/AddDecomptePage';
import AvancementsDecomptePage from '../pages/projet/decompte/avancement/AvancementsDecomptePage';
import AddAvancementDecomptePage from '../pages/projet/decompte/avancement/AddAvancementDecomptePage';

export const router = createBrowserRouter([
  {
    path: '/login',
    element: <LoginPage />,
  },
  {
    path: '/',
    element: <App />,
    errorElement: <ErrorPage message="internal server error" />,
    children: [
      {
        path: '/',
        element: <DashboardPage />
      },
      {
        path: '/users',
        element: <UsersPage />
      },
      {
        path: '/users/add',
        element: <AddUserPage />
      },
      {
        path: '/users/:email',
        element: <EditUserPage />
      },
      {
        path: '/projets',
        element: <ProjetsPage />
      },
      {
        path: '/projets/add',
        element: <AddProjetPage />
      },
      {
        path: '/projets/:sid/etapes',
        element: <EtapesProjetPage />
      },

      {
        path: '/projets/:sid/etapes/add',
        element: <AddEtapeProjetPage />
      },
      {
        path: '/projets/:sid/faits',
        element: <FaitsMarquantsPage />
      },
      {
        path: '/projets/:sid/faits/add',
        element: <AddFaitMarquantPage />
      },
      {
        path: '/projets/:sid/etapes/:eid',
        element: <EditEtapeProjetPage />
      },
      {
        path: '/projets/:sid/etapes/:eid/avancements',
        element: <AvancementsEtapePage />
      },
      {
        path: '/projets/:sid/etapes/:eid/avancements/add',
        element: <AddAvancementEtapePage />
      },
      {
        path: '/projets/:sid/etapes/:eid/avancements/:aid',
        element: <EditAvancementEtapePage />
      },
      {
        path: '/projets/:sid/decomptes',
        element: <DecomptesPage />
      },
      {
        path: '/projets/:sid/decomptes/add',
        element: <AddDecomptePage />
      },
      {
        path: '/projets/:sid/decomptes/:did/avancements',
        element: <AvancementsDecomptePage />
      },
      {
        path: '/projets/:sid/decomptes/:did/avancements/add',
        element: <AddAvancementDecomptePage />
      },
      {
        path: '/projets/:sid',
        element: <EditProjetPage />
      },
      {
        path: '/projets/:sid/view/details',
        element: <ViewDetailsProjetPage />
      },
      {
        path: '/projets/:sid/view/avancements',
        element: <ViewAvancementsProjetPage />
      },
      {
        path: '/projets/:sid/view/faits',
        element: <ViewFaitsMarquantsProjetPage />
      },
      {
        path: '/projets/:sid/view/decomptes',
        element: <ViewDecomptesProjetPage />
      },
      {
        path: '/etablissements',
        element: <EtablissementsPage />
      },
      {
        path: '/etablissements/add',
        element: <AddEtablissementPage />
      },
      {
        path: '/etablissements/:sid',
        element: <EditEtablissementPage />
      },
      {
        path: '/affectations',
        element: <AffectationsPage />
      },
      {
        path: '/affectations/add',
        element: <AddAffectationPage />
      },
      {
        path: '/affectations/:sid',
        element: <EditAffectationPage />
      },
    ]
  }
]);
